import React from 'react';
import { BlankState } from '../Interview/InterviewMarkerList/BlankState';

interface PropTypes {
  title: string;
  text: string;
}

export function EmptyTab(props: PropTypes) {
  return (
    <BlankState className='my-2' title={props.title} subtitle={props.text} />
  );
}
