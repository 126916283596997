import React from 'react';
import { GenericEntry } from '../..';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { Simple } from '../../../../../entities/ApplicationFeed';
import { AuthorName } from '../../AuthorName';
import { AlertObject } from '../../../../../components/Alert';

interface PropTypes {
  application: Application;
  entry: Simple;
  currentUser: User;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function RcSurveySharedEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-people'
      iconClassName='bg-light-warning'
      title={
        <>
          <AuthorName name={props.entry.authorName} /> sent a reference request
          to <strong>{props.application.candidate.name}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
