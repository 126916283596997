import React from 'react';
import { ScorecardFill } from '../../../../entities/ApplicationFeed';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { User } from '../../../../entities/User';
import { ActivityButton } from '../ActivityButton';
import { AuthorName } from '../AuthorName';
import { AlertObject } from '../../../../components/Alert';
import { Icon } from '../../../../components/Icon';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: ScorecardFill;
  isLastItemInPage: boolean;
  editedScorecard: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function AdHocScorecardEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-clipboard-plus'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <AuthorName name={props.entry.authorName} />
          {props.editedScorecard ? (
            <>
              {' '}
              edited <strong>
                {' '}
                {props.application.candidate.name}&apos;s
              </strong>{' '}
              scorecard.
            </>
          ) : (
            <>
              {' '}
              filled in a scorecard for{' '}
              <strong> {props.application.candidate.name}.</strong>
            </>
          )}
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div className='d-flex'>
        {props.entry.scorecard.averageRating && (
          <div className='d-flex align-items-center'>
            <Icon name='bi-star-fill' className='p-0 fs-4 text-yellow' />
            <div className='fs-5 fw-bold mx-2'>
              {props.entry.scorecard.averageRating}
            </div>
            <div className='fs-6 text-dark-200 me-4'>AVG Attribute Rating</div>
          </div>
        )}
        <ActivityButton
          url={`/applications/${props.application.id}?tab=feedback&show_scorecard_id=${props.entry.scorecard.id}`}
          text='View Feedback'
        />
      </div>
    </GenericEntry>
  );
}
