import { isEmptyString } from '../isEmptyString';

export const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export function validateEmail(value?: string) {
  if (isEmptyString(value)) {
    return;
  }

  const isValid = EMAIL_REGEX.exec(value);
  if (!isValid) {
    return 'Value must be a valid email';
  }
}
