import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import { OverviewService } from '../../../../../services/applicant_tracking/analytics/OverviewService';
import { Overview } from '../../../../../entities/applicant_tracking/analytics/Overview';
import styles from './styles.module.scss';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { TitledPanel } from '../TitledPanel';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { CardTitle } from '../../../../../components/Card';
import { FilterState } from '../../Filters';
import { truncateNumber } from '../../../../../utils/number';
import { TruncateText } from '../../../../../components/TruncateText';
import { useAnalyticsData } from '../useAnalyticsData';
import { buildPassThroughRateValue } from '../../../../../utils/applicant_tracking/analytics';

interface PropTypes {
  filterState?: FilterState;
  height: string;
  exportToPDF?: boolean;
}

interface LazyContentPropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
}

export function OverviewWidget(props: PropTypes) {
  return (
    <TitledPanel title={<CardTitle>Overview</CardTitle>} height={props.height}>
      <LazyContent
        filterState={props.filterState}
        exportToPDF={props.exportToPDF}
      />
    </TitledPanel>
  );
}

function LazyContent(props: LazyContentPropTypes) {
  const data = useAnalyticsData(OverviewService.show, props.filterState);

  if (!data) return <LoadingSpinner />;

  return <Content data={data} exportToPDF={props.exportToPDF} />;
}

function Content(props: { data: Overview; exportToPDF?: boolean }) {
  return (
    <div className={classNames(styles.container, 'gap-3 h-100')}>
      <MiniPanel
        title='Job Positions'
        style={{ backgroundColor: '#FFFAE8' }}
        exportToPDF={props.exportToPDF}
      >
        <NumericValue value={props.data.openJobsCount} />
      </MiniPanel>
      <MiniPanel
        title='AVG Time to Hire'
        className='bg-light-info'
        tooltip="Number of days between a candidate's application and accepting an offer."
        exportToPDF={props.exportToPDF}
      >
        <AverageTimeToHire value={props.data.averageTimeToHire} />
      </MiniPanel>
      <MiniPanel
        title='Active Candidates'
        className='bg-light-success'
        tooltip='Applicants who are active in the recruiting process and have not been rejected or hired.'
        exportToPDF={props.exportToPDF}
      >
        <NumericValue value={props.data.activeCandidatesCount} />
        {props.data.totalCandidatesCount > 0 && (
          <TruncateText
            text={`out of ${props.data.totalCandidatesCount} applicants`}
            className='fs-6 text-dark-200'
          />
        )}
      </MiniPanel>
      <MiniPanel
        title='Pass-through rate'
        className='bg-gray'
        tooltip='Overall pass-through rate is calculated by dividing total hired applicants by the total of applicants.'
        exportToPDF={props.exportToPDF}
      >
        <PassThroughRate value={props.data.passThroughRate} />
      </MiniPanel>
    </div>
  );
}

function NumericValue(props: { value?: number }) {
  if (!props.value) return <EmptyValue />;

  return <MainValue>{props.value}</MainValue>;
}

function AverageTimeToHire(props: { value?: number }) {
  if (!props.value) return <EmptyValue />;

  return <MainValue>{truncateNumber(props.value)} days</MainValue>;
}

function PassThroughRate(props: { value?: number }) {
  if (!props.value) return <EmptyValue />;

  return <MainValue>{buildPassThroughRateValue(props.value)}%</MainValue>;
}

function EmptyValue() {
  return <div className={'fs-2'}>-</div>;
}

function MiniPanel(props: {
  title: string;
  children: ReactNode;
  tooltip?: string;
  exportToPDF?: boolean;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div
      className={classNames('px-3 py-3h h-100 rounded-3', props.className)}
      style={props.style}
    >
      <div className='d-flex align-items-center gap-2'>
        <TruncateText
          tooltip={true}
          text={props.title}
          className='fs-5 fw-semibold'
        />
        {props.tooltip && props.exportToPDF !== true && (
          <InfoTooltip>{props.tooltip}</InfoTooltip>
        )}
      </div>
      {props.children}
    </div>
  );
}

function MainValue(props: { children: ReactNode }) {
  return (
    <div className='fs-2 fw-bold' style={{ lineHeight: '24px' }}>
      {props.children}
    </div>
  );
}
