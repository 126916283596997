import React from 'react';
import { ListCandidate } from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { Alert, AlertType } from '../../../../../../../../components/Alert';
import {
  CandidateActionModal,
  CandidateActionProps,
} from '../../../ApplicationActionModals';

interface PropTypes {
  archiveModalActive: boolean;
  candidate: ListCandidate;
  onClose: () => void;
}

export function ArchiveCandidate(props: PropTypes) {
  const [errorAlertActive, setErrorAlertActive] = React.useState(false);
  const [action, setAction] = React.useState<string>();
  const archiveAction =
    props.candidate?.status == 'archived' ? 'Unarchive' : 'Archive';

  const onArchiveAction = (action: CandidateActionProps) => {
    if (action?.state == 'Success') {
      window.location.reload();
    } else if (action?.state == 'Error') {
      setAction(`${action.action.toLowerCase()}d`);
      setErrorAlertActive(true);
    }
    props.onClose();
  };

  return (
    <>
      {errorAlertActive && (
        <Alert
          type={AlertType.Danger}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setErrorAlertActive(false)}
        >
          <span>
            The candidate{' '}
            <span className='fw-bold'>{`${props.candidate.firstName} ${props.candidate.lastName}`}</span>{' '}
            could not be {action}
          </span>
        </Alert>
      )}
      {props.archiveModalActive && (
        <CandidateActionModal
          candidateId={props.candidate.id}
          candidateName={`${props.candidate.firstName} ${props.candidate.lastName}`}
          isOpen={props.candidate !== null}
          action={archiveAction}
          onClose={onArchiveAction}
        />
      )}
    </>
  );
}
