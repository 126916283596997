import React from 'react';
import { ScorecardFill } from '../../../../entities/ApplicationFeed';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { User } from '../../../../entities/User';
import { ActivityButton } from '../ActivityButton';
import { AuthorName } from '../AuthorName';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: ScorecardFill;
  isLastItemInPage: boolean;
  editedScorecard: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function InterviewScorecardEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-clipboard-check'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <AuthorName name={props.entry.authorName} />
          {props.editedScorecard ? ' edited ' : ' filled in '}
          <strong>{props.application.candidate.name}</strong>&apos;s scorecard
          {props.entry.scorecard.interview && (
            <>
              {' '}
              for the{' '}
              <strong>
                {props.entry.scorecard.interview.interviewStage.name}
              </strong>{' '}
              interview.
            </>
          )}
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div>
        {props.entry.scorecard.interview && (
          <ActivityButton
            url={`/interviews/${props.entry.scorecard.interview.id}`}
            text='View Interview'
            icon='bi-play-btn'
            className='me-3'
          />
        )}
        <ActivityButton
          url={`/applications/${props.application.id}?tab=feedback&show_scorecard_id=${props.entry.scorecard.id}`}
          text='View Scorecard'
          icon='bi-clipboard-check'
        />
      </div>
    </GenericEntry>
  );
}
