import React, { useEffect, useRef, useState } from 'react';
import { User } from '../../../../../entities/User';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../../../../../config/queryClient';
import { NoteInput } from '../../../../../components/NoteInput';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'reactstrap';
import { ApplicationFeed } from '../../../../../entities/ApplicationFeed';
import { ApplicationFeedCommentService } from '../../../../../services/applicant_tracking/ApplicationFeedCommentService';
import { ApplicationFeedComment } from '../../../../../entities/ApplicationFeedComment';

interface PropTypes {
  currentUser: User;
  entry: ApplicationFeed;
  value?: ApplicationFeedComment;
  onClose: (state?: boolean) => void;
}

async function commentService(props: PropTypes, action: () => Promise<void>) {
  try {
    await action();
    props.onClose(true);
  } catch (error) {
    props.onClose(false);
  }
}

export function CommentInput(props: PropTypes) {
  const [comment, setComment] = useState<string>(props.value?.text || '');
  const inputRef = useRef(null);

  const addComment = () => {
    commentService(props, async () => {
      await ApplicationFeedCommentService.create({
        comment,
        applicationFeedId: props.entry.id,
      });
    });
  };

  const editComment = () => {
    commentService(props, async () => {
      await ApplicationFeedCommentService.update({
        comment,
        applicationFeedCommentId: props.value.id,
      });
    });
  };

  useEffect(() => {
    // To set the cursor at the end of input
    if (inputRef.current) {
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      );
    }
  }, []);

  return (
    <div className={styles['comment-container']}>
      <QueryClientProvider client={queryClient}>
        <NoteInput
          value={comment}
          name='ApplicationFeedComment'
          placeholder='Add a comment and/or @mention anyone from your team.'
          autofocus={true}
          appendSpaceOnAdd={true}
          className={classNames('p-2 fs-5 rounded-1', styles['note-text'])}
          onChange={setComment}
          inputRef={inputRef}
        />
      </QueryClientProvider>
      <div className='d-flex mt-2 pt-1 justify-content-end'>
        <Button
          color='danger'
          size='sm'
          className='me-1'
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button
          disabled={comment.length === 0}
          size='sm'
          color='primary'
          onClick={props.value ? editComment : addComment}
        >
          {props.value ? 'Save' : 'Add'}
        </Button>
      </div>
    </div>
  );
}
