import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';
import { LocationStateList } from '../../../entities/v1/LocationStateList';

export interface ListParams {
  countryCode: string;
}

export class LocationStateService {
  public static list(params: ListParams): Promise<LocationStateList> {
    return ApiService.get<LocationStateList>(
      '/api/v1/location_states',
      snakecaseKeys(params),
    );
  }
}
