import React from 'react';
import { SpanTooltip } from '../../../components/SpanTooltip';

export function PasswordRequirementsList() {
  return (
    <div className='text-dark-200 fw-regular fs-6'>
      <div>The password must include:</div>
      <ul className='ps-3'>
        <li>An uppercase letter;</li>
        <li>A lower case letter;</li>
        <li>A number;</li>
        <li>
          <div className='d-flex'>
            An allowed special character{' '}
            <SpanTooltip
              text={
                <div className='d-flex align-items-center'>
                  <i className='bi bi-info-circle fs-4 ms-1 text-info d-flex'></i>
                </div>
              }
              tooltipText={
                'Allowed special characters: #, ?, !, @, $, %, ^, &, *, -, . (dot), _, , (comma), +, :, ~.'
              }
              trigger='hover'
              className='d-flex'
            />
          </div>
        </li>
      </ul>
    </div>
  );
}
