export const DATE_RANGE_QUERY_PARAM = 'date_range';
export const START_DATE_QUERY_PARAM = 'start_date';
export const END_DATE_QUERY_PARAM = 'end_date';

export function emptyDateRange() {
  return {
    start: null,
    end: null,
    range: null,
  };
}
