import React from 'react';
import { SurveyTrigger } from '../../../../entities/candidate_experience/SurveyTrigger';
import { SurveyTriggerEmptyState } from './SurveyTriggerEmptyState';
import { SurveyTriggerPageTitleCol } from '../SurveyTriggerPageTitleCol';
import { SurveyTriggerTable } from './SurveyTriggerTable';
import { getQueryParam } from '../../../../utils/url';

interface PropTypes {
  survey_triggers: SurveyTrigger[];
}

function isSearchQueryPresent(): boolean {
  return getQueryParam('name')?.length > 0;
}

export default function SurveyTriggerIndexPage(props: PropTypes) {
  return (
    <>
      {props.survey_triggers.length === 0 && !isSearchQueryPresent() ? (
        <>
          <SurveyTriggerPageTitleCol buttonEnabled={false} />
          <SurveyTriggerEmptyState />
        </>
      ) : (
        <>
          <SurveyTriggerPageTitleCol buttonEnabled={true} />
          <SurveyTriggerTable survey_triggers={props.survey_triggers} />
        </>
      )}
    </>
  );
}
