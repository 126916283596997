import { Candidate } from './Candidate';
import { JobPost } from './JobPost';

export interface Application {
  id: number;
  candidate: Candidate;
  candidateId: number;
  status: string;
  applicationResume: string;
  applicationCoverLetter: string;
  experience: string;
  academicDegree: string;
  currentCompany: string;
  currentTitle: string;
  details: string;
  expectedSalary: number;
  expectedSalaryCurrency: string;
  hasWorkingVisa: boolean;
  jobPost: JobPost;
  portfolioUrl: string;
  candidateConsented: boolean;
  referralKnowledge: boolean;
  referrerUserId: string;
  referrerEmail: string;
  sourceId: number;
  lastInteractionAt: string;
}

export enum ApplicationStatus {
  Draft = 'draft',
  Active = 'active',
  Rejected = 'rejected',
  Hired = 'hired',
}
