import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import { IconButton } from '../../../../../components/IconButton';

interface PropTypes {
  url: string;
}

export function CVNotAvailable() {
  return (
    <EmptyState
      title='CV not available'
      text='It seems that the candidate has not shared a CV.'
      classNames='my-8 py-6h'
    />
  );
}

export function CVLoading() {
  return (
    <EmptyState
      title='CV is loading'
      text="We are still processing the candidate's CV. Please check back later."
      classNames='my-8 py-6h'
    />
  );
}

export function DocumentTypeNotSupported(props: PropTypes) {
  return (
    <EmptyState
      title='CV preview is not available'
      text={
        <>
          <div className='mb-4'>
            It is not possible to preview this CV due to its format. Please
            download it.
          </div>
          <IconButton
            iconName='bi-arrow-down'
            buttonText='Download CV'
            color='info'
            onClick={() =>
              window.open(props.url, '_blank', 'noopener,noreferrer')
            }
          />
        </>
      }
      classNames='my-8 py-6h w-100'
    />
  );
}
