import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { InterviewRatingService } from '../../../services/InterviewRatingService';
import { Alert, AlertType } from '../../../components/Alert';
import { CloseableModal } from '../../../components/CloseableModal';
import { RateOption } from '../../../entities/RateOption';
import { RatingOptions } from './RatingOptions';
import { InterviewRating } from '../../../entities/InterviewRating';

interface PropTypes {
  interviewId: number;
  ratingOptions: RateOption[];
  isOpen: boolean;
  onClose: (ratingResponse: InterviewRating) => void;
}

interface ModalProps {
  interviewId: number;
  ratingOptions: RateOption[];
  activeOption: RateOption | null;
  setActiveOption: (option: RateOption | null) => void;
  setAlertActive: (active: boolean) => void;
  setAlertType: (type: AlertType) => void;
  setAlertMessage: (message: string) => void;
  onClose: (ratingResponse: InterviewRating) => void;
}

function ModalBody(props: ModalProps) {
  const rateInterview = async () => {
    let rating: InterviewRating;

    try {
      rating = await InterviewRatingService.create(
        props.interviewId,
        props.activeOption.value,
      );

      props.setAlertType(AlertType.Success);
      props.setAlertMessage('Interview successfully rated.');
    } catch (error) {
      props.setAlertType(AlertType.Danger);
      props.setAlertMessage('Interview could not be rated.');
      console.error(error);
    }
    props.setAlertActive(true);
    props.onClose(rating);
  };

  return (
    <>
      <div className='text-center mb-4 pb-2'>Overall Recommendation</div>
      <RatingOptions
        ratingOptions={props.ratingOptions}
        activeOption={props.activeOption}
        setActiveOption={props.setActiveOption}
      />
      <div className='d-flex mt-5 justify-content-end'>
        <Button
          className='me-2'
          color='secondary'
          onClick={() => props.onClose(null)}
        >
          Skip
        </Button>
        <Button
          color='primary'
          disabled={props.activeOption == null}
          onClick={rateInterview}
        >
          Rate Interview
        </Button>
      </div>
    </>
  );
}

function parseSearchParams(ratingOptions: RateOption[]) {
  const searchParams = new URLSearchParams(window.location.search);
  const optionString = searchParams.get('ratingOption');
  const selectedOption = ratingOptions.find(
    (option) => option.value === optionString,
  );

  return selectedOption ? selectedOption : null;
}

export function RatingModal(props: PropTypes) {
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Info);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [activeOption, setActiveOption] = useState<RateOption | null>(
    parseSearchParams(props.ratingOptions),
  );

  return (
    <>
      {alertActive && (
        <Alert
          type={alertType}
          autoClearTimeout={3000}
          clearable={true}
          onClose={() => setAlertActive(false)}
        >
          {alertMessage}
        </Alert>
      )}
      {props.isOpen && (
        <CloseableModal
          className='modal-dialog-centered'
          onClose={() => props.onClose(null)}
          isOpen={true}
          size='md'
          closeDisabled={false}
          keyboard={true}
          backdrop={true}
          headerTitle='Rate Interview'
          bodyChildren={
            <ModalBody
              interviewId={props.interviewId}
              ratingOptions={props.ratingOptions}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              onClose={props.onClose}
              setAlertActive={setAlertActive}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
            />
          }
        />
      )}
    </>
  );
}
