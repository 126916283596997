import React from 'react';
import { GenericEntry } from '../..';
import { Application } from '../../../../../entities/Application';
import { PulseSurveyActions } from '../../../../../entities/ApplicationFeed';
import { User } from '../../../../../entities/User';
import { AlertObject } from '../../../../../components/Alert';

interface PropTypes {
  application: Application;
  entry: PulseSurveyActions;
  currentUser: User;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function PulseSurveyCompletedEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-file-earmark-check'
      iconClassName='bg-green-light'
      title={
        <>
          <strong>{props.application.candidate.name}</strong> completed the {''}
          Pulse <strong>{props.entry.ceSurveyName}.</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
