import React from 'react';
import { Control } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { LabelledSelectFormHook } from '../../../../../../../components/LabelledSelectFormHook';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
}

export function Reason(props: PropTypes) {
  const options = [
    { label: 'New Hire', value: 'new_hire' },
    { label: 'Replacement', value: 'replacement' },
    { label: 'Parental Leave', value: 'parental_leave' },
  ];

  return (
    <Col xs={6}>
      <LabelledSelectFormHook<JobRequisitionDto>
        label='Reason'
        placeholder='Select reason'
        formHookContext={{
          controllerName: 'reason',
          formControl: props.formControl,
          required: true,
        }}
        options={options}
      />
    </Col>
  );
}
