import React from 'react';
import { ApplicationListingFilters, INCLUSION_OPTIONS } from '../../utils';
import { Col, Input, Row } from 'reactstrap';
import { Select } from '../../../../../../../../components/Select';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function ResumeFilter(props: PropTypes) {
  const handleChange = (value: string) => {
    props.setFilters({
      ...props.filters,
      resume: value,
    });
  };

  return (
    <Col xs='6' className='mt-3h'>
      <label>
        <h5>Resume</h5>
      </label>
      <Row className='gx-3'>
        <Col xs='3'>
          <Select
            options={INCLUSION_OPTIONS}
            disabled={props.isLoading}
            selected={INCLUSION_OPTIONS.find(
              (o) => o.value === props.filters?.resumeInclusionValue,
            )}
            onChange={(resumeInclusionValue) =>
              props.setFilters({
                ...props.filters,
                resumeInclusionValue: resumeInclusionValue?.value,
              })
            }
          />
        </Col>
        <Col xs='9'>
          <Input
            className='form-control fs-5'
            placeholder='Search by resume'
            value={props.filters?.resume ?? ''}
            disabled={props.isLoading}
            onChange={(v) => handleChange(v.target.value)}
          />
        </Col>
      </Row>
    </Col>
  );
}
