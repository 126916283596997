import { FilterState } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { TopInterviewers } from '../../../../entities/applicant_tracking/analytics/TopInterviewers';
import { ApiService } from '../../../ApiService';
import { mapToParams } from '../mapToParams';

export class TopInterviewersService {
  public static show(
    filterState?: FilterState,
    signal?: AbortSignal,
  ): Promise<TopInterviewers> {
    return ApiService.get(
      '/api/v1/applicant_tracking/analytics/top_interviewers',
      mapToParams(filterState),
      { signal },
    );
  }
}
