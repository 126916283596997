import React from 'react';
import { PhoneNumberField } from '../PhoneNumberField';
import { JobPostingDetailsForm } from '..';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: unknown) => void;
}

export function ContactPhone(props: PropTypes) {
  return (
    <PhoneNumberField
      label='Contact Phone'
      value={props.form.contactPhone}
      name='contactPhone'
      error={props.errors['contactInfo']?.['phoneNumber']}
      onInputChange={props.onInputChange}
    />
  );
}
