import React from 'react';
import { ApplicationListingFilters, INCLUSION_OPTIONS } from '../../utils';
import { Col, Input, Row } from 'reactstrap';
import { Select } from '../../../../../../../../components/Select';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function NameFilter(props: PropTypes) {
  const handleChange = (value: string) => {
    props.setFilters({
      ...props.filters,
      name: value,
    });
  };
  return (
    <Col xs='3' className='mt-3h'>
      <label>
        <h5>Name</h5>
      </label>
      <Row className='gx-3'>
        <Col xs='6'>
          <Select
            options={INCLUSION_OPTIONS}
            disabled={props.isLoading}
            selected={INCLUSION_OPTIONS.find(
              (o) => o.value === props.filters?.nameInclusionValue,
            )}
            onChange={(nameInclusionValue) =>
              props.setFilters({
                ...props.filters,
                nameInclusionValue: nameInclusionValue?.value,
              })
            }
          />
        </Col>
        <Col xs='6'>
          <Input
            className='form-control fs-5'
            placeholder='Search by name'
            value={props.filters?.name ?? ''}
            disabled={props.isLoading}
            onChange={(v) => handleChange(v.target.value)}
          />
        </Col>
      </Row>
    </Col>
  );
}
