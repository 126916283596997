import React, { useEffect, useState } from 'react';
import { Icon } from '../../../../../components/Icon';
import { Badge } from '../../../../../components/Badge';
import { Offcanvas } from '../../../../../components/Offcanvas';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { CartProduct } from './CartProduct';
import { Button } from 'reactstrap';
import { Product, Wallet } from '@vonq/hapi-elements-types';
import { CartFooter } from './CartFooter';
import { WindowWithHapi } from '../..';
import { getLabelForCount } from '../../../../../utils/grammar';
import { JobBoardAlertObject, JobBoardAlertType } from '../JobBoardAlert';
import { JobState } from '../../../../../entities/JobDetails';
import { JobPostingDetailsForm } from '../JobPostingDetails';
import { isPaidPostFormValid } from '../../utils/validators/paidPostFormValidator';

interface PropTypes {
  cartAmount: number;
  disabled: boolean;
  form: JobPostingDetailsForm;
  hasLogo: boolean;
  hasSubdomain: boolean;
  jobStatus: JobState;
  products: Product[];
  wallet: Wallet;
  window: WindowWithHapi;
  setAlert: (alert: JobBoardAlertObject) => void;
  onPay: () => void;
  setWallet: (wallet: Wallet) => void;
}

function CartHeader(props: { cartSize: number }) {
  return (
    <>
      <div className='d-flex flex-nowrap gap-3'>
        <h2 className='mb-0'>Cart</h2>
        <Chip color={ChipColor.DangerLight}>
          {getLabelForCount('item', props.cartSize)}
        </Chip>
      </div>
    </>
  );
}

export function Cart(props: PropTypes) {
  const [billingPortalLink, setBillingPortalLink] = useState<string>(null);
  const [isOpen, setIsOpen] = useState(false);

  const cartSize = props.products?.length || 0;

  useEffect(() => {
    if (!isOpen || props.wallet == null) {
      return;
    }

    (async () => {
      if (billingPortalLink == null) {
        setBillingPortalLink(
          (
            await props.window.hapi.wallet.api.getBillingPortalLink(
              window.location.href,
            )
          )?.billingPortalLink,
        );
      }

      if (!props.wallet?.has_billing_details) {
        const wallet = await props.window.hapi.wallet.api.getWallet();

        if (wallet != null) {
          props.setWallet(wallet);
        }
      }
    })();
  }, [isOpen]);

  async function handleRemoveProduct(product: Product): Promise<void> {
    try {
      await props.window.hapi.basket.service.removeProductOrContractById.run(
        product.product_id,
      );
      props.setAlert({
        type: JobBoardAlertType.ProductRemoved,
        message: (
          <span>
            <b>{product.title}</b> was removed from the cart
          </span>
        ),
      });
    } catch (e: any) {
      props.setAlert({
        type: JobBoardAlertType.ProductRemovedError,
        message: (
          <span>
            Could not remove the <b>{product.title}</b> from the cart
          </span>
        ),
      });
      console.error(
        `Could not remove product ${product.product_id} from the basket`,
        e,
      );
    }
  }

  return (
    <>
      <Button
        disabled={props.wallet == null || props.disabled}
        color='secondary'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='d-flex'>
          <div className='me-2'>
            <div className='position-relative'>
              {cartSize > 0 && <Badge value={cartSize} />}
              <Icon name={'bi-cart'} />
            </div>
          </div>
          Cart
        </div>
      </Button>
      <Offcanvas
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        header={<CartHeader cartSize={cartSize} />}
        footer={
          <CartFooter
            billingPortalLink={billingPortalLink}
            cartAmount={props.cartAmount ?? 0}
            cartSize={cartSize}
            formIsValid={isPaidPostFormValid(props.form)}
            hasLogo={props.hasLogo}
            hasSubdomain={props.hasSubdomain}
            jobStatus={props.jobStatus}
            wallet={props.wallet}
            onPay={props.onPay}
            onClose={() => setIsOpen(false)}
          />
        }
      >
        <h4 className='mb-4'>{props.form?.campaignName || 'N/A'}</h4>
        <div className='d-flex flex-column gap-3 overflow-auto mh-90'>
          {props.products.map((p: Product, index: number) => (
            <CartProduct
              key={index}
              index={index}
              currency={props.wallet.balance.currency}
              product={p}
              onRemoveProduct={handleRemoveProduct}
            />
          ))}
        </div>
      </Offcanvas>
    </>
  );
}
