import snakecaseKeys from 'snakecase-keys';
import { PlanTemplateList } from '../../../../entities/v1/applicant_tracking/PlanTemplateList';
import { ApiService } from '../../../ApiService';
import { PlanTemplate } from '../../../../entities/v1/applicant_tracking/PlanTemplate';

interface ListParams {
  publishState?: 'archived' | 'published';
  jobId?: number;
  expand?: string[];
}

interface Params {
  expand: string[];
}

export class PlanTemplateService {
  public static list(params?: ListParams): Promise<PlanTemplateList> {
    return ApiService.get<PlanTemplateList>(
      '/api/v1/applicant_tracking/plan_templates',
      snakecaseKeys(params),
    );
  }

  public static get(id: number, params?: Params): Promise<PlanTemplate> {
    return ApiService.get<PlanTemplate>(
      `/api/v1/applicant_tracking/plan_templates/${id}`,
      snakecaseKeys(params),
    );
  }
}
