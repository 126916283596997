import React, { useState } from 'react';
import { Application } from '../../../entities/Application';
import { Col, Row } from 'reactstrap';
import { User } from '../../../entities/User';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { AlertObject } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';
import { FeedbackList } from './FeedbackList';
import { FeedbackFilters, FeedbackType, Filters } from './Filters';
import { AddFeedback } from './AddFeedback';
import { getQueryParam } from '../../../utils/url';

interface PropTypes {
  application: Application;
  currentUser: User;
  jobHiringMemberRole?: JobHiringMemberRole;
  reloadApplication: () => void;
  setAlert: (data: AlertObject) => void;
}

function tabToOpen(): FeedbackType {
  if (getQueryParam('show_application_rating_id')) {
    return 'candidate_rating';
  }

  return 'scorecard';
}

function emptyFiltersWithTab(tab: FeedbackType): FeedbackFilters {
  return {
    feedbackType: tab,
    status: null,
    jobStages: [],
    hiringMembers: [],
  } as FeedbackFilters;
}

export function FeedbackTab(props: PropTypes) {
  const [filters, setFilters] = useState<FeedbackFilters>(
    emptyFiltersWithTab(tabToOpen()),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refreshCandidateRatingList = () => {
    props.reloadApplication();
    setFilters(emptyFiltersWithTab('candidate_rating'));
  };

  return (
    <div className='d-flex'>
      <div className='d-flex flex-column gap-3 w-100'>
        <div className='d-flex justify-content-between'>
          <CandidatePageSubHeader text='Feedback' />
          <AddFeedback
            application={props.application}
            isLoading={isLoading}
            refreshList={refreshCandidateRatingList}
          />
        </div>
        <Row className='flex-nowrap'>
          <Col xs='3'>
            <Filters
              application={props.application}
              filters={filters}
              isLoading={isLoading}
              onSearch={setFilters}
            />
          </Col>
          <Col>
            <FeedbackList
              isLoading={isLoading}
              filters={filters}
              currentUser={props.currentUser}
              application={props.application}
              viewAllScorecards={props.application.viewAllScorecards}
              setIsLoading={setIsLoading}
              refreshCandidateRatingList={refreshCandidateRatingList}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
