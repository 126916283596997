import { Col, Row } from 'reactstrap';
import { RichTextEditor } from '../../../../../../../components/RichTextEditor';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { AreaRichTextComposer } from '../../../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { JOB_POST_EDITOR_DEFAULT_STATE } from '../../../../../../../utils/applicant_tracking/jobDetails';
import { MarkdownNodePlugin } from '../../../../../../../components/RichTextEditor/plugins/MarkdownNodePlugin';
import React from 'react';
import { Control } from 'react-hook-form';
import classNames from 'classnames';
import { SetEditablePlugin } from '../../../../../../../components/RichTextEditor/plugins/SetEditablePlugin';

interface PropTypes {
  formControl: Control<CreateJobDto>;
  generatedJobDescription: string;
  updateGlobalStateCallback: (data: any) => void;
  disabled: boolean;
}

export function JobDescriptionEditor(props: PropTypes) {
  return (
    <>
      <Row className='mt-4'>
        <Col className='col-12'>
          <RichTextEditor<CreateJobDto>
            formHookContext={{
              controllerName: 'editorState',
              formControl: props.formControl,
              required: true,
            }}
            render={(field) => (
              <div
                className={classNames('rounded-1 ps-0 pe-0', {
                  'pe-none border border-info': props.disabled,
                })}
              >
                <AreaRichTextComposer
                  disabled={props.disabled}
                  defaultEditorState={
                    (field.value as string) ?? JOB_POST_EDITOR_DEFAULT_STATE
                  }
                  size='lg'
                  onChangeJson={(value: string) => field.onChange(value)}
                  onChangeHtml={(html: string) =>
                    props.updateGlobalStateCallback({ editorStateHtml: html })
                  }
                  externalPlugins={[
                    <MarkdownNodePlugin
                      key={'markdown-node-plugin'}
                      text={props.generatedJobDescription}
                      clearState={true}
                      blockUpdates={!props.disabled}
                    />,
                    <SetEditablePlugin
                      key={'set-editable-plugin'}
                      isEditable={!props.disabled}
                    />,
                  ]}
                />
              </div>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
