import React from 'react';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../../../../../utils/mapResultToSelectOption';
import { ApplicationListingFilters } from '../../utils';
import { JobService } from '../../../../../../../../services/v1/applicant_tracking/JobService';
import { Col } from 'reactstrap';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function JobsFilter(props: PropTypes) {
  const loadJobs = (name: string, page: number) =>
    mapResultToSelectOption(
      JobService.list({ name, page }),
      (filter) => filter.jobs,
      false,
    );

  return (
    <Col xs='3' className='mt-3h'>
      <MultiSelectAsyncFilter
        isMulti
        label='Job'
        placeholder='Select jobs'
        loadOptions={loadJobs}
        onChange={(jobs) => props.setFilters({ ...props.filters, jobs })}
        className='ps-0 fs-5 fw-semibold'
        disabled={props.isLoading}
        selected={props.filters?.jobs || []}
      />
    </Col>
  );
}
