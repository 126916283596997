import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { Chip, ChipColor } from '../../../../../../components/Chip';
import { JobDetails } from '../../../../../../services/applicant_tracking/JobService/EditJobDto';
import { PipelineDetail } from './WorkflowDetail';
import { JobStageTemplateInput } from '../../../../../PlanTemplates/SetupPlanTemplatePage';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { PlanTemplateList } from '../../../../../../entities/v1/applicant_tracking/PlanTemplateList';
import { PlanTemplate } from '../../../../../../entities/v1/applicant_tracking/PlanTemplate';
import { PlanTemplate as OldPlanTemplate } from '../../../../../../entities/applicant_tracking/PlanTemplate';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { LabelledSelect } from '../../../../../../components/LabelledSelect';
import { ChangeWorkflowModal } from './ChangeWorkflowModal';
import { JobWorkflowDropdown } from './JobWorkflowDropdown';

interface PropTypes {
  jobDetails: JobDetails;
  formControl: Control<CreateJobDto>;
  planTemplateList: PlanTemplateList;
  planTemplate: OldPlanTemplate | undefined;
  focusedJobStage: JobStageTemplateInput;
  changeLiveWorkflow: boolean;
  jobId?: number;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
  setValue: UseFormSetValue<CreateJobDto>;
}

function GlobalTemplateSelectedAlert(props: { changeLiveWorkflow: boolean }) {
  if (props.changeLiveWorkflow) {
    return null;
  }

  return (
    <AlertMessage
      hasOverlay={false}
      icon={{ name: 'bi-info-circle' }}
      className='mt-3 fs-5 bg-light-danger'
      text={
        <span>
          <b>Important!</b> You have selected a default template that you
          won&#39;t be able to edit in the future. Please select or create
          another workflow if you plan to make changes!
        </span>
      }
    />
  );
}

function mapPlanTemplateToOption(pt: PlanTemplate): SelectOption {
  return {
    value: pt.id.toString(),
    label: pt.name,
    subLabel: (
      <Col xs='4'>
        <Chip
          className='d-block mw-100 text-truncate fw-semibold'
          color={ChipColor.Gray}
        >
          {pt.stagePlanTemplateIds.length} stages
        </Chip>
      </Col>
    ),
  };
}

export function JobWorkflowPipeline(props: PropTypes) {
  const jobWorkflowIdControllerName = 'jobWorkflowId';
  const stageMovesControllerName = 'stageMoves';

  const watchWorkflowId = useWatch({
    control: props.formControl,
    name: jobWorkflowIdControllerName,
  });
  const options = props.planTemplateList.planTemplates.map((pt) =>
    mapPlanTemplateToOption(pt),
  );
  const [changeModalVisible, setChangeModalVisible] = useState(false);
  const [newWorkflowId, setNewWorkflowId] = useState(null);

  useEffect(() => props.setFocusedJobStage(null), [watchWorkflowId]);

  const handleClose = (
    success: boolean,
    mappedWorkflow?: Map<number, number>,
  ) => {
    if (success) {
      props.setValue(jobWorkflowIdControllerName, newWorkflowId);
      const valueMapped = Array.from(mappedWorkflow).map((v) => {
        return { from: v[0], to: v[1] };
      });

      props.setValue(stageMovesControllerName, valueMapped);
    }
    setChangeModalVisible(false);
  };

  const handleChangeWorkflow = (newOption: SelectOption) => {
    if (watchWorkflowId != null && newOption.value !== watchWorkflowId) {
      setChangeModalVisible(true);
      setNewWorkflowId(newOption.value);
    } else {
      props.setValue(jobWorkflowIdControllerName, newOption.value);
    }
  };

  return (
    <>
      <ChangeWorkflowModal
        jobId={props.jobId}
        newWorkflowId={newWorkflowId}
        onClose={handleClose}
        isOpen={changeModalVisible}
      />
      <Row>
        <Col xs='3'>
          {props.changeLiveWorkflow ? (
            props.jobDetails?.applicationsCount > 0 ? (
              <LabelledSelect
                options={options}
                selected={options.find((i) => i.value === watchWorkflowId)}
                label='Job Workflow'
                onChange={handleChangeWorkflow}
              />
            ) : (
              <JobWorkflowDropdown
                formControl={props.formControl}
                options={options}
              />
            )
          ) : (
            <JobWorkflowDropdown
              formControl={props.formControl}
              options={options}
              disabled={props.jobDetails?.applicationsCount > 0}
            />
          )}
        </Col>
      </Row>
      <Detail
        id={watchWorkflowId}
        changeLiveWorkflow={props.changeLiveWorkflow}
        planTemplate={props.planTemplate}
        focusedJobStage={props.focusedJobStage}
        onFocusJobStage={props.setFocusedJobStage}
      />
    </>
  );
}

function Detail(props: {
  id: string | undefined;
  planTemplate: OldPlanTemplate | undefined;
  focusedJobStage: JobStageTemplateInput;
  changeLiveWorkflow: boolean;
  onFocusJobStage: (jobStage: JobStageTemplateInput) => void;
}) {
  if (!props.id) return null;
  if (!props.planTemplate) return <LoadingSpinner />;

  return (
    <div>
      {props.planTemplate?.globalTemplate && (
        <GlobalTemplateSelectedAlert
          changeLiveWorkflow={props.changeLiveWorkflow}
        />
      )}
      {props.planTemplate && (
        <PipelineDetail
          planTemplate={props.planTemplate}
          focusedJobStage={props.focusedJobStage}
          setFocusedJobStage={props.onFocusJobStage}
        />
      )}
    </div>
  );
}
