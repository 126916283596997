import { SourceReferrer } from './SourceReferrer';

export const SOURCING_CHANNELS = [
  { id: 'agencies', name: 'Agencies' },
  { id: 'company_marketing', name: 'Company Marketing' },
  { id: 'employee_referrals', name: 'Employee Referrals' },
  { id: 'events_and_programs', name: 'Events and Programs' },
  { id: 'external_job_boards', name: 'External Job Boards' },
  { id: 'sourcing', name: 'Sourcing' },
];

export const ChannelTypes = SOURCING_CHANNELS.map((channel) => channel.id);

export type ChannelType = typeof ChannelTypes[number];
export interface Source {
  id: number;
  displayName?: string;
  name: string;
  channelType: ChannelType;
  enabledOnCareers: boolean;
  sourceReferrer: SourceReferrer;
}
