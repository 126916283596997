import classNames from 'classnames';
import React from 'react';
import { DropdownButton } from '../Dropdown/DropdownButton';
import { PropTypes as IconPropTypes } from '../Icon';
import { DropdownMenu } from './DropdownMenu';
import { GroupedOption } from '../GroupedMultiSelect';

interface PropTypes {
  options: GroupedOption[];
  onChange: (key: string, selected: boolean) => void;
  containerClassName?: string;
  buttonIcon?: IconPropTypes;
  buttonText?: string;
  buttonClassName?: string;
  hasChevron?: boolean;
  menuPlacementClass?: string;
}

export function DropdownGroupedList(props: PropTypes) {
  return (
    <div className={classNames('dropdown', props.containerClassName)}>
      <DropdownButton
        text={props.buttonText}
        icon={props.buttonIcon}
        className={props.buttonClassName}
        hasChevron={props.hasChevron}
      />
      <DropdownMenu
        placementClass={props.menuPlacementClass}
        options={props.options}
        onChange={props.onChange}
      />
    </div>
  );
}
