import { IconSpan } from '../../../../../../../../../components/IconSpan';
import React from 'react';
import { GenerateState } from '../../../../../../../../../components/FillWithAIButton';

interface PropTypes {
  aiGenerateState: GenerateState;
}

function GeneratingStateIcon() {
  return (
    <IconSpan
      className='text-dark-500 fs-5 text-nowrap mb-2'
      spanText='AI is writing...'
      icon={{
        name: 'bi-stars',
      }}
    />
  );
}

function CancellingStateIcon() {
  return (
    <IconSpan
      className='text-dark-500 fs-5 text-nowrap mb-2'
      spanText='Canceling...'
      icon={{
        name: 'bi-stars',
      }}
    />
  );
}

export function GeneratingIcon(props: PropTypes) {
  switch (props.aiGenerateState) {
    case 'Generating':
      return <GeneratingStateIcon />;
    case 'Canceling':
      return <CancellingStateIcon />;
  }
}
