import React, { useState } from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { Application } from '../../../../../entities/applicant_tracking/Application';
import { InterviewStage } from '../../../../../entities/applicant_tracking/InterviewStage';
import ScheduleInterviewPage, { SchedulingSettings } from '../index';
import { User } from '../../../../../entities/User';
import candidateSchedulingImage from '../../../../../images/candidate-experience-mailer-new-survey.png';
import {
  InterviewDatePickerFooter,
  UpsertSelfScheduleInterviewPropTypes,
} from '../InterviewDatePicker/InterviewDatePickerFooter';
import { InterviewCandidateUserService } from '../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import { appendCurrentRedirectUri } from '../../../../../utils/url';
import { ShareTeamAvailabilityFooter } from '../ShareTeamAvailabilityFooter';
import { appendDateQueryParamsToUrl } from '../utils/buildDateQueryParams';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  application: Application;
  currentUser: User;
  availableParticipants: User[];
  interviewStage: InterviewStage;
  meetingProviders: VcsProvider[];
  defaultMeetingProvider: VcsProvider;
}

export default function CandidateScheduleInterview(props: PropTypes) {
  const [schedulingSettings, setSchedulingSettings] =
    useState<SchedulingSettings>(null);
  const onConfirm = async (data: UpsertSelfScheduleInterviewPropTypes) => {
    try {
      const interviewCandidateUser =
        await InterviewCandidateUserService.createSelfSchedule(
          props.application.id,
          props.interviewStage.id,
          data.interviewerUserIds,
          data.meetingProvider,
          data.durationMinutes,
          data.dateRange,
          data.scheduleFromHours,
          data.timezone,
          data.meetingOrganizer.id,
        );

      window.location.href = appendCurrentRedirectUri(
        appendDateQueryParamsToUrl(
          `/applicant_tracking/interview_candidate_users/${interviewCandidateUser.id}`,
          data.scheduleFromHours,
          data.dateRange,
          data.timezone,
        ),
      );
    } catch (e) {
      window.location.href = appendCurrentRedirectUri(
        `/applicant_tracking/interview_candidate_users/schedule/error`,
      );
    }
  };

  return (
    <ScheduleInterviewPage
      meetingOrganizer={props.currentUser}
      applicationIds={[props.application.id]}
      title={'Let the candidate schedule the interview'}
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      candidate={props.application.candidate}
      participants={[props.currentUser]}
      availableParticipants={props.availableParticipants}
      interviewStage={props.interviewStage}
      meetingProviders={props.meetingProviders}
      meetingProvider={props.defaultMeetingProvider}
      customImage={candidateSchedulingImage}
      onConfirm={(_data) => null}
      candidateView={false}
      selfScheduleView={true}
      allowChangingMeetingOrganizer={true}
      allowIgnoreParticipantCalendar={false}
      setSchedulingSettings={setSchedulingSettings}
      interviewDatePickerFooter={
        <InterviewDatePickerFooter
          buttonText='Confirm Availability'
          description={
            <ShareTeamAvailabilityFooter
              schedulingSettings={schedulingSettings}
            />
          }
          onConfirm={onConfirm}
        />
      }
    />
  );
}
