import { FilterState } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { Time } from '../../../../entities/applicant_tracking/analytics/Time';
import { ApiService } from '../../../ApiService';
import { mapToParams } from '../mapToParams';

export class TimeService {
  public static show(
    filterState: FilterState,
    signal?: AbortSignal,
  ): Promise<Time> {
    return ApiService.get(
      '/api/v1/applicant_tracking/analytics/time',
      mapToParams(filterState),
      { signal },
    );
  }
}
