import React from 'react';
import { Application } from '../../../../entities/Application';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ApplicationFeedList } from '../../../../services/applicant_tracking/ApplicationFeedService';
import { ActivityContent } from '../../ActivityContent';
import { PanelFooter } from '../../../../components/PanelFooter';
import { User } from '../../../../entities/User';
import { ActivityTabFilter, ActivityTabFilters } from './ActivityTabFilters';
import { EmptyState } from '../../../../components/EmptyState';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { AlertObject } from '../../../../components/Alert';
import { Card } from '../../../../components/Card';

interface PropTypes {
  application: Application;
  applicationFeedList: ApplicationFeedList;
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  itemsPerPage: number;
  applyFilters?: () => void;
  onFilter?: (filters: ActivityTabFilter) => void;
  onPageClick(page: number): void;
  reloadApplication: () => void;
  reloadFeed: () => void;
  setAlert: (data: AlertObject) => void;
}

function Content(props: PropTypes) {
  const { currentPage, totalCount } = props.applicationFeedList;

  return (
    <>
      {totalCount === 0 ? (
        <EmptyState
          title='No activity available'
          text='No activity is available for this candidate taking into account the selected filters.'
        />
      ) : (
        <div>
          <ActivityContent
            application={props.application}
            applicationFeeds={props.applicationFeedList.data}
            applicationStylesPath={props.applicationStylesPath}
            currentUser={props.currentUser}
            emailAccountIntegration={props.emailAccountIntegration}
            redirectUri={`/applications/${props.application.id}?tab=activity`}
            setAlert={props.setAlert}
            reloadApplication={props.reloadApplication}
            reloadFeed={props.reloadFeed}
          />
          {totalCount > props.itemsPerPage && (
            <PanelFooter
              page={currentPage}
              pageSize={props.itemsPerPage}
              totalRows={totalCount}
              visiblePageCount={5}
              onPageChanged={props.onPageClick}
            />
          )}
        </div>
      )}
    </>
  );
}

export function CandidateProcessActivityPanel(props: PropTypes) {
  return (
    <Card className='mb-4'>
      <div className='d-flex mb-3 align-items-center justify-content-between'>
        <h3 className='flex-grow-1'>Candidate Process</h3>
        <ActivityTabFilters onFilter={props.onFilter} />
      </div>
      <div>
        {props.applicationFeedList ? (
          <Content
            application={props.application}
            applicationFeedList={props.applicationFeedList}
            applicationStylesPath={props.applicationStylesPath}
            currentUser={props.currentUser}
            emailAccountIntegration={props.emailAccountIntegration}
            itemsPerPage={props.itemsPerPage}
            onPageClick={props.onPageClick}
            reloadApplication={props.reloadApplication}
            reloadFeed={props.reloadFeed}
            setAlert={props.setAlert}
          />
        ) : (
          <div className={classNames(styles['loading-spinner-container'])}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </Card>
  );
}
