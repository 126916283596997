import React, { useEffect, useState } from 'react';
import { IconButton } from '../../../../components/IconButton';
import { ActivityContent } from '../../ActivityContent';
import { Application } from '../../../../entities/Application';
import { NoteModal } from '../../../../components/ApplicantTracking/NoteModal';
import { User } from '../../../../entities/User';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { NoteService } from '../../../../services/applicant_tracking/NoteService';
import { AlertObject } from '../../../../components/Alert';
import { Card } from '../../../../components/Card';
import {
  ApplicationFeedList,
  ApplicationFeedService,
} from '../../../../services/applicant_tracking/ApplicationFeedService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import moment from 'moment';
import { ActivityTabFilter } from '../../ActivityTab/CandidateProcessActivityPanel/ActivityTabFilters';

interface PropTypes {
  application: Application;
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  reloadApplication: () => void;
  setAlert: (data: AlertObject) => void;
}

interface LatestActivityBodyPropTypes extends PropTypes {
  applicationFeedList: ApplicationFeedList;
  reloadFeed: () => void;
}

function LatestActivityBody(props: LatestActivityBodyPropTypes) {
  useEffect(() => {
    props.reloadFeed();
  }, [props.application]);

  if (props.applicationFeedList == null) {
    return <LoadingSpinner />;
  }

  return (
    <ActivityContent
      application={props.application}
      applicationFeeds={props.applicationFeedList.data}
      applicationStylesPath={props.applicationStylesPath}
      currentUser={props.currentUser}
      emailAccountIntegration={props.emailAccountIntegration}
      reloadApplication={props.reloadApplication}
      reloadFeed={props.reloadFeed}
      setAlert={props.setAlert}
    />
  );
}

export function LatestActivityPanel(props: PropTypes) {
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);
  const [applicationFeedList, setApplicationFeedList] =
    useState<ApplicationFeedList>(null);

  const fetchApplicationFeedList = async () => {
    const applicationFeed = await ApplicationFeedService.list(
      props.application.id,
      5,
      {
        activityFilter: [],
        dateFilter: {
          start: moment().subtract(6, 'months').startOf('day').utc(true),
          end: moment().endOf('day').utc(true),
        },
        page: 1,
      } as ActivityTabFilter,
    );

    setApplicationFeedList(applicationFeed);
  };

  const onAddNote = async (note: string) => {
    await NoteService.create({
      applicationId: props.application.id,
      note: note,
    });
  };

  return (
    <Card>
      <div className='d-flex align-items-center mb-4'>
        <h3 className='mb-0 me-auto'>Latest Activity</h3>
        <IconButton
          buttonText='Add note'
          color='borderless'
          iconName='bi-file-earmark-text'
          onClick={() => setIsNoteModalOpen(true)}
        />
      </div>
      <NoteModal
        mode='Add'
        onSubmit={onAddNote}
        isOpen={isNoteModalOpen}
        onClose={(succeeded: boolean) => {
          setIsNoteModalOpen(false);
          succeeded && fetchApplicationFeedList();
        }}
      />
      <LatestActivityBody
        {...props}
        reloadFeed={fetchApplicationFeedList}
        applicationFeedList={applicationFeedList}
      />
    </Card>
  );
}
