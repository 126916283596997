import React, { ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Alert, AlertType } from '../Alert';

interface PropTypes {
  error: FieldErrors;
  errorMessage?: ReactNode;
}

function getErrorMessage(error: FieldErrors): ReactNode {
  if (error.root?.type === 'clientError') {
    return (
      <span>
        <b>An error occurred!</b> Please review the form
      </span>
    );
  } else if (error.root?.type === 'serverError') {
    return (
      <span>
        <b>A server error occurred!</b> Please contact{' '}
        <a href='mailto:support@screenloop.com'>support</a>
      </span>
    );
  }

  return error[Object.keys(error)[0]]?.message;
}

export function FormErrorMessage(props: PropTypes) {
  if (props.error == null || Object.keys(props.error).length === 0) {
    return null;
  }

  const errorMessage = getErrorMessage(props.error);

  return (
    errorMessage && (
      <Alert clearable type={AlertType.Danger}>
        {errorMessage}
      </Alert>
    )
  );
}
