import React from 'react';
import { LabelledSelectFormHook } from '../../../../../../../components/LabelledSelectFormHook';
import { SelectOption } from '../../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';

interface PropTypes {
  formControl: any;
  options: SelectOption[];
  disabled?: boolean;
}

export function JobWorkflowDropdown(props: PropTypes) {
  const jobWorkflowIdControllerName = 'jobWorkflowId';

  return (
    <LabelledSelectFormHook<CreateJobDto>
      label='Job Workflow'
      placeholder='Select job workflow'
      formHookContext={{
        controllerName: jobWorkflowIdControllerName,
        formControl: props.formControl,
        required: true,
      }}
      options={props.options}
      disabled={props.disabled}
    />
  );
}
