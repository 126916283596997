import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { IconButton } from '../../../../../../../components/IconButton';
import { Row } from 'reactstrap';
import React from 'react';
import { FormState } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import {
  FillWithAIButton,
  GenerateState,
} from '../../../../../../../components/FillWithAIButton';

interface PropTypes {
  formState: FormState<CreateJobDto>;
  previewUrl: string;
  isGeneratorOpened: boolean;
  aiGenerateState: GenerateState;
  handleFillWithAIButtonClick: () => void;
  generateJobDescriptionEnabled: boolean;
}

export function JobDescriptionHeader(props: PropTypes) {
  return (
    <Row className='mt-4'>
      <div className='d-flex justify-content-between align-items-center mt-4'>
        <div>
          <FormFieldHeader
            fieldName={'Description'}
            isRequired={true}
            classNames={'fw-semibold'}
            fontSize={'fs-3'}
          />
          <small className='text-muted'>
            Add job description, requirements and benefits
          </small>
        </div>
        {!props.isGeneratorOpened && (
          <div className='d-flex align-items-right'>
            {props.generateJobDescriptionEnabled && (
              <FillWithAIButton
                state={props.aiGenerateState}
                onClick={props.handleFillWithAIButtonClick}
                regenerateHelperText='Want to generate another description?'
                buttonSize={'md'}
              />
            )}
            <IconButton
              iconName='bi-box-arrow-up-right'
              color='secondary'
              buttonClass='ms-3 text-info border-info'
              buttonText='Preview'
              disabled={!props.formState.isValid}
              onClick={() => {
                window.open(props.previewUrl, '_blank');
              }}
            />
          </div>
        )}
      </div>
    </Row>
  );
}
