import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type PropTypes = {
  isEditable: boolean;
};

export function SetEditablePlugin(props: PropTypes): JSX.Element {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(props.isEditable);
  }, [props.isEditable, editor]);

  return null;
}
