import React, { useEffect, useState } from 'react';
import { JobPostingDetailsForm } from '.';
import { TextField } from './TextField';
import { FormGroup } from './FormGroup';
import countries from 'iso-3166-1';
import { Country } from 'iso-3166-1/dist/iso-3166';
import { humanize } from '../../../../../utils/humanize';
import { SimpleSelectField } from './SimpleSelectField';
import { LocationStateService } from '../../../../../services/v1/LocationStateService';
import { LocationState } from '../../../../../entities/v1/LocationState';

interface PropTypes {
  isOrganic: boolean;
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: unknown) => void;
}

const COUNTRY_SELECT_NAME = 'country';
const STATE_SELECT_NAME = 'countryState';

function CountrySelect(props: {
  countries: Country[];
  selectedCountry?: string;
  onInputChange: (name: string, value: unknown) => void;
}) {
  const options = props.countries.map((country: Country) => {
    return {
      label: humanize(country.country),
      value: country.alpha2,
      selected: country.alpha2 === props.selectedCountry,
    };
  });

  return (
    <SimpleSelectField
      label={'Country'}
      options={options}
      value={props.selectedCountry}
      mandatory
      name={COUNTRY_SELECT_NAME}
      onInputChange={props.onInputChange}
    />
  );
}

function StateSelect(props: {
  selectedCountry?: string;
  selectedState?: string;
  onInputChange: (name: string, value: unknown) => void;
}) {
  const [states, setStates] = useState<LocationState[]>(null);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (props.selectedCountry != null) {
        setStates(null);
        setStateLoading(true);

        const stateResponse = await LocationStateService.list({
          countryCode: props.selectedCountry,
        });

        // There are some countries that only have one state or no state at all.
        // In that case, we pick the first result.
        const stateArray = stateResponse?.states || [];

        if (stateArray.length === 0) {
          stateArray.push({
            name:
              countries.whereAlpha2(props.selectedCountry)?.country ||
              props.selectedCountry,
            alphaCode: props.selectedCountry,
          });
        }

        if (stateArray.length === 1) {
          props.onInputChange(STATE_SELECT_NAME, stateArray[0].alphaCode);
        } else {
          if (
            stateArray.find(
              (v: LocationState) => v.alphaCode === props.selectedState,
            ) == null
          ) {
            props.onInputChange(STATE_SELECT_NAME, null);
          }
        }

        setStateLoading(false);
        setStates(stateArray);
      }
    })();
  }, [props.selectedCountry]);

  return (
    <SimpleSelectField
      label={'State'}
      options={
        states?.map((state) => {
          return {
            label: humanize(state.name),
            value: state.alphaCode,
            selected: state.alphaCode === props.selectedState,
          };
        }) || []
      }
      isLoading={stateLoading}
      disabled={states == null || states?.length <= 1}
      value={props.selectedState}
      mandatory
      name={STATE_SELECT_NAME}
      onInputChange={props.onInputChange}
    />
  );
}

export function LocationFields(props: PropTypes) {
  return (
    <FormGroup>
      <h4>Location</h4>
      {props.isOrganic == false && (
        <>
          <TextField
            label='Address Line 1'
            value={props.form.address1}
            name='address1'
            onInputChange={props.onInputChange}
            mandatory={true}
            error={props.errors['workingLocation']?.['addressLine1']}
            placeholder={'Insert Address'}
          />
          <TextField
            label='Address Line 2'
            value={props.form.address2}
            name='address2'
            error={props.errors['workingLocation']?.['addressLine2']}
            onInputChange={props.onInputChange}
            placeholder={'Insert Address'}
          />
        </>
      )}
      <div className='d-flex gap-2'>
        <TextField
          className='w-50'
          label='City'
          value={props.form.city}
          name='city'
          error={props.errors['workingLocation']?.['city']}
          onInputChange={props.onInputChange}
          mandatory={true}
          placeholder={'Insert City'}
        />
        <TextField
          className='w-50'
          label='Zip Code'
          value={props.form.postcode}
          name='postcode'
          error={props.errors['workingLocation']?.['postcode']}
          onInputChange={props.onInputChange}
          mandatory={true}
          placeholder={'Insert Zip Code'}
        />
      </div>
      <CountrySelect
        countries={countries.all()}
        selectedCountry={props.form.country}
        onInputChange={props.onInputChange}
      />
      {props.isOrganic && (
        <StateSelect
          selectedCountry={props.form.country}
          selectedState={props.form.countryState}
          onInputChange={props.onInputChange}
        />
      )}
    </FormGroup>
  );
}
