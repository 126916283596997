import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { buildUrl } from '../../../../utils/url';

export interface JobDescriptionAiGenerateRequest {
  id: number;
  onMessage: (message: MessageEvent) => void;
  onEnd: () => void;
  jobName: string;
  locationId?: string;
  locationType: string;
  employmentType: string;
  experienceLevel?: string;
  templateJobPostId?: string;
  description?: string;
}
import { JobList } from '../../../../entities/v1/JobList';

export interface ListParams {
  id?: number;
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class JobService {
  public static list(params: ListParams) {
    return ApiService.get<JobList>(
      '/api/v1/applicant_tracking/jobs',
      snakecaseKeys(params),
    );
  }

  public static aiGenerateJobDescription(
    request: JobDescriptionAiGenerateRequest,
  ): void {
    const streamUrl = buildUrl(
      window.location.origin +
        `/api/stream/applicant_tracking/jobs/${request.id}/generate_description`,
      [
        { key: 'job_name', value: request.jobName },
        { key: 'location_id', value: request.locationId },
        { key: 'location_type', value: request.locationType },
        { key: 'employment_type', value: request.employmentType },
        { key: 'experience_level', value: request.experienceLevel },
        { key: 'template_job_post_id', value: request.templateJobPostId },
        { key: 'description', value: request.description },
      ],
    );

    const eventSource = new EventSource(streamUrl);

    eventSource.onmessage = request.onMessage;

    eventSource.onerror = (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close();
        request.onEnd();
      }
    };
  }
}
