import React, { useEffect, useState } from 'react';
import { SearchSegment } from '../../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import {
  GroupedOption,
  GroupedSelect,
} from '../../../../../../../../components/GroupedReactSelect';
import { SearchSegmentService } from '../../../../../../../../services/v1/applicant_tracking/SearchSegmentService';
import { SelectOption } from '../../../../../../../../components/Select';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  selectedSegment: SearchSegment;
  setSelectedSegment: (segment: SearchSegment) => void;
}

function isSelectedSegment(
  segment: SearchSegment,
  selectedSegment: SearchSegment,
) {
  if (selectedSegment === null) return segment.isDefault;

  return segment.id === selectedSegment.id;
}

function mapSegmentToOption(
  segment: SearchSegment,
  selectedSegment: SearchSegment,
): SelectOption {
  return {
    value: segment.id.toString(),
    label: segment.name,
    selected: isSelectedSegment(segment, selectedSegment),
  };
}

function buildGroupedOptions(
  segments: SearchSegment[],
  selectedSegment: SearchSegment,
): GroupedOption[] {
  return [
    {
      label: 'Public',
      options: segments
        .filter((segment) => !segment.isPrivate)
        .map((segment) => mapSegmentToOption(segment, selectedSegment)),
    },
    {
      label: 'My Own',
      options: segments
        .filter((segment) => segment.isPrivate)
        .map((segment) => mapSegmentToOption(segment, selectedSegment)),
    },
  ];
}

function findSelectedSegment(
  segments: SearchSegment[],
  selectedSegment: SelectOption,
): SearchSegment {
  return segments.find(
    (segment) => segment.id.toString() === selectedSegment.value,
  );
}

export function SegmentSelect(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [segments, setSegments] = useState<SearchSegment[]>([]);
  const [options, setOptions] = useState<GroupedOption[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<SelectOption>(null);

  const loadSegments = () => {
    setIsLoading(true);
    SearchSegmentService.list()
      .then((searchSegmentList) =>
        setSegments(searchSegmentList.searchSegments),
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (segments.find((segment) => segment.id === props.selectedSegment.id))
      return;

    loadSegments();
  }, [props.selectedSegment]);

  useEffect(() => {
    const newOptions = buildGroupedOptions(segments, props.selectedSegment);
    const newSelectedOption = newOptions
      .flatMap((group) => group.options)
      .find((option) => option.selected);

    setOptions(newOptions);
    setSelectedSegment(newSelectedOption);
  }, [segments]);

  useEffect(() => {
    selectedSegment &&
      props.setSelectedSegment(findSelectedSegment(segments, selectedSegment));
  }, [selectedSegment]);

  return (
    <div className={classNames('ms-3', styles['select-container'])}>
      <GroupedSelect
        mandatory
        isSearchable
        isClearable={false}
        options={options}
        isLoading={isLoading}
        selected={selectedSegment}
        onChange={(option) => setSelectedSegment(option)}
      />
    </div>
  );
}
