import React, { useEffect } from 'react';
import { LoadingData } from '../../../../../../components/LoadMoreButton';
import { LocationFields } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/LocationFields';
import {
  JobPostingDetailsForm,
  SalaryCurrency,
} from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails';
import { PostDetails } from '../../../../../../entities/applicant_tracking/jobboards/PostDetails';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { Location } from '../../../../../../entities/applicant_tracking/Location';
import { TargetGroupSelect } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/TargetGroup/TargetGroupSelect';
import { TargetGroupValues } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/TargetGroup/TargetGroupValues';
import { SalaryCurrency as SalaryCurrencyField } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/JobRequirementsFields/SalaryCurrency';
import { SalaryRange } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/JobRequirementsFields/SalaryRange';
import { SalaryPeriod } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/JobRequirementsFields/SalaryPeriod';
import { WeeklyRange } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/JobRequirementsFields/WeeklyRange';
import { FormGroup } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/FormGroup';
import { ContactEmail } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/ContactInformationFields/ContactEmail';
import { targetGroupFromValue } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/TargetGroup/utils';
import { SkillsInput } from './SkillsInput';
import { Link } from '../../../../../../components/Link';
import { NumberField } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/NumberField';
import { isSalaryRangeFilled } from '../../../../../JobPage/JobBoardsTab/utils/validators/sharedFormValidations';
import { addSeparatorToSalary } from '../../../../../../utils/addSeparatorToSalary';

interface PropTypes {
  isLoading: boolean;
  form: JobPostingDetailsForm;
  job: CreateJobDto;
  postDetails: PostDetails;
  targetGroup: TargetGroupValues;
  organicPostSkillsEnabled: boolean;
  formErrors: Record<string, any>;
  onInputChange: (name: string, value: unknown) => void;
  setForm: (form: JobPostingDetailsForm) => void;
}

const EMPTY_ERRORS = {};

export function prefillCampaignForm(
  job: CreateJobDto,
  location?: Location,
  postDetails?: PostDetails,
): JobPostingDetailsForm {
  return {
    title: postDetails?.title ?? job.name,
    // Automatic filled
    editorStateHtml: job.editorStateHtml,
    jobPostUrl: 'EMPTY',
    applicationFormUrl: 'EMPTY',
    companyId: postDetails?.companyId,
    //Location
    city: postDetails?.city ?? location?.city,
    postcode: postDetails?.postcode,
    country: postDetails?.country ?? location?.countryCode,
    countryState: postDetails?.countryState ?? location?.stateCode,
    // Contact Information
    contactEmail: postDetails?.contactEmail,
    // Job Requirements
    organicPostSkills: postDetails?.organicPostSkills ?? [],
    salaryPeriod: postDetails?.salaryPeriod,
    salaryCurrency: postDetails?.salaryCurrency as SalaryCurrency,
    salaryRangeFrom: addSeparatorToSalary(
      postDetails?.salaryRangeFrom?.toString(),
    ),
    salaryRangeTo: addSeparatorToSalary(postDetails?.salaryRangeTo?.toString()),
    weeklyHoursFrom: postDetails?.weeklyHoursFrom?.toString(),
    weeklyHoursTo: postDetails?.weeklyHoursTo?.toString(),
    // Reused in Target Group (for paid posts)
    industry: null,
    jobCategory: null,
    organicEnabled: postDetails?.organicEnabled || false,
  };
}

function TargetGroupAndSalary(props: PropTypes) {
  useEffect(() => {
    if (props.postDetails) {
      props.setForm({
        ...props.form,
        industry: targetGroupFromValue(
          props.targetGroup?.industries.find(
            (s) =>
              s.id.toString() ===
              (props.job.organicPostForm?.industry?.id?.toString() ??
                props.postDetails.industryId),
          ),
        ),
        jobCategory: targetGroupFromValue(
          props.targetGroup?.jobFunctions.find(
            (s) =>
              s.id.toString() ===
              (props.job.organicPostForm?.jobCategory?.id?.toString() ??
                props.postDetails.jobCategoryId),
          ),
        ),
      });
    }
  }, [props.targetGroup]);

  return (
    <div className='d-flex flex-column gap-3'>
      <TargetGroupSelect
        name={'jobCategory'}
        label='Job Category'
        values={props.targetGroup?.jobFunctions}
        selected={props.form.jobCategory}
        onInputChange={props.onInputChange}
      />
      <TargetGroupSelect
        name={'industry'}
        label='Industry'
        values={props.targetGroup?.industries}
        selected={props.form.industry}
        onInputChange={props.onInputChange}
      />
      {props.organicPostSkillsEnabled && (
        <SkillsInput
          name={'organicPostSkills'}
          selected={props.form.organicPostSkills}
          onInputChange={props.onInputChange}
        />
      )}
      <SalaryRange
        form={props.form}
        errors={props.formErrors}
        onInputChange={props.onInputChange}
      />
      <SalaryPeriod
        form={props.form}
        isClearable
        mandatory={isSalaryRangeFilled(props.form)}
        disabled={!isSalaryRangeFilled(props.form)}
        onInputChange={props.onInputChange}
      />
      <SalaryCurrencyField
        form={props.form}
        mandatory={isSalaryRangeFilled(props.form)}
        disabled={!isSalaryRangeFilled(props.form)}
        onInputChange={props.onInputChange}
      />
      <WeeklyRange
        form={props.form}
        errors={props.formErrors}
        onInputChange={props.onInputChange}
      />
    </div>
  );
}

export function JobOrganicPostsDetails(props: PropTypes) {
  return (
    <div className='border border-blue-gray-500 rounded-3 p-4'>
      <h3>Job Posting details</h3>
      {props.isLoading || props.form == null ? (
        <LoadingData />
      ) : (
        <div className='d-flex flex-column mt-4 gap-4'>
          <TargetGroupAndSalary {...props} />
          <FormGroup>
            <h4>Company</h4>
            <NumberField
              label='Linkedin Company ID'
              value={props.form.companyId}
              name='companyId'
              onInputChange={props.onInputChange}
              error={props.formErrors['linkedinCompanyId']}
              mandatory={false}
              placeholder={'e.g. 74028890'}
            >
              <Link
                href='https://www.linkedin.com/help/linkedin/answer/a415420/associating-your-linkedin-company-id-with-the-linkedin-job-board-frequently-asked-questions'
                classNames='text-info small'
                target='_blank'
              >
                Discover how to find your Linkedin Company ID
              </Link>
            </NumberField>
          </FormGroup>
          <LocationFields
            form={props.form}
            errors={EMPTY_ERRORS}
            onInputChange={props.onInputChange}
            isOrganic={true}
          />
          <FormGroup>
            <h4>Contact Information</h4>
            <ContactEmail
              form={props.form}
              errors={EMPTY_ERRORS}
              onInputChange={props.onInputChange}
            />
          </FormGroup>
        </div>
      )}
    </div>
  );
}
