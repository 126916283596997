import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';
import { User, UserList } from '../../../entities/v1/User';
import { EmailUserIntegration } from '../../../entities/EmailUserIntegration';

export interface ListParams {
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
  ignorePagination?: boolean;
  includeAllActiveUsers?: boolean;
}

export class UserService {
  public static list(params: ListParams): Promise<UserList> {
    return ApiService.get<UserList>('/api/v1/users', snakecaseKeys(params));
  }

  public static get(userId: number): Promise<User> {
    return ApiService.get<User>(`/api/v1/users/${userId}`);
  }

  public static showEmailIntegration(): Promise<EmailUserIntegration> {
    return ApiService.get<EmailUserIntegration>(
      '/api/v1/users/me/email_integration',
    );
  }
}
