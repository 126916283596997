import React from 'react';
import { CloseableModal } from '../../../../../../../../components/CloseableModal';
import { ModalBody } from './ModalBody';
import { ApplicationListingFilters } from '../../utils';
import {
  AlertObject,
  AlertType,
} from '../../../../../../../../components/Alert';
import { SearchSegment } from '../../../../../../../../entities/v1/applicant_tracking/SearchSegment';

interface PropTypes {
  isOpen: boolean;
  filters: ApplicationListingFilters;
  setAlert: (alert: AlertObject) => void;
  onClose: (succeeded?: boolean) => void;
  onCreateSegment: (segment: SearchSegment) => void;
}

export function SaveSegmentModal(props: PropTypes) {
  const onCreateSegment = (segment: SearchSegment) => {
    props.setAlert({
      message: (
        <>
          The segment <b>{segment.name}</b> was successfully saved!
        </>
      ),
      type: AlertType.Success,
    });
    props.onCreateSegment(segment);
    props.onClose(true);
  };

  return (
    <>
      <CloseableModal
        className='modal-dialog-centered'
        onClose={props.onClose}
        isOpen={props.isOpen}
        size='md'
        closeDisabled={false}
        keyboard={true}
        backdrop={true}
        headerTitle='Save as Segment'
        bodyChildren={
          <ModalBody
            filters={props.filters}
            onCreateSegment={onCreateSegment}
            onClose={props.onClose}
          />
        }
      />
    </>
  );
}
