import classNames from 'classnames';
import { Icon } from '../../../../../../../components/Icon';
import React from 'react';
import { GenerateState } from '../../../../../../../components/FillWithAIButton';

interface PropTypes {
  aiGenerateState: GenerateState;
}

function StayOnPageWarning() {
  return (
    <div
      className={classNames(
        'col-4 mx-auto text-center my-3 py-2 fs-5 bg-yellow-light text-primary rounded-2',
      )}
    >
      <Icon name='bi-info-circle' /> This may take a moment. Please stay on this
      page.
    </div>
  );
}

function EditInfo() {
  return (
    <div
      className={classNames(
        'col-7 mx-auto text-center my-3 py-2 fs-5 bg-light-info text-primary rounded-2',
      )}
    >
      <Icon name='bi-info-circle' /> If you change your mind, you can leverage
      the undo and redo functions in the description text editor.
    </div>
  );
}

export function FootnoteMessage(props: PropTypes) {
  switch (props.aiGenerateState) {
    case 'Generating':
      return <StayOnPageWarning />;
    case 'Regenerate':
      return <EditInfo />;
  }
  return null;
}
