import React from 'react';
import { Button } from 'reactstrap';
import { IconSpan } from '../IconSpan';

export type GenerateState =
  | 'Generate'
  | 'Generating'
  | 'Regenerate'
  | 'Canceling';

interface PropTypes {
  state: GenerateState;
  onClick: () => void;
  regenerateHelperText: string;
  buttonSize: string;
}

function GenerateState(props: PropTypes) {
  return (
    <Button
      type='button'
      color='borderless'
      onClick={props.onClick}
      size={props.buttonSize}
    >
      <IconSpan
        spanText='Fill with AI'
        className='text-nowrap ms-2'
        icon={{
          name: 'bi-stars',
        }}
      />
    </Button>
  );
}

function GeneratingState() {
  return (
    <IconSpan
      className='text-dark-500 fs-6 text-nowrap ms-2'
      spanText='AI is writing...'
      icon={{
        name: 'bi-stars',
      }}
    />
  );
}

function CancelingState() {
  return (
    <IconSpan
      className='text-dark-500 fs-6 text-nowrap ms-2'
      spanText='Canceling...'
      icon={{
        name: 'bi-stars',
      }}
    />
  );
}

function RegenerateState(props: PropTypes) {
  return (
    <div className='d-flex align-items-center ms-2'>
      <div className='fs-6 text-dark-200 me-2 pe-1 text-nowrap'>
        {props.regenerateHelperText}
      </div>
      <Button
        type='button'
        color='borderless'
        onClick={props.onClick}
        size={props.buttonSize}
      >
        <IconSpan
          spanText='Regenerate with AI'
          className='text-nowrap'
          icon={{
            name: 'bi-arrow-repeat',
          }}
        />
      </Button>
    </div>
  );
}

export function FillWithAIButton(props: PropTypes) {
  switch (props.state) {
    case 'Generate':
      return <GenerateState {...props} />;
    case 'Generating':
      return <GeneratingState />;
    case 'Regenerate':
      return <RegenerateState {...props} />;
    case 'Canceling':
      return <CancelingState />;
  }
}
