export function extractAllowedPhoneNumberInput(value?: string) {
  // Assumes that:
  //  - phone numbers may have a leading '+' sign (for country code purposes)
  //  - phone numbers only have numbers
  if (value == null) {
    return value;
  }

  const parsedValue = value.trim();
  const startsWithPlus = parsedValue.startsWith('+');

  const onlyDigits = parsedValue.replace(/\D/g, '');

  return `${startsWithPlus ? '+' : ''}${onlyDigits}`;
}
