import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';
import { Application } from '../../../entities/applicant_tracking/Application';
import { SubmitApplicationDto } from './SubmitApplicationDto';
import { CareersApplicationForm } from '../../../entities/applicant_tracking/CareersApplicationForm';
import { Source } from '../../../entities/applicant_tracking/Source';

export class CareersService {
  public static getApplicationForm(
    organizationSubdomain: string,
    jobPostId: number,
  ): Promise<CareersApplicationForm> {
    return ApiService.get<CareersApplicationForm>(
      `/api/applicant_tracking/careers/${organizationSubdomain}/job_posts/${jobPostId}/application_form`,
    );
  }

  public static getSources(organizationSubdomain: string): Promise<Source[]> {
    return ApiService.get<Source[]>(
      `/api/applicant_tracking/careers/${organizationSubdomain}/sources`,
    );
  }

  public static submitApplication(
    organizationSubdomain: string,
    jobPostId: number,
    props: SubmitApplicationDto,
  ): Promise<Application> {
    return ApiService.post<Application>(
      `/api/applicant_tracking/careers/${organizationSubdomain}/job_posts/${jobPostId}/submit`,
      snakecaseKeys({
        ...props,
        customQuestionAnswers: props.customQuestionAnswers?.filter((v) => v),
      }),
    );
  }
}
