import React from 'react';
import { LoadingSpinner } from '../../../components/LoadingSpinner';

interface PropTypes {
  redacted: boolean;
}

export function RedactingLoadingState(props: PropTypes) {
  return (
    <LoadingSpinner
      message={
        props.redacted ? 'Redacting candidate data from the CV...' : null
      }
    />
  );
}
