import React from 'react';
import { Col } from 'reactstrap';
import { AdditionalLabelledMultiSelect } from '..';
import { AnalyticsFiltersService } from '../../../../../services/v1/candidate_experience/Analytics';
import { MultiSelectAsyncFilter } from '../../../../MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../../utils/mapResultToSelectOption';

export function LocationsLabelledMultiSelectCol(props: {
  additionalLabelledMultiSelect: AdditionalLabelledMultiSelect;
}) {
  return (
    <Col xs='12' md='4' xl='2' className='mt-2'>
      <MultiSelectAsyncFilter
        isMulti
        label='Locations'
        loadOptions={(name, page) =>
          mapResultToSelectOption(
            AnalyticsFiltersService.locations({ name, page }),
            (filter) => filter.locations,
            true,
          )
        }
        onChange={props.additionalLabelledMultiSelect.handleFilterUpdate(
          'locations',
        )}
        selected={props.additionalLabelledMultiSelect.selected}
      />
    </Col>
  );
}
