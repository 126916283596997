import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { SelectOption } from '../../../../components/Select';
import { useForm } from 'react-hook-form';
import { RejectReason } from '../../../../entities/applicant_tracking/RejectReason';
import { LabelledSelect } from '../../../../components/LabelledSelect';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import classNames from 'classnames';
import { CheckboxButton } from '../../../../components/CheckboxButton';
import { CheckboxPillGroup } from '../../../../components/CheckboxPillGroup';
import { CheckboxPill } from '../../../../components/CheckboxPill';

interface PropTypes {
  applicationIds: number[];
  rejectReasons: RejectReason[];
  filterRejectedBy?: string;
  preSelectedRejectReasonId?: number;
  onClose: (succeeded?: boolean, rejectedReason?: string) => void;
  setPreSelectedRejectReasonId?: (preSelectedRejectReasonId: number) => void;
}

function buildOptions(options: RejectReason[]): SelectOption[] {
  return options
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((option) => ({
      label: option.name,
      value: option.id.toString(),
    }));
}

function findSelectedReasonById(id: number, options: SelectOption[]) {
  return options.find((option) => parseInt(option.value, 10) === id);
}

export function RejectionForm(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rejectedBy, setRejectedBy] = useState<string>(
    props.filterRejectedBy ?? 'organization',
  );
  const [rejectReasons, setRejectReasons] = useState<SelectOption[]>([]);
  const [selectedRejectReason, setSelectedRejectReason] = useState(null);
  const [storeSelectedReasonChecked, setStoreSelectedReasonChecked] =
    useState<boolean>(!!props.preSelectedRejectReasonId);
  const { handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      rejectedBy: rejectedBy,
    },
  });

  useEffect(() => {
    const filteredReasons = props.rejectReasons.filter(
      (reason) => reason.rejectedBy === rejectedBy,
    );
    const options = buildOptions(filteredReasons);
    setRejectReasons(options);
    setSelectedRejectReason(
      findSelectedReasonById(props.preSelectedRejectReasonId, options),
    );
  }, [rejectedBy]);

  useEffect(() => {
    if (selectedRejectReason && storeSelectedReasonChecked) {
      props.setPreSelectedRejectReasonId?.(
        parseInt(selectedRejectReason.value, 10),
      );
    }
  }, [storeSelectedReasonChecked, selectedRejectReason]);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);
    try {
      props.applicationIds.length === 1
        ? await ApplicationService.reject({
            applicationId: props.applicationIds[0],
            rejectReasonId: parseInt(selectedRejectReason.value, 10),
          })
        : await ApplicationService.bulkReject({
            applicationIds: props.applicationIds,
            rejectReasonId: parseInt(selectedRejectReason.value, 10),
          });

      props.onClose(true, selectedRejectReason.label);
    } catch (error) {
      props.onClose(false, selectedRejectReason.label);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  });

  const rejectedByPropsList = [
    {
      id: 'organization',
      label: 'We rejected them',
      value: 'organization',
    },
    {
      id: 'candidate',
      label: 'They rejected us',
      value: 'candidate',
    },
  ];

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <form onSubmit={onSubmit}>
        {!props.filterRejectedBy && (
          <>
            <div className='mt-4 mb-2 fs-5'>Rejection type</div>
            <CheckboxPillGroup
              childrenClassName={'fs-6'}
              selectedValue={rejectedBy}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setRejectedBy(e.currentTarget.value)
              }
            >
              {rejectedByPropsList.map((childProps: any, index: number) => (
                <CheckboxPill key={index} {...childProps} />
              ))}
            </CheckboxPillGroup>
          </>
        )}
        <div className={classNames(props.filterRejectedBy ? 'mt-1' : 'mt-4')}>
          <LabelledSelect
            options={rejectReasons}
            selected={selectedRejectReason}
            label={!props.filterRejectedBy && 'Rejection reason'}
            placeholder='Select rejection reason'
            className={'fw-normal fs-5'}
            onChange={(reason) => setSelectedRejectReason(reason)}
          />
        </div>
        <div
          className={classNames(
            'd-flex mt-5h align-items-center',
            props.setPreSelectedRejectReasonId
              ? 'justify-content-between'
              : 'justify-content-end',
          )}
        >
          {props.setPreSelectedRejectReasonId && (
            <CheckboxButton
              name={'storeSelectedReason'}
              labelSize='sm'
              label='Remember reason for this list'
              checked={storeSelectedReasonChecked}
              onChange={() =>
                setStoreSelectedReasonChecked(!storeSelectedReasonChecked)
              }
            />
          )}
          <div className='d-flex justify-content-end'>
            <Button
              className='me-2'
              color='danger'
              onClick={() => props.onClose(null)}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={!rejectedBy || !selectedRejectReason}
              type='submit'
            >
              Reject
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
