import React from 'react';
import { DateFilter } from '../../../../components/DateRangePicker';
import { Button, Col, Row } from 'reactstrap';
import { LabelledDatePicker } from '../../../../components/LabelledDatePicker';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SelectOption } from '../../../../components/Select';
import { LabelledSelect } from '../../../../components/LabelledSelect';
import { Job } from '../../../../entities/v1/applicant_tracking/Job';
import { Location } from '../../../../entities/v1/applicant_tracking/Location';
import {
  FilterResult,
  MultiSelectAsyncFilter,
} from '../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../utils/mapResultToSelectOption';
import { DepartmentService } from '../../../../services/v1/applicant_tracking/DepartmentService';
import { JobService } from '../../../../services/v1/applicant_tracking/JobService';
import { LocationService } from '../../../../services/v1/applicant_tracking/LocationService';

export const DEFAULT_FILTER_BY: SelectOption = {
  label: 'Departments',
  value: 'department',
};

export const FILTER_BY_OPTIONS: SelectOption[] = [
  DEFAULT_FILTER_BY,
  {
    label: 'Jobs',
    value: 'job',
  },
];

export interface FilterData {
  filterBys: SelectOption[];
  jobs: Job[];
  locations: Location[];
}

export interface FilterState {
  filterBy: SelectOption;
  entries: SelectOption[];
  locations: SelectOption[];
  dateRange: DateFilter;
}

interface PropTypes {
  filterState: FilterState;
  onFilterUpdate: (
    name: keyof FilterState,
    value: FilterState[keyof FilterState],
  ) => void;
  onApplyClick: () => void;
  onResetClick: () => void;
}

interface FiltersConfig {
  [key: string]: {
    label: string;
    load: (search: string, page: number) => Promise<FilterResult>;
  };
}

const filtersConfig: FiltersConfig = {
  department: {
    label: 'Departments',
    load: (name: string, page: number) =>
      mapResultToSelectOption(
        DepartmentService.list({ name, page }),
        (filter) => filter.departments,
      ),
  },
  job: {
    label: 'Jobs',
    load: (name: string, page: number) =>
      mapResultToSelectOption(
        JobService.list({ name, page }),
        (filter) => filter.jobs,
      ),
  },
};

function DynamicDataMultiSelectAsyncFilter(props: PropTypes) {
  const filterBy = props.filterState.filterBy;

  return (
    <MultiSelectAsyncFilter
      isMulti
      label={filterBy.label?.slice(0, -1)}
      loadOptions={filtersConfig[filterBy.value].load}
      onChange={(newValues) => props.onFilterUpdate('entries', newValues)}
      selected={props.filterState.entries}
      max={10}
    />
  );
}

export function Filters(props: PropTypes) {
  return (
    <div className='mb-3 w-100'>
      <Row className='gap-2 fs-5 h-100'>
        <Col xs='12' md='4' xl='2'>
          <LabelledSelect
            options={Object.keys(filtersConfig).map((key) => ({
              value: key,
              label: filtersConfig[key].label,
            }))}
            selected={props.filterState.filterBy}
            onChange={(newFilter) =>
              props.onFilterUpdate('filterBy', newFilter)
            }
            label='Filter by'
            className={'w-100'}
          />
        </Col>
        <Col xs='12' md='4' xl='2'>
          <DynamicDataMultiSelectAsyncFilter {...props} />
        </Col>
        <Col xs='12' md='4' xl='2'>
          <MultiSelectAsyncFilter
            isMulti
            label={'Locations'}
            loadOptions={(name: string, page: number) =>
              mapResultToSelectOption(
                LocationService.list({ name, page }),
                (filter) => filter.locations,
              )
            }
            onChange={(newValues) =>
              props.onFilterUpdate('locations', newValues)
            }
            selected={props.filterState.locations ?? []}
            max={10}
          />
        </Col>
        <Col xs='12' md='4' xl='2'>
          <LabelledDatePicker
            label='Date'
            dateFilter={props.filterState.dateRange}
            onChange={(start, end, range) =>
              props.onFilterUpdate('dateRange', { start, end, range })
            }
            className={'w-100'}
          />
        </Col>
        <Col
          xs='6'
          md='4'
          xl='2'
          className={classNames(
            'd-flex text-nowrap align-bottom align-self-end',
            styles['filter-buttons-column'],
          )}
        >
          <div
            className={classNames(
              'd-flex gap-3 justify-content-between p-0',
              styles['filter-buttons'],
            )}
          >
            <Button
              type='button'
              color='secondary'
              onClick={props.onResetClick}
            >
              Reset
            </Button>
            <Button type='button' color='primary' onClick={props.onApplyClick}>
              Apply
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
