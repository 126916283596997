import { isEmptyString } from '../isEmptyString';

export const PHONE_NUMBER_REGEX = /^\+?\d{8,15}$/;

export function validatePhoneNumber(value?: string) {
  if (isEmptyString(value)) {
    return;
  }

  const isValid = PHONE_NUMBER_REGEX.exec(value);
  if (!isValid) {
    return 'Phone numbers have between 8 and 15 digits';
  }
}
