import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetError,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  buildUpsertPlanTemplateDto,
  getReferenceTemplate,
  mapJobStageToJobStageInput,
} from '../../../utils/applicant_tracking/planTemplate';
import { Row } from 'reactstrap';
import { PlanTemplateBody } from './PlanTemplateBody';
import { StagePlanTemplateBody } from './StagePlanTemplateBody';
import React, { createContext, useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { UpsertPlanTemplateDto } from './DTOs/UpsertPlanTemplateDto';
import { FormErrorMessage } from '../../../components/FormErrorMessage';
import { PlanTemplate } from '../../../entities/applicant_tracking/PlanTemplate';
import { JobStageTemplate } from '../../../entities/applicant_tracking/JobStageTemplate';
import { StageMoveDTO } from './DTOs/StageMoveDTO';

interface PropTypes {
  redactionEnabled: boolean;
  editMode: boolean;
  planTemplate: PlanTemplate;
  viewMode: boolean;
  interviewTemplateEnabled: boolean;
}

export interface JobStageTemplateInput extends JobStageTemplate {
  uniqueKey: string;
  index: number;
}

export type TemplateType = 'start_from_scratch' | 'template';

export interface JobWorkflowEditContextProps {
  editMode: boolean;
  viewMode: boolean;
  control: Control<UpsertPlanTemplateDto, any>;
  errors: FieldErrors;
  showAddStage: boolean;
  isValid: boolean;
  jobStages: JobStageTemplateInput[];
  focusedJobStage: JobStageTemplateInput;
  planTemplate: PlanTemplate;
  templateType: TemplateType;
  referenceTemplate: PlanTemplate;
  isLoading: boolean;
  stageMoves: StageMoveDTO[];
  setStageMoves: (stageMoves: StageMoveDTO[]) => void;
  setTemplateType: (templateType: TemplateType) => void;
  register: UseFormRegister<UpsertPlanTemplateDto>;
  resetField: (name: string) => void;
  setError: UseFormSetError<any>;
  setPlanTemplate: (planTemplate: PlanTemplate) => void;
  setJobStages: (jobStages: JobStageTemplateInput[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
  handleSubmit: (
    onSubmit: (data: UpsertPlanTemplateDto) => Promise<void>,
  ) => any;
}

export const JobWorkflowEditContext = createContext(null);

export default function SetupPlanTemplatePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [focusedJobStage, setFocusedJobStage] =
    useState<JobStageTemplateInput>(null);
  const [showAddStage, setShowAddStage] = useState<boolean>(false);
  const [planTemplate, setPlanTemplate] = useState<PlanTemplate>(
    props.planTemplate,
  );
  const [referenceTemplate, setReferenceTemplate] =
    useState<PlanTemplate>(null);
  const [jobStages, setJobStages] = useState<JobStageTemplateInput[]>(
    mapJobStageToJobStageInput(props.planTemplate?.jobStages),
  );
  const [templateType, setTemplateType] =
    useState<TemplateType>('start_from_scratch');
  const [stageMoves, setStageMoves] = useState<StageMoveDTO[]>([]);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
    resetField,
  } = useForm<UpsertPlanTemplateDto>({
    mode: 'onChange',
    defaultValues: buildUpsertPlanTemplateDto(props.planTemplate, jobStages),
  });
  const referenceTemplateId = useWatch({
    control: control,
    name: 'planTemplateReferenceId',
  });

  const pageContext = {
    editMode: props.editMode,
    viewMode: props.viewMode,
    control,
    errors,
    jobStages,
    focusedJobStage,
    showAddStage,
    isValid,
    isLoading,
    planTemplate,
    templateType,
    referenceTemplate,
    stageMoves,
    setStageMoves,
    setTemplateType,
    handleSubmit,
    register,
    resetField,
    setError,
    setPlanTemplate,
    setJobStages,
    setFocusedJobStage,
    setIsLoading,
  } as JobWorkflowEditContextProps;

  const closeAddStage = () => {
    setShowAddStage(false);
    setFocusedJobStage(null);
  };

  useEffect(closeAddStage, [jobStages?.length]);

  useEffect(() => {
    getReferenceTemplate(referenceTemplateId).then((planTemplate) => {
      setReferenceTemplate(planTemplate);
    });
  }, [referenceTemplateId]);

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <JobWorkflowEditContext.Provider value={pageContext}>
        <Row className='row g-3 mb-4 align-items-center justify-content-between mx-2'>
          {errors.name?.message && <FormErrorMessage error={errors} />}
          <PlanTemplateBody setShowAddStage={setShowAddStage} />
          {(focusedJobStage || showAddStage) && (
            <StagePlanTemplateBody
              redactionEnabled={props.redactionEnabled}
              jobStage={focusedJobStage}
              onClose={closeAddStage}
              interviewTemplateEnabled={props.interviewTemplateEnabled}
            />
          )}
        </Row>
      </JobWorkflowEditContext.Provider>
    </>
  );
}
