import classNames from 'classnames';
import React from 'react';
import styles from '../styles.module.scss';

interface PropTypes {
  children: React.ReactNode;
  className?: string;
  alertDanger?: boolean;
}

export function TriggerRowDropdown(props: PropTypes) {
  return (
    <div
      className={classNames(
        'col align-self-start',
        styles['dropdown'],
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}
