import React from 'react';
import { UseFormRegister, UseFormTrigger } from 'react-hook-form';
import { CheckboxButton } from '../../../../../../components/CheckboxButton';
import * as DOMPurify from 'dompurify';

interface PropTypes {
  candidateConsentEnabled: boolean;
  previewMode: boolean;
  candidateConsentHtml?: string;
  formRegister: UseFormRegister<any>;
  reValidate: UseFormTrigger<any>;
}

export function ApplicationFormCandidateConsent(props: PropTypes) {
  if (!props.candidateConsentEnabled) return null;

  const { onChange, onBlur, name, ref } = props.formRegister(
    'candidateConsented',
    {
      required: props.candidateConsentEnabled,
    },
  );

  const html = prepareHtml(props.candidateConsentHtml);

  return (
    <div className='mt-4'>
      <CheckboxButton
        name={name}
        onBlur={onBlur}
        checkboxRef={ref}
        label={
          html ? (
            <div
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          ) : (
            <>
              I accept and consent the use of my personal information for this
              and future job opportunities.
            </>
          )
        }
        labelSpanClasses='ps-3 ps-sm-2'
        disabled={props.previewMode}
        mandatory={!html}
        onChange={(ev: React.FormEvent<HTMLInputElement>) => {
          onChange(ev ?? null);
          props.reValidate();
        }}
      />
    </div>
  );
}

function prepareHtml(html: string) {
  const el = document.createElement('div');
  el.innerHTML = html;

  if (!el.textContent.trim()) return null;

  // This is the easiest way I could figure out of making the "required"
  // asterisk come after the last paragraph.

  const paragraphs = el.querySelectorAll('p');
  const lastParagraph = paragraphs[paragraphs.length - 1];

  lastParagraph.insertAdjacentHTML(
    'beforeend',
    "<span class='ps-2 text-danger'>*</span>",
  );

  return DOMPurify.sanitize(el.innerHTML);
}
