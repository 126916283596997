export function buildPassThroughRateValue(passThroughRate?: number): string {
  if (passThroughRate == null) {
    return null;
  }

  const percentage = passThroughRate * 100;

  if (percentage > 0 && percentage < 1) {
    return percentage.toFixed(2);
  }

  return percentage.toFixed(0);
}
