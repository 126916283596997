import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { IconButton } from '../../../../../components/IconButton';
import { PageTitle } from '../../../../../components/PageTitle';
import { Alert, AlertObject, AlertType } from '../../../../../components/Alert';
import { BulkActionButtons } from './BulkActionButtons';
import { ListCandidate } from '../../../../../entities/applicant_tracking/ListCandidate';
import { AccountIntegration } from '../../../../../entities/AccountIntegration';
import { User } from '../../../../../entities/User';
import { HrisEmployee } from '../../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../../enums/IntegrationProvider/HrisProvider';

export interface AlertMessage {
  type: string;
  name: string;
}

interface PropTypes {
  alertMessage: AlertMessage;
  candidates: ListCandidate[];
  currentUser: User;
  selectedCandidates: ListCandidate[];
  addCandidateEnabled: boolean;
  emailAccountIntegration: AccountIntegration;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
  hrisEmployees: HrisEmployee[];
  hrisProvider?: HrisProvider;
  bulkSelfSchedulingEnabled: boolean;
}

export function ListApplicationsPageHeader(props: PropTypes) {
  const [alertActive, setAlertActive] = useState<boolean>(
    props.alertMessage !== null,
  );

  return (
    <Row className='mb-4 align-items-center justify-content-between'>
      {alertActive && (
        <Alert
          type={AlertType.Success}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlertActive(false)}
        >
          <span>
            The candidate{' '}
            <span className='fw-bold'>{props.alertMessage.name}</span> was
            successfully {props.alertMessage.type}.
          </span>
        </Alert>
      )}
      <Col xs='auto'>
        <PageTitle text={'Candidates'} />
      </Col>
      <Col xs='auto'>
        {props.selectedCandidates.length > 0 && (
          <BulkActionButtons {...props} />
        )}
        <IconButton
          color='primary'
          iconName='bi-person-check'
          iconClass={'fs-4'}
          buttonClass={classNames('w-auto')}
          iconTextClass={classNames('fs-5', 'fw-normal')}
          buttonText='Add Candidate'
          onClick={() => {
            window.location.href = '/applicant_tracking/candidate_wizard';
          }}
          disabled={!props.addCandidateEnabled}
        />
      </Col>
    </Row>
  );
}
