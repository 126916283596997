import { getQueryParam } from '../../../url';
import {
  CareersJobBoardParams,
  ORGANIC_POSTING_PARAM,
} from '../careers_job_board_params';

export function parseJobBoardParams(): CareersJobBoardParams {
  const campaign = getQueryParam('vq_campaign');
  const vq_source = getQueryParam('vq_source');

  let source = vq_source ?? getQueryParam('sl_source');

  // Source is required, thus not empty
  // If value is organic-posting-network, then no campaign id is expected
  // Else, then a campaign is expected, though not required
  if (source == null && campaign == null) return null;

  if (source != null && vq_source == null) {
    // Accept only the well-know sources
    if (source === 'website') {
      return { source, campaign: null, provider: null };
    } else if (source === 'organic-posting-network') {
      source = ORGANIC_POSTING_PARAM;
    } else {
      return null;
    }
  }

  const provider = 'vonq';

  return {
    source,
    campaign,
    provider,
  };
}
