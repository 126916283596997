import { useEffect, useState } from 'react';
import { FilterState } from '../Filters';

type FetchFn<T> = (filters: FilterState, signal: AbortSignal) => Promise<T>;
type CallbackFn<T> = (data: T) => void;

function handleError(e: any) {
  if (e.name === 'AbortError') return;
  throw e;
}

export function useAnalyticsData<T>(
  fetchFn: FetchFn<T>,
  filters: FilterState,
  successCallback?: CallbackFn<T>,
) {
  const [data, setData] = useState<T>(null);

  useEffect(() => {
    const controller = new AbortController();

    fetchFn(filters, controller.signal)
      .then((data) => {
        setData(data);
        return data;
      })
      .then(successCallback)
      .catch(handleError);

    return () => {
      try {
        controller.abort();
      } catch (e) {
        handleError(e);
      }
    };
    // React deep object equality doesn't work. Stringifying the dependency is a
    // quick and easy workaround.
  }, [JSON.stringify(filters)]);

  return data;
}
