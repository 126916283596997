import React, { ReactElement } from 'react';
import { GenericEntry } from '../..';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { ApplicationFeedEmail } from '../../../../../entities/ApplicationFeed';
import { Avatar } from '../../../../../components/Avatar';
import IframeResizer from 'iframe-resizer-react';
import * as DOMPurify from 'dompurify';
import { AccountIntegration } from '../../../../../entities/AccountIntegration';
import { AlertObject } from '../../../../../components/Alert';
import { AttachmentList } from './AttachmentList';

interface PropTypes {
  application: Application;
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  entry: ApplicationFeedEmail;
  isLastItemInPage: boolean;
  title: ReactElement;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

function removeGmailQuote(doc: Document) {
  try {
    const gmailQuote = doc.querySelector('.gmail_quote');
    if (gmailQuote) {
      gmailQuote.remove();
    }
  } catch (e) {
    console.warn('Could not remove the Gmail quote.', e);
  }
}

function removeOutlookQuote(doc: Document) {
  try {
    const emailBody = doc.querySelector('body');
    const outlookQuote = doc?.querySelector('#appendonsend');
    if (outlookQuote) {
      let outlookQuoteIndex = -1;

      for (let i = 0; i < emailBody.children.length; i++) {
        const childElement = emailBody.children.item(i);
        if (childElement === outlookQuote) {
          outlookQuoteIndex = i;
        }
      }

      // Remove all elements after the appendonsend div.
      if (outlookQuoteIndex >= 0) {
        while (emailBody.children.length > outlookQuoteIndex) {
          emailBody.children.item(outlookQuoteIndex).remove();
        }
      }
    }
  } catch (e) {
    console.warn('Could not remove the Outlook quote.', e);
  }
}

function removeAppleMailQuote(doc: Document) {
  try {
    const appleQuote = doc.querySelector('blockquote');
    if (appleQuote) {
      appleQuote.remove();
    }
  } catch (e) {
    console.warn('Could not remove the Apple Mail quote.', e);
  }
}

function addScreenloopStyles(doc: Document) {
  const style = doc.createElement('style');
  style.innerHTML = `
    @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');

    * {
      font-family: 'Nunito Sans', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
      font-size: 14px !important;
    }
    `;
  doc.head.appendChild(style);
}

function adjustEmailHtml(html: string, applicationStylesPath: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  removeGmailQuote(doc);
  removeOutlookQuote(doc);
  removeAppleMailQuote(doc);

  doc.documentElement.innerHTML = DOMPurify.sanitize(
    doc.documentElement.innerHTML,
    {
      WHOLE_DOCUMENT: true,
      ADD_TAGS: ['meta'],
      ADD_ATTR: ['content'],
    },
  );

  // Ensure iframe's links are opened in new tabs.
  const base = doc.createElement('base');
  base.setAttribute('target', '_blank');
  doc.head.appendChild(base);

  // Add script to automatically resize iframe.
  const script = doc.createElement('script');
  script.src = '/iframeResizer.contentWindow.js';
  script.defer = true;
  doc.head.appendChild(script);

  // Add our own styles if the email has none.
  const style = doc.head.querySelector('style');
  if (!style) {
    const link = doc.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = applicationStylesPath;
    doc.head.appendChild(link);

    doc.body.classList.add('bg-transparent', 'fs-5');
  }
  addScreenloopStyles(doc);

  return doc.documentElement.innerHTML;
}

function EmailEntryBody(props: PropTypes) {
  const authorName =
    props.application.candidate.email === props.entry.email.from
      ? props.application.candidate.name
      : props.entry.authorName;

  return (
    <div className='d-flex flex-nowrap align-items-start'>
      <Avatar className='me-3' name={authorName} size='lg' />
      <div className='w-100 rounded-3 bg-gray py-3 px-4'>
        <div>
          <b>{authorName}</b>{' '}
          <small className='text-dark-200'>({props.entry.email.from})</small>
        </div>
        <div className='mt-3'>
          <IframeResizer
            srcDoc={adjustEmailHtml(
              props.entry.email.html,
              props.applicationStylesPath,
            )}
            scrolling={false}
            checkOrigin={[window.location.origin]}
            style={{ width: '1px', minWidth: '100%' }}
          />
        </div>
        <AttachmentList attachments={props.entry.email.attachments} />
      </div>
    </div>
  );
}

export function EmailEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-envelope'
      iconClassName='bg-blue-gray-light'
      title={props.title}
      date={props.entry.createdAt}
      {...props}
    >
      <EmailEntryBody {...props} />
    </GenericEntry>
  );
}
