import React, { useState } from 'react';
import { appendQueryParam, getQueryParam } from '../../../../../utils/url';

const SEARCH_PARAM = 'name';

export function SearchBox() {
  const [searchText, setSearchText] = useState<string>(
    getQueryParam(SEARCH_PARAM) ?? '',
  );

  return (
    <form
      className='row gx-1 align-items-center me-3'
      onSubmit={(ev) => {
        ev.preventDefault();
        window.location.href = appendQueryParam(
          window.location.href,
          SEARCH_PARAM,
          searchText,
        );
      }}
    >
      <input
        type='text'
        name={SEARCH_PARAM}
        value={searchText}
        onChange={(ev) => setSearchText(ev.target.value)}
        placeholder='Search'
        className='form-control search-input'
        style={{ width: '250px' }}
      />
    </form>
  );
}
