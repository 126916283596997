import { PlanTemplate } from '../../../../../../entities/applicant_tracking/PlanTemplate';
import { SelectOption } from '../../../../../../components/Select';
import { JobStageTemplate } from '../../../../../../entities/applicant_tracking/JobStageTemplate';
import { EmailTemplate } from '../../../../../../entities/EmailTemplate';
import { InterviewTemplate } from '../../../../../../entities/v1/applicant_tracking/InterviewTemplate';
import { FilterResult } from '../../../../../../components/MultiSelectAsyncFilter';
import { humanize } from '../../../../../../utils/humanize';

export function mapStagesToSelectOption(
  name?: string,
  planTemplate?: PlanTemplate,
  selectedValues?: number[],
  onlyStagesWithInterviews: boolean = false,
): Promise<FilterResult> {
  let jobStages = planTemplate?.jobStages;

  if (!jobStages) {
    return Promise.resolve({
      options: [],
      entriesCount: 0,
      page: 1,
      pageCount: 1,
      nextPage: null,
    });
  }

  if (onlyStagesWithInterviews) {
    jobStages = jobStages.filter((jobStage: JobStageTemplate) =>
      containsInterviews(jobStage),
    );
  }

  if (name != null) {
    jobStages = jobStages.filter((jobStage: JobStageTemplate) =>
      jobStage.name.toLowerCase().includes(name.toLowerCase()),
    );
  }

  return Promise.resolve({
    options: jobStages.map((jobStage: JobStageTemplate) => {
      return {
        value: jobStage.jobStageId.toString(),
        label: jobStage.name,
        selected: selectedValues?.some((v) => v === jobStage.jobStageId),
      };
    }),
    entriesCount: jobStages?.length,
    page: 1,
    pageCount: 1,
    nextPage: null,
  });
}

export function mapApplicationStatusesToSelectOptions(
  name?: string,
  selectedValues?: string[],
): Promise<FilterResult> {
  const statusOptions = ['active', 'hired', 'rejected'];

  return Promise.resolve({
    options: statusOptions
      .filter((opt: string) =>
        name == null ? true : opt.toLowerCase().includes(name.toLowerCase()),
      )
      .map((status: string) => {
        return {
          value: status,
          label: humanize(status),
          selected: selectedValues?.some((v) => v === status),
        };
      }),
    entriesCount: statusOptions.length,
    page: 1,
    pageCount: 1,
    nextPage: null,
  });
}

function containsInterviews(jobStage: JobStageTemplate): boolean {
  return !!(jobStage.interviewKitTemplate || jobStage.scorecardTemplate);
}

export function mapEmailTemplatesToSelectOptions(
  emailTemplates: EmailTemplate[],
  selectedValue?: string,
): SelectOption[] {
  return emailTemplates.map((emailTemplate: EmailTemplate) => {
    return {
      value: emailTemplate.id.toString(),
      label: emailTemplate.name,
      selected: emailTemplate.id.toString() === selectedValue,
    };
  });
}

export function mapInterviewTemplatesToSelectOptions(
  interviewTemplates: InterviewTemplate[],
  selectedValue?: string,
): SelectOption[] {
  return interviewTemplates.map((interviewTemplate: InterviewTemplate) => {
    return {
      value: interviewTemplate.id.toString(),
      label: interviewTemplate.name,
      selected: interviewTemplate.id.toString() === selectedValue,
    };
  });
}

export function mapIntToSelectOptions(selectedValue?: string): SelectOption[] {
  return [...Array(8).keys()].map((value: number) => {
    return {
      value: value.toString(),
      label: value.toString(),
      selected: value.toString() === selectedValue,
    };
  });
}
