import React from 'react';
import { FeedbackFilters } from '.';
import { MultiSelectAsyncFilter } from '../../../../components/MultiSelectAsyncFilter';
import { Application } from '../../../../entities/Application';
import { mapResultToSelectOption } from '../../../../utils/mapResultToSelectOption';
import { JobStageService } from '../../../../services/v1/applicant_tracking/JobStageService';

interface PropTypes {
  application: Application;
  isLoading: boolean;
  filters: FeedbackFilters;
  setFilters: (filters: FeedbackFilters) => void;
}

export function JobStagesFilter(props: PropTypes) {
  const loadJobStages = (name: string, page: number) =>
    mapResultToSelectOption(
      JobStageService.list({
        jobId: props.application.job.id,
        name,
        page,
      }),
      (filter) => filter.jobStages,
      false,
    );

  return (
    <MultiSelectAsyncFilter
      isMulti
      label='Stages'
      loadOptions={loadJobStages}
      onChange={(jobStages) =>
        props.setFilters({ ...props.filters, jobStages })
      }
      className='mt-4 ps-0 fs-5 fw-semibold'
      disabled={props.isLoading}
      selected={props.filters?.jobStages || []}
    />
  );
}
