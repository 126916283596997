import classNames from 'classnames';
import React from 'react';
import { ChangeHandler, RefCallBack } from 'react-hook-form';
import styles from './styles.module.scss';
import { sizeSmallerThan, useScreenSize } from '../../utils/useScreenSize';
import { Size } from '../../utils/types/Size';

export interface InputLabelPropTypes {
  htmlFor?: string;
  size?: Size;
  labelComponent?: JSX.Element;
  label?: React.ReactNode;
  labelSpanClasses?: string;
  mandatory?: boolean;
}

export interface PropTypes {
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: ChangeHandler;
  checkboxRef?: RefCallBack;
  name: string;
  label?: React.ReactNode;
  labelSize?: Size;
  labelClasses?: string;
  labelSpanClasses?: string;
  labelInputClasses?: string;
  labelComponent?: JSX.Element;
  checked?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
}

function Label(props: InputLabelPropTypes) {
  const fontSize = props.size === 'sm' ? 'fs-6' : 'fs-5';

  if (props.labelComponent != null) {
    return props.labelComponent;
  } else if (props.label != null) {
    return (
      <label
        className={classNames(
          'ps-2 fw-normal',
          fontSize,
          props.labelSpanClasses,
        )}
        htmlFor={props.htmlFor}
      >
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
    );
  } else {
    return null;
  }
}

export function CheckboxButton(props: PropTypes) {
  const size = sizeSmallerThan(useScreenSize(), 'sm') && 'lg';

  return (
    <div
      className={classNames('d-flex align-items-center', props.labelClasses)}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        ref={props.checkboxRef}
        onBlur={props.onBlur}
        className={classNames(
          'form-check-input mt-0',
          props.labelInputClasses,
          styles[size],
        )}
        type='checkbox'
        name={props.name}
        id={props.name}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
      />
      <Label
        label={props.label}
        labelSpanClasses={props.labelSpanClasses}
        labelComponent={props.labelComponent}
        mandatory={props.mandatory}
        htmlFor={props.name}
        size={props.labelSize}
      />
    </div>
  );
}
