import React from 'react';
import { ApplicationListingFilters } from '../../utils';
import { Col, Input } from 'reactstrap';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function LocationFilter(props: PropTypes) {
  const handleChange = (value: string) => {
    props.setFilters({
      ...props.filters,
      location: value,
    });
  };

  return (
    <Col xs='3' className='mt-3h'>
      <label>
        <h5>Location</h5>
      </label>
      <Input
        className='form-control fs-5'
        placeholder='Search location'
        value={props.filters?.location ?? ''}
        disabled={props.isLoading}
        onChange={(v) => handleChange(v.target.value)}
      />
    </Col>
  );
}
