import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import {
  SearchSegment,
  SearchSegmentFilter,
  SearchSegmentList,
} from '../../../../entities/v1/applicant_tracking/SearchSegment';

export class SearchSegmentService {
  public static list(): Promise<SearchSegmentList> {
    return ApiService.get<SearchSegmentList>(
      '/api/v1/applicant_tracking/search_segments',
    );
  }

  public static create(searchSegmentRequest: {
    name: string;
    isPrivate: boolean;
    filter: SearchSegmentFilter;
  }): Promise<SearchSegment> {
    return ApiService.post<SearchSegment>(
      '/api/v1/applicant_tracking/search_segments',
      snakecaseKeys({ searchSegmentRequest }),
    );
  }

  public static update(
    id: number,
    searchSegmentRequest: SearchSegmentFilter,
  ): Promise<SearchSegment> {
    return ApiService.put<SearchSegment>(
      `/api/v1/applicant_tracking/search_segments/${id}`,
      snakecaseKeys({ searchSegmentRequest }),
    );
  }
}
