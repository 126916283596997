import React from 'react';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { ApplicationFeedEmail } from '../../../../../entities/ApplicationFeed';
import { EmailEntry } from '../EmailEntry';
import { AccountIntegration } from '../../../../../entities/AccountIntegration';
import { AlertObject } from '../../../../../components/Alert';

interface PropTypes {
  application: Application;
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  entry: ApplicationFeedEmail;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function EmailReceivedEntry(props: PropTypes) {
  return (
    <EmailEntry
      title={
        <>
          <strong>{props.application.candidate.name}</strong> sent an email.
        </>
      }
      emailAccountIntegration={props.emailAccountIntegration}
      {...props}
    />
  );
}
