import classNames from 'classnames';
import React from 'react';
import { extractAllowedPhoneNumberInput } from '../../../../../utils/extractors/extractAllowedPhoneNumberInput';
import { validatePhoneNumber } from '../../../../../utils/validators/phoneNumberValidator';

export function PhoneNumberField(props: {
  label: string;
  value: string;
  name: string;
  className?: string;
  mandatory?: boolean;
  placeholder?: string;
  error?: string;
  onInputChange: (name: string, value: unknown) => void;
}) {
  const err = validatePhoneNumber(props.value) ?? props.error;

  return (
    <div className={props.className}>
      <label className='form-label'>
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
      <input
        type='text'
        className={classNames('form-control fs-5', {
          'is-invalid border-danger': err,
        })}
        value={props.value ?? undefined}
        onChange={(event) =>
          props.onInputChange(
            props.name,
            extractAllowedPhoneNumberInput(event.target.value),
          )
        }
        placeholder={props.placeholder}
      />
      {err && <div className='text-danger-500 mt-2 fs-5'>{err}</div>}
    </div>
  );
}
