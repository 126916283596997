import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { StageTuple } from '..';
import { JobStage } from '../../../../../../../../entities/v1/applicant_tracking/JobStage';
import { PlanTemplate } from '../../../../../../../../entities/v1/applicant_tracking/PlanTemplate';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../../../../../components/Select';
import { StagePlanTemplate } from '../../../../../../../../entities/v1/applicant_tracking/StagePlanTemplate';
import { LoadingSpinner } from '../../../../../../../../components/LoadingSpinner';
import { HorizontalDivider } from '../../../../../../../../components/HorizontallDivider';
import { getLabelForCount } from '../../../../../../../../utils/grammar';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { Icon } from '../../../../../../../../components/Icon';
import { AlertMessage } from '../../../../../../../../components/AlertMessage';
import { AsyncSelect } from '../../../../../../../../components/Select/async';

interface PropTypes {
  mapping: Map<number, StageTuple>;
  oldStages: JobStage[];
  newStages: () => Promise<PlanTemplate>;
  onSelectedStages: (oldStageId: number, newStage: StageTuple) => void;
}

async function filterStages(
  jobStages: Promise<PlanTemplate>,
  q: string,
): Promise<SelectOption[]> {
  return jobStages.then((list) =>
    list.stagePlanTemplates
      .filter((jobStage) =>
        jobStage.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((jobStage) => ({
        value: jobStage.id.toString(),
        label: jobStage.name,
      })),
  );
}

function selectedOption(
  mapping: Map<number, StageTuple>,
  stageId: number,
): SelectOption {
  const selected = mapping.get(stageId);

  if (selected == null) {
    return null;
  }

  return {
    value: selected.id.toString(),
    label: selected.label.toString(),
    selected: true,
  };
}

function BodyHeader() {
  return (
    <>
      <span className='fs-5'>
        Move all candidates to a different stage before changing the Job
        Workflow.
      </span>
      <Row className='mt-4'>
        <Col xs='3'>
          <span className='fs-5 fw-semibold'>Nr of Candidates</span>
        </Col>
        <Col xs='3'>
          <span className='fs-5 fw-semibold'>Current Stage</span>
        </Col>
        <Col xs='1'></Col>
        <Col xs='5'>
          <span className='fs-5 fw-semibold'>New Stage</span>
          <span className={classNames('d-inline text-danger fs-5 ps-1 mt-0')}>
            *
          </span>
        </Col>
      </Row>
    </>
  );
}

function StageConflictRow(
  props: PropTypes & { options: Promise<PlanTemplate>; stage: JobStage },
) {
  async function handleChange(oldStage: JobStage, option: SelectOption) {
    return props.options
      .then((i) =>
        i.stagePlanTemplates.find(
          (x: StagePlanTemplate) => x.id.toString() === option.value,
        ),
      )
      .then((selected: StagePlanTemplate) => {
        props.onSelectedStages(oldStage.id, {
          id: selected.id,
          label: selected.name,
        });
      });
  }

  return (
    <Fragment>
      <HorizontalDivider classNames='mt-3 mb-2h' />
      <Row>
        <Col xs='3'>
          <span className='fw-semibold fs-5'>
            {getLabelForCount('Candidate', props.stage.applicationsCount)}
          </span>
        </Col>
        <Col xs='3'>
          <Chip className='mw-100' truncate color={ChipColor.BlueLight}>
            {props.stage.name}
          </Chip>
        </Col>
        <Col xs='1'>
          <Icon className='fw-semibold fs-5' name='bi-arrow-right' />
        </Col>
        <Col xs='5'>
          <AsyncSelect
            size='sm'
            placeholder='Select New Stage'
            onChange={(option: SelectOption) =>
              handleChange(props.stage, option)
            }
            loadOptions={(input: string) => filterStages(props.options, input)}
            selected={selectedOption(props.mapping, props.stage.id)}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export function Body(props: PropTypes) {
  const [options] = useState(props.newStages);

  return (
    <Fragment>
      {props.oldStages == null ? (
        <LoadingSpinner />
      ) : (
        <>
          <BodyHeader />
          <div>
            {props.oldStages.map((stage: JobStage, index: number) => {
              return (
                <StageConflictRow
                  key={index}
                  {...props}
                  stage={stage}
                  options={options}
                />
              );
            })}
          </div>
          <AlertMessage
            textClassName='fs-5'
            className='mt-4 bg-yellow-light'
            icon={{ className: 'fs-5', name: 'bi-info-circle' }}
            text='Scheduled interviews will stay in the previous stage. Review automations after changing the Workflow.'
          />
        </>
      )}
    </Fragment>
  );
}
