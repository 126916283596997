import {
  PipelineApplication,
  PipelineApplications,
} from '../../../entities/applicant_tracking/PipelineApplication';
import { ApiService } from '../../ApiService';

export class PipelineApplicationService {
  public static list(jobId: string): Promise<PipelineApplications> {
    return ApiService.get<PipelineApplications>(
      `/api/v1/applicant_tracking/jobs/${jobId}/pipeline_applications`,
    );
  }

  public static show(id: number): Promise<PipelineApplication> {
    return ApiService.get<PipelineApplication>(
      `/api/v1/applicant_tracking/pipeline_applications/${id}`,
    );
  }
}
