import React from 'react';
import { OfferFeed } from '../../../../../entities/ApplicationFeed';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { AlertObject } from '../../../../../components/Alert';
import { GenericEntry } from '../..';
import { getNounWithPossessiveCase } from '../../../../../utils/grammar';

interface PropTypes {
  application: Application;
  currentUser: User;
  isLastItemInPage: boolean;
  entry: OfferFeed;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function OfferRevoked(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-exclamation-circle'
      iconClassName='bg-light-warning'
      title={
        <span>
          <strong>{props.entry.authorName}</strong> {' has revoked '}
          <strong>
            {getNounWithPossessiveCase(props.application.candidate.name)}
          </strong>{' '}
          Offer.
        </span>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
