import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SortingIcon } from '../../../../../../../components/SortingIcon';
import { TableHeader as TableHeaderLabel } from '../../../../../../../components/TableHeader';
import { CheckboxButton } from '../../../../../../../components/CheckboxButton';
import { SortingColumn } from '../../../../../../../utils/sorting';

interface PropTypes {
  columns: ColumnPropTypes[];
  sortColumn: SortingColumn;
  checked: boolean;
  checkboxDisabled?: boolean;
  onSelectedCandidatesUpdate: (checked: boolean) => void;
  onSortUpdate: (sortingColumn: SortingColumn) => void;
}

interface ColumnPropTypes {
  key: string;
  label: string;
  type: string;
  tooltipText?: string;
  isSortable?: boolean;
  className?: string;
}

function TableHeaderColumnName(
  props: PropTypes & {
    tag: string;
    label: string;
    idx: number;
    type: string;
    tooltipText: string;
    isSortable: boolean;
  },
) {
  return props.isSortable ? (
    <a
      className={classNames('d-flex justify-content-start align-items-center')}
      href={'#'}
      onClick={() => props.onSortUpdate(props.sortColumn)}
    >
      <TableHeaderLabel label={props.label} tooltipText={props.tooltipText} />
      <SortingIcon
        sortDirection={props.sortColumn.direction}
        isSelected={props.tag === props.sortColumn.columnName}
        className={'ms-auto'}
      />
    </a>
  ) : (
    <TableHeaderLabel label={props.label} tooltipText={props.tooltipText} />
  );
}

export function TableHeader(props: PropTypes) {
  const [checked, setChecked] = useState(false);
  const onCheck = () => {
    props.onSelectedCandidatesUpdate(!checked);
    setChecked(!checked);
  };
  useEffect(() => setChecked(props.checked), [props.checked]);

  return (
    <thead className={classNames('text-nowrap')}>
      <tr>
        <th
          className={classNames(
            styles['table-head-border-bottom'],
            'pb-3 left-col right-col',
          )}
        >
          <CheckboxButton
            name={null}
            label={null}
            checked={checked}
            onChange={onCheck}
            disabled={props.checkboxDisabled}
          />
        </th>
        {props.columns.map(
          ({ key, label, tooltipText, type, isSortable, className }, idx) => {
            return (
              <th
                className={classNames(
                  { 'right-col': idx === props.columns.length - 1 },
                  styles['table-head-border-bottom'],
                  'pb-3',
                  className,
                )}
                key={idx}
              >
                <TableHeaderColumnName
                  {...props}
                  tag={key}
                  label={label}
                  idx={idx}
                  tooltipText={tooltipText}
                  type={type}
                  isSortable={isSortable ?? true}
                />
              </th>
            );
          },
        )}
      </tr>
    </thead>
  );
}
