import React, { useEffect, useState } from 'react';
import { User } from '../../../../../entities/User';
import { FeedbackFilters } from '../../Filters';
import { ApplicationRatingService } from '../../../../../services/v1/applicant_tracking/ApplicationRatingService';
import { EmptyFeedback } from '../EmptyStates/EmptyFeedback';
import { CandidateRatingBlock } from './CandidateRatingBlock';
import { mapSelectedIds } from '..';
import { Application } from '../../../../../entities/Application';
import { getQueryParam } from '../../../../../utils/url';

interface PropTypes {
  application: Application;
  currentUser: User;
  filters: FeedbackFilters;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  refreshCandidateRatingList: () => void;
}

function showApplicationRatingId(): number {
  return parseInt(getQueryParam('show_application_rating_id'));
}

function bringOpenedRatingToTop(applicationRatings) {
  const openedRatingId = showApplicationRatingId();

  if (!openedRatingId) return applicationRatings;

  const indexOfOpenRating = applicationRatings?.findIndex(
    (rating) => rating.id === openedRatingId,
  );

  if (indexOfOpenRating !== -1) {
    const elementToMove = applicationRatings.splice(indexOfOpenRating, 1)[0];
    applicationRatings.unshift(elementToMove);
  }

  return applicationRatings;
}

export function CandidateRatingList(props: PropTypes) {
  const [applicationRatings, setApplicationRatings] = useState(null);

  useEffect(() => {
    props.setIsLoading(true);
    ApplicationRatingService.list({
      applicationId: props.application.id,
      expand: ['user', 'job_stage'],
      jobStageIds: mapSelectedIds(props.filters?.jobStages || []),
      hiringMemberIds: mapSelectedIds(props.filters?.hiringMembers || []),
    })
      .then((response) => setApplicationRatings(response.applicationRatings))
      .finally(() => props.setIsLoading(false));
  }, [props.filters]);

  const sortedApplicationRatings = bringOpenedRatingToTop(
    applicationRatings || [],
  );

  if (props.isLoading) return null;

  return (
    <>
      {!applicationRatings || applicationRatings?.length === 0 ? (
        <EmptyFeedback filters={props.filters} />
      ) : (
        <>
          {sortedApplicationRatings.map((applicationRating, index) => (
            <CandidateRatingBlock
              key={index}
              application={props.application}
              applicationRating={applicationRating}
              opened={applicationRating.id === showApplicationRatingId()}
              refreshList={props.refreshCandidateRatingList}
              currentUser={props.currentUser}
            />
          ))}
          <div className='fs-5 text-dark-200'>
            Showing 1 - {applicationRatings.length} of{' '}
            {applicationRatings.length} Candidate Rating
          </div>
        </>
      )}
    </>
  );
}
