import { extractNumbers } from '../../../../../utils/extractors/extractNumbers';
import { isEmptyString } from '../../../../../utils/isEmptyString';
import { JobPostingDetailsForm } from '../../JobBoardsTabUI/JobPostingDetails';

const NUMBER_REGEX = /^\d+$/;
const MAX_WEEKLY_HOURS = 168;

export function isSalaryRangeFilled(form: JobPostingDetailsForm): boolean {
  return !!(form.salaryRangeTo || form.salaryRangeFrom);
}

export function validateSalaryPeriod(form: JobPostingDetailsForm): boolean {
  if (isSalaryRangeFilled(form)) {
    return !!form.salaryPeriod;
  }

  return true;
}

export function validateSalaryCurrency(form: JobPostingDetailsForm): boolean {
  if (isSalaryRangeFilled(form)) {
    return !!form.salaryCurrency;
  }

  return true;
}

export function validateNumberInput(value?: string) {
  if (isEmptyString(value)) {
    return;
  }

  const isValid = NUMBER_REGEX.exec(value);
  if (!isValid) {
    return 'Value must be a number';
  }
}

export function validateSalaryFromInput(form: JobPostingDetailsForm) {
  const salaryFromNumbers = extractNumbers(form.salaryRangeFrom);
  const salaryToNumbers = extractNumbers(form.salaryRangeTo);
  const err = validateNumberInput(salaryFromNumbers);

  if (err != null) {
    return err;
  }

  if (salaryToNumbers != null && NUMBER_REGEX.exec(salaryToNumbers)) {
    const parsedValue = parseInt(salaryFromNumbers);
    const parsedMaxValue = parseInt(salaryToNumbers);

    if (parsedValue > parsedMaxValue) {
      return 'Min should be lower than max';
    }
  }
}

export function validateSalaryToInput(form: JobPostingDetailsForm) {
  const salaryFromNumbers = extractNumbers(form.salaryRangeFrom);
  const salaryToNumbers = extractNumbers(form.salaryRangeTo);
  const err = validateNumberInput(salaryToNumbers);

  if (err != null) {
    return err;
  }

  if (salaryFromNumbers != null && NUMBER_REGEX.exec(salaryFromNumbers)) {
    const parsedValue = parseInt(salaryToNumbers);
    const parsedMinValue = parseInt(salaryFromNumbers);

    if (parsedValue < parsedMinValue) {
      return ' ';
    }
  }
}

export function validateWeeklyHoursFromInput(form: JobPostingDetailsForm) {
  const err = validateNumberInput(form.weeklyHoursFrom);

  if (err != null) {
    return err;
  }

  if (form.weeklyHoursTo != null && NUMBER_REGEX.exec(form.weeklyHoursTo)) {
    const parsedValue = parseInt(form.weeklyHoursFrom);
    const parsedMaxValue = parseInt(form.weeklyHoursTo);

    if (parsedValue > MAX_WEEKLY_HOURS) {
      return `Cannot exceed ${MAX_WEEKLY_HOURS} hours`;
    } else if (parsedValue > parsedMaxValue) {
      return 'Min should be lower than max';
    }
  }
}

export function validateWeeklyHoursToInput(form: JobPostingDetailsForm) {
  const err = validateNumberInput(form.weeklyHoursTo);

  if (err != null) {
    return err;
  }

  const parsedValue = parseInt(form.weeklyHoursTo);
  if (parsedValue > MAX_WEEKLY_HOURS) {
    return `Cannot exceed ${MAX_WEEKLY_HOURS} hours`;
  } else if (
    form.weeklyHoursFrom != null &&
    NUMBER_REGEX.exec(form.weeklyHoursFrom)
  ) {
    const parsedMinValue = parseInt(form.weeklyHoursFrom);

    if (parsedValue < parsedMinValue) {
      return ' ';
    }
  }
}
