import React, { useState } from 'react';
import { EmptyState } from '../../../components/EmptyState';
import { BubbleTable } from '../../../components/BubbleTable';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { Application } from '../../../entities/Application';
import { InterviewRow } from './InterviewRow';
import { CancelInterviewConfirmationModal } from './CancelInterviewConfirmationModal';
import { User } from '../../../entities/User';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { AlertObject, AlertType } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';

interface PropTypes {
  currentUser: User;
  application: Application;
  applicationId: number;
  applicantTrackingEnabled: boolean;
  jobHiringMemberRole: JobHiringMemberRole;
  onCancel: () => void;
  onInterviewSelected: (interview: InterviewListItem) => void;
  setAlert: (alert: AlertObject) => void;
}

function InterviewsTable(
  props: PropTypes & { onCancelInterview: (interviewId: string) => void },
) {
  if (props.application.interviews.length === 0) {
    return (
      <EmptyState title='No interviews are available for this candidate yet' />
    );
  }

  return (
    <BubbleTable>
      <thead>
        <tr>
          <th className='left-col'>Interview</th>
          <th>Status</th>
          <th>Participants</th>
          <th>Duration</th>
          <th>Scheduled For</th>
          <th>Interview Rating</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.application.interviews.map(
          (interview: InterviewListItem, idx: number) => (
            <InterviewRow
              key={idx}
              applicationId={props.applicationId}
              currentUser={props.currentUser}
              interview={interview}
              jobHiringMemberRole={props.jobHiringMemberRole}
              onInterviewSelected={props.onInterviewSelected}
              onCancelInterview={props.onCancelInterview}
              applicantTrackingEnabled={props.applicantTrackingEnabled}
            />
          ),
        )}
      </tbody>
    </BubbleTable>
  );
}

export function InterviewsTab(props: PropTypes) {
  const [interviewToBeCancelled, setInterviewToBeCancelled] = useState(null);
  const [isCancelInterviewModalOpen, setIsCancelInterviewModalOpen] =
    useState(false);

  const handleCancelInterview = (interviewId: string) => {
    setInterviewToBeCancelled(interviewId);
    setIsCancelInterviewModalOpen(true);
  };

  const handleCancellingSuccess = () => {
    props.setAlert({
      message: `The interview with ${props.application.candidate.name} was successfully cancelled.`,
      type: AlertType.Success,
    });

    props.onCancel();
  };

  const handleCancellingError = () => {
    props.setAlert({
      message: `The interview with ${props.application.candidate.name} could not be cancelled.`,
      type: AlertType.Danger,
    });
  };

  return (
    <>
      <CandidatePageSubHeader
        classNames={props.application.interviews.length === 0 && 'mb-3'}
        text='Interviews'
      />
      <CancelInterviewConfirmationModal
        candidateName={props.application.candidate.name}
        interviewId={interviewToBeCancelled}
        isModalOpen={isCancelInterviewModalOpen}
        setIsModalOpen={setIsCancelInterviewModalOpen}
        onSuccess={handleCancellingSuccess}
        onError={handleCancellingError}
      />
      <InterviewsTable {...props} onCancelInterview={handleCancelInterview} />
    </>
  );
}
