import React from 'react';
import { SalaryField } from '../SalaryField';
import { JobPostingDetailsForm } from '..';
import {
  validateSalaryFromInput,
  validateSalaryToInput,
} from '../../../utils/validators/sharedFormValidations';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: any;
  mandatory?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

export function SalaryRange(props: PropTypes) {
  const salaryFromError =
    validateSalaryFromInput(props.form) ??
    props.errors['salaryIndication']?.['range']?.['from'];
  const salaryToError =
    validateSalaryToInput(props.form) ??
    props.errors['salaryIndication']?.['range']?.['to'];

  return (
    <div className='d-flex gap-2'>
      <SalaryField
        className='w-50'
        label='Salary Range'
        value={props.form.salaryRangeFrom}
        name='salaryRangeFrom'
        onInputChange={props.onInputChange}
        placeholder='From'
        error={salaryFromError}
      />
      <SalaryField
        className='w-50'
        label='To'
        value={props.form.salaryRangeTo}
        name='salaryRangeTo'
        onInputChange={props.onInputChange}
        mandatory={props.mandatory}
        placeholder='To'
        error={salaryToError}
      />
    </div>
  );
}
