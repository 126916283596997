import { ApiService } from '../../ApiService';
import { UpsertJobDto } from './UpsertJobDto';
import { Job } from '../../../entities/applicant_tracking/Job';
import snakecaseKeys from 'snakecase-keys';
import { EditJobDto } from './EditJobDto';
import { InterviewStage } from '../../../entities/applicant_tracking/InterviewStage';

export class JobService {
  public static get(id: string): Promise<EditJobDto> {
    return ApiService.get<EditJobDto>(`/api/applicant_tracking/jobs/${id}`);
  }

  public static upsert(props: UpsertJobDto): Promise<Job> {
    if (props.id == null) {
      return JobService.create(props);
    } else {
      return JobService.update(props);
    }
  }

  public static create(props: UpsertJobDto): Promise<Job> {
    return ApiService.post<Job>(
      '/api/applicant_tracking/jobs',
      snakecaseKeys(props),
    );
  }

  public static listInterviewStages(jobId: number): Promise<InterviewStage[]> {
    return ApiService.get<InterviewStage[]>(
      `/api/applicant_tracking/jobs/${jobId}/interview_stages`,
    );
  }

  public static update(props: UpsertJobDto): Promise<Job> {
    return ApiService.put<Job>(
      `/api/applicant_tracking/jobs/${props.id}`,
      snakecaseKeys(props),
    );
  }

  public static submit(id: string): Promise<Job> {
    return ApiService.post<Job>(`/api/applicant_tracking/jobs/${id}/submit`);
  }

  public static publish(id: string, updateJobPosts?: boolean): Promise<Job> {
    return ApiService.post<Job>(
      `/api/applicant_tracking/jobs/${id}/publish`,
      snakecaseKeys({ updateJobPosts }),
    );
  }

  public static unpublish(id: string, updateJobPosts?: boolean): Promise<Job> {
    return ApiService.post<Job>(
      `/api/applicant_tracking/jobs/${id}/unpublish`,
      snakecaseKeys({ updateJobPosts }),
    );
  }

  public static delete(id: number): Promise<void> {
    return ApiService.delete<void>(`/api/applicant_tracking/jobs/${id}`);
  }

  public static archive(id: number): Promise<Job> {
    return ApiService.put<Job>(`/api/applicant_tracking/jobs/${id}/archive`);
  }

  public static unarchive(id: number): Promise<Job> {
    return ApiService.put<Job>(`/api/applicant_tracking/jobs/${id}/unarchive`);
  }
}
