import React from 'react';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { useStateMachine } from 'little-state-machine';
import { OverviewApplyButton } from './OverviewApplyButton';
import { JobPostPageTab } from '..';
import classNames from 'classnames';
import { BackToCareersPageButton } from '../BackToCareersPageButton';

const MAX_WIDTH = '800px';

interface PropTypes {
  previewMode: boolean;
  jobPostHtmlString?: string;
  setActiveTab: (tab: JobPostPageTab) => void;
}

export function OverviewTab(props: PropTypes) {
  const { state } = useStateMachine({});
  if (
    [props.jobPostHtmlString, state.setupJob?.editorStateHtml].every(
      (el) => el == null,
    )
  ) {
    return null;
  }

  const sanitizedHtml = DOMPurify.sanitize(
    props.jobPostHtmlString || state.setupJob?.editorStateHtml,
  );

  return (
    <div className={'text-start mx-auto px-3'} style={{ maxWidth: MAX_WIDTH }}>
      {parse(sanitizedHtml)}
      <div
        className={classNames(
          'd-flex',
          'justify-content-center',
          'w-100',
          'mt-4',
          'mb-5',
        )}
      >
        <BackToCareersPageButton
          color={'secondary'}
          size={'lg'}
          className={'me-3'}
          previewMode={props.previewMode}
          text='Back'
          onClick={() => (window.location.href = '..')}
        />
        <OverviewApplyButton
          previewMode={props.previewMode}
          setActiveTab={props.setActiveTab}
        />
      </div>
    </div>
  );
}
