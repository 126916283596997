import { Button } from 'reactstrap';
import { StageTuple } from '..';
import React from 'react';
import { PlanTemplate } from '../../../../../../../../entities/v1/applicant_tracking/PlanTemplate';
import { StagePlanTemplate } from '../../../../../../../../entities/v1/applicant_tracking/StagePlanTemplate';

function convertJobStagesToStagePlanTemplateIds(
  mapping: Map<number, StageTuple>,
  oldWorflow: PlanTemplate,
): Map<number, number> {
  const newMap = new Map<number, number>();

  mapping.forEach((v: StageTuple, k: number) => {
    newMap.set(
      oldWorflow.stagePlanTemplates.find(
        (v: StagePlanTemplate) => v.jobStageId === k,
      ).id,
      v.id,
    );
  });

  return newMap;
}

export function Footer(props: {
  disabled: boolean;
  mapping: Map<number, StageTuple>;
  oldWorflow: PlanTemplate;
  onClose: (success: boolean, mappedWorkflow?: Map<number, number>) => void;
}) {
  return (
    <>
      <span className='text-dark-200 fs-5'>
        No changes will be applied until the job is saved.
      </span>
      <div className='d-flex gap-3'>
        <Button color='danger' onClick={() => props.onClose(false)}>
          Cancel
        </Button>
        <Button
          color='primary'
          disabled={props.disabled}
          onClick={() =>
            props.onClose(
              true,
              convertJobStagesToStagePlanTemplateIds(
                props.mapping,
                props.oldWorflow,
              ),
            )
          }
        >
          Change Workflow
        </Button>
      </div>
    </>
  );
}
