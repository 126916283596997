import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { getQueryParam, getRedirectUriQueryParam } from '../../../../utils/url';
import {
  ApprovalAssignedTo,
  ApprovalFlowStep,
  RequisitionType,
  ListApprovalFlowsMetadata,
} from '../../../../entities/applicant_tracking/ApprovalFlow';
import { createNewApprovalFlowStep } from '../components/ApprovalSteps';
import { ApprovalFlowBody } from '../components/ApprovalFlowBody';
import { ApprovalReviewModal } from '../components/ApprovalReviewModal';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import {
  isApprovalFlowSavable,
  loadExistingFlowMetadata,
  mapDataToAPIRequest,
} from '../utils';
import { ApprovalFlowService } from '../../../../services/v1/applicant_tracking/ApprovalFlowService';
import { Alert, AlertObject, AlertType } from '../../../../components/Alert';

const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/approval_flows';

interface PropTypes {
  offerFlowFfEnabled: boolean;
}

export default function NewApprovalFlowPage(props: PropTypes) {
  const [name, setName] = useState<string>('');
  const [alert, setAlert] = useState<AlertObject>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [existingFlows, setExistingFlows] =
    useState<ListApprovalFlowsMetadata>(null);
  const [assignedTo, setAssignedTo] =
    useState<ApprovalAssignedTo>('organization');
  const [requisitionType, setRequisitionType] = useState<RequisitionType>(
    props.offerFlowFfEnabled
      ? (getQueryParam('requisition_type') as RequisitionType) ?? 'offer'
      : 'job',
  );
  const [assignedLocationOrDepartmentIds, setAssignedLocationOrDepartmentIds] =
    useState<number[]>([]);
  const [approvalSteps, setApprovalSteps] = useState<ApprovalFlowStep[]>([
    createNewApprovalFlowStep(1),
  ]);
  const [reviewModalOpen, setReviewModalOpen] = useState<boolean>(false);

  const isSavable = () =>
    isApprovalFlowSavable(
      name,
      assignedTo,
      !!existingFlows?.organizationFlowId,
      assignedLocationOrDepartmentIds,
      approvalSteps,
    );

  useEffect(() => {
    setAssignedTo('organization');
    loadExistingFlowMetadata(requisitionType).then(setExistingFlows);
  }, [requisitionType]);

  useEffect(() => {
    existingFlows?.organizationFlowId && setAssignedTo('location');
  }, [existingFlows]);

  useEffect(() => setAssignedLocationOrDepartmentIds([]), [assignedTo]);

  async function handleSave() {
    try {
      setIsLoading(true);
      await ApprovalFlowService.create(
        mapDataToAPIRequest(
          name,
          assignedTo,
          requisitionType,
          assignedLocationOrDepartmentIds,
          approvalSteps,
        ),
      );

      window.location.href = REDIRECT_URI;
    } catch (e: any) {
      setIsLoading(false);
      setReviewModalOpen(false);

      setAlert({ type: AlertType.Danger, message: e.body.message });
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      {alert && (
        <Alert
          clearable
          autoClearTimeout={3000}
          type={alert.type}
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
      )}
      <div className='container'>
        <PageHeader title='Add Approval Flow' backUrl={REDIRECT_URI} />
        <Card>
          <CardHeader
            title='Approval Flow'
            saveText='Review and Save'
            titleClassNames='mb-0'
            classNames='align-items-center'
            isSavable={isSavable()}
            onSaveClick={() => setReviewModalOpen(true)}
            cancelUrl={REDIRECT_URI}
          />
          <ApprovalFlowBody
            name={name}
            assignedTo={assignedTo}
            requisitionType={requisitionType}
            approvalSteps={approvalSteps}
            assignedLocationOrDepartmentIds={assignedLocationOrDepartmentIds}
            disabledLocationIds={existingFlows?.assignedLocationIds}
            disabledDepartmentIds={existingFlows?.assignedDepartmentIds}
            organizationFlowExists={!!existingFlows?.organizationFlowId}
            offerFlowFfEnabled={props.offerFlowFfEnabled}
            onName={setName}
            setApprovalSteps={setApprovalSteps}
            setRequisitionType={setRequisitionType}
            onAssignedToChange={setAssignedTo}
            setAssignedLocationOrDepartmentIds={
              setAssignedLocationOrDepartmentIds
            }
          />
        </Card>
      </div>
      {reviewModalOpen && (
        <ApprovalReviewModal
          steps={approvalSteps}
          onCancel={() => setReviewModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
}
