import { Button } from 'reactstrap';
import React from 'react';
import { GenerateState } from '../../../../../../../../components/FillWithAIButton';

interface PropTypes {
  aiGenerateStatus: GenerateState;
  setIsGeneratorOpened: (value) => void;
  setAiGenerateState: (value) => void;
  isErrorMessage: boolean;
  setIsErrorMessage: (value) => void;
}

export function StopGeneratingButton(props: PropTypes) {
  function handleOnCloseButtonClick() {
    props.setIsGeneratorOpened(false);
    if (props.isErrorMessage) {
      props.setAiGenerateState('Generate');
    }
    props.setIsErrorMessage(false);
  }

  function handleOnCancelButtonClick() {
    props.setAiGenerateState('Canceling');
  }

  return props.aiGenerateStatus == 'Generating' ? (
    <Button
      size='md'
      color='borderless'
      onClick={handleOnCancelButtonClick}
      disabled={props.isErrorMessage}
    >
      Cancel
    </Button>
  ) : (
    <Button size='md' color='borderless' onClick={handleOnCloseButtonClick}>
      Close
    </Button>
  );
}
