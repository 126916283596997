import { JobService } from '../../../../../../../services/v1/applicant_tracking/JobService';
import { GenerateState } from '../../../../../../../components/FillWithAIButton';

export function generateAiResponse(
  setValue: (value: string) => void,
  setIsErrorMessage: (isErrorMessage: boolean) => void,
  setAiGenerateState: (state: GenerateState) => void,
  jobId: number,
  jobName: string,
  locationId: string,
  locationType: string,
  employmentType: string,
  experienceLevel: string,
  templateJobPostId?: string,
  description?: string,
) {
  let response = '';

  const onMessage = (message: MessageEvent) => {
    const data = JSON.parse(message.data);
    if (data.error) {
      setIsErrorMessage(true);
    }

    response += data.response;

    setValue(response);
  };

  JobService.aiGenerateJobDescription({
    id: jobId,
    onMessage: onMessage,
    onEnd: () => {
      setAiGenerateState('Regenerate');
    },
    jobName: jobName,
    locationId: locationId?.toString(),
    locationType: locationType,
    employmentType: employmentType,
    experienceLevel: experienceLevel,
    templateJobPostId: templateJobPostId?.toString(),
    description: description,
  });
}
