import React from 'react';
import { GenericEntry } from '../..';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { Simple } from '../../../../../entities/ApplicationFeed';
import { AlertObject } from '../../../../../components/Alert';

interface PropTypes {
  application: Application;
  entry: Simple;
  currentUser: User;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function RcSelfAssessmentCompletedEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-file-earmark-check'
      iconClassName='bg-light-warning'
      title={<>The Self-assessment survey has been successfully completed.</>}
      date={props.entry.createdAt}
      {...props}
    />
  );
}
