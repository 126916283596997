import React, { useEffect, useState } from 'react';
import { Application } from '../../../entities/Application';
import { Col, Row } from 'reactstrap';
import { CandidateProcessActivityPanel } from './CandidateProcessActivityPanel';
import {
  ApplicationFeedList,
  ApplicationFeedService,
} from '../../../services/applicant_tracking/ApplicationFeedService';
import { User } from '../../../entities/User';
import { PendingActionsPanel } from '../ProfileTab/PendingActionsPanel';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { HiringTeam } from '../../JobPage/OverviewTab/HiringTeam';
import {
  ActivityTabFilter,
  defaultFilters,
} from './CandidateProcessActivityPanel/ActivityTabFilters';
import _ from 'lodash';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import { AlertObject } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';
import { findBriefJobDetails } from '../findBriefJobDetails';

interface PropTypes {
  application: Application;
  applicationStylesPath: string;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobHiringMemberRole?: JobHiringMemberRole;
  reloadApplication: () => void;
  setAlert: (data: AlertObject) => void;
}

const ITEMS_PER_PAGE = 10;

export function ActivityTab(props: PropTypes) {
  const [filters, setFilters] = useState<ActivityTabFilter>(defaultFilters);

  const [applicationFeedList, setApplicationFeedList] =
    useState<ApplicationFeedList>(null);
  const briefJobDetails = findBriefJobDetails(props.application);

  const fetchApplicationFeedList = () => {
    ApplicationFeedService.list(
      props.application.id,
      ITEMS_PER_PAGE,
      filters,
    ).then((feed) => setApplicationFeedList(feed));
  };

  useEffect(fetchApplicationFeedList, [props.application, filters]);

  return (
    <div className='d-flex'>
      <div className='d-flex flex-column gap-3 w-100'>
        <CandidatePageSubHeader text='Activity' />
        <Row className='flex-nowrap'>
          <Col xs='9'>
            <CandidateProcessActivityPanel
              application={props.application}
              applicationFeedList={applicationFeedList}
              applicationStylesPath={props.applicationStylesPath}
              currentUser={props.currentUser}
              emailAccountIntegration={props.emailAccountIntegration}
              itemsPerPage={ITEMS_PER_PAGE}
              onFilter={setFilters}
              onPageClick={(page) => setFilters({ ...filters, page: page })}
              reloadApplication={fetchApplicationFeedList}
              reloadFeed={fetchApplicationFeedList}
              setAlert={props.setAlert}
            />
          </Col>
          <Col>
            <PendingActionsPanel {...props} />
            <HiringTeam
              hiringMembers={briefJobDetails?.hiringMembers ?? []}
              className='p-3 mb-3'
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
