import React from 'react';
import { Col, Row } from 'reactstrap';
import { EmptyTab } from '../../../../../components/EmptyTab';
import { ProgressBar } from '../../../../../components/ProgressBar';
import { InterviewQuestion } from '../../../../../entities/InterviewQuestion';
import { CommonListPanel } from '../../CommonListPanel';
import { InterviewQuestionPart } from './InterviewQuestionPart';
import { HorizontalDivider } from '../../../../../components/HorizontallDivider';

interface PropTypes {
  interviewQuestions: InterviewQuestion[];
  onInterviewQuestionsUpdated: (question: InterviewQuestion) => void;
}

function QuestionsEmptyState() {
  return (
    <div className='px-5'>
      <EmptyTab
        title={'No questions available yet'}
        text={
          'Improve your experience by adding questions to help guide you through the interview.'
        }
      />
    </div>
  );
}

function QuestionsHeader(props: { interviewQuestions: InterviewQuestion[] }) {
  const questionsNumber: number = props.interviewQuestions.length;
  const questionsCheckedNumber: number = props.interviewQuestions.filter(
    (iq) => iq.selected,
  ).length;

  return (
    <Row className='justify-content-between'>
      <Col>
        <span className='fw-bold ms-3 fs-4 mb-0'>Interview Questions</span>
      </Col>
      <Col className='me-3'>
        {questionsNumber !== 0 && (
          <ProgressBar
            justifyContentClass='justify-content-end'
            label={`(${questionsCheckedNumber}/${questionsNumber})`}
            progress={(questionsCheckedNumber / questionsNumber) * 100.0}
            labelClasses='fs-6 text-dark-200'
            progressBarColorClass='bg-success-500'
          />
        )}
      </Col>
    </Row>
  );
}

function SectionHeader(props: { sectionName: string }) {
  return (
    <>
      {props.sectionName !== 'null' && (
        <div className='fw-semibold fs-5 mb-3'>{props.sectionName}</div>
      )}
    </>
  );
}

export function InterviewQuestionsPanel(props: PropTypes) {
  const groupedQuestions = props.interviewQuestions.reduce(
    (result, question) => {
      if (!result[question.externalSectionName]) {
        result[question.externalSectionName] = [];
      }
      result[question.externalSectionName].push(question);
      return result;
    },
    {},
  );

  return (
    <div className='mt-4 bg-white pt-3 pb-4h rounded-4 mx-3'>
      <QuestionsHeader interviewQuestions={props.interviewQuestions} />
      {Object.keys(groupedQuestions).map((sectionName) => (
        <CommonListPanel
          key={sectionName}
          header={<SectionHeader sectionName={sectionName} />}
        >
          {props.interviewQuestions.length > 0 ? (
            <div id='interview_questions'>
              <>
                {groupedQuestions[sectionName].map(
                  (interviewQuestion, index) => (
                    <div key={index}>
                      {index != 0 && <HorizontalDivider classNames='my-2h' />}
                      <InterviewQuestionPart
                        key={interviewQuestion.id}
                        interviewQuestion={interviewQuestion}
                        onInterviewQuestionsUpdated={
                          props.onInterviewQuestionsUpdated
                        }
                      />
                    </div>
                  ),
                )}
              </>
            </div>
          ) : (
            <QuestionsEmptyState />
          )}
        </CommonListPanel>
      ))}
    </div>
  );
}
