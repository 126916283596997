import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  className?: string;
  style?: CSSProperties;
  additionalInnerContainerClassNames?: string;
  innerMostClassName?: string;
  children: React.ReactNode;
  hasHooverEffect?: boolean;
}

/**
 * @deprecated use `components/Card` instead.
 */
export function DeprecatedPanel(props: PropTypes) {
  return (
    <div
      className={classNames(
        'card',
        'rounded-4',
        'shadow-sm',
        props.className,
        props.hasHooverEffect && styles['panel-border'],
      )}
      style={{ ...props.style }}
    >
      <div
        className={classNames(
          'app-card-body',
          'w-100',
          props.additionalInnerContainerClassNames,
        )}
      >
        <div className='row h-100'>
          <div className='col-12 h-100'>
            <div className={props.innerMostClassName || 'h-100'}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
