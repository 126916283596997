import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Icon } from '../../../Icon';

interface PropTypes {
  disabled?: boolean;
  classNames?: string;
  onAddStage: () => void;
}

export function AddStageContainer(props: PropTypes) {
  const textColor = props.disabled ? 'text-blue-200' : 'text-info';

  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center align-items-center h-100',
        styles.container,
        props.classNames,
        props.disabled && styles['disabled-container'],
      )}
      onClick={!props.disabled ? props.onAddStage : undefined}
    >
      <Icon name='bi-plus-circle' className={classNames('fs-1', textColor)} />
      <span className={classNames('fw-semibold fs-3 mt-3', textColor)}>
        Add Stage
      </span>
    </div>
  );
}
