import classNames from 'classnames';
import React from 'react';
import { SurveyTrigger } from '../../../../../entities/candidate_experience/SurveyTrigger';
import { longFormatDate } from '../../../../../utils/timeFormat';

interface PropTypes {
  survey_triggers: SurveyTrigger[];
}

function TableHeader() {
  return (
    <thead className='fs-4'>
      <tr>
        <th className='cell col-3 fw-semibold left-col'>Trigger Name</th>
        <th className='cell col-2 fw-semibold'>Track</th>
        <th className='cell col-2 fw-semibold'>Survey Type</th>
        <th className='cell col-2 fw-semibold'>Status</th>
        <th className='cell col-2 fw-semibold'>Last Updated</th>
        <th className='cell col-1 right-col'></th>
      </tr>
    </thead>
  );
}

function TableDropDown(props: { surveyTriggerId: number }) {
  return (
    <td className='cell text-center right-col'>
      <div className='align-self-center'>
        <div className='dropdown'>
          <a
            className='dropdown'
            data-bs-toggle='dropdown'
            href='#'
            role='button'
            aria-expanded='false'
          >
            <i
              className='bi bi-three-dots'
              data-toggle='tooltip'
              data-bs-placement='top'
              data-bs-delay='1000'
            ></i>
          </a>
          <ul className='dropdown-menu' aria-labelledby='user-dropdown-toggle'>
            <div
              className='dropdown-item'
              style={{ cursor: 'pointer' }}
              onClick={() =>
                (window.location.href = `/organization_settings/survey_triggers/${props.surveyTriggerId}/edit`)
              }
            >
              {' '}
              <i className='bi bi-pencil me-1'></i> Edit
            </div>
          </ul>
        </div>
      </div>
    </td>
  );
}

function ColumnName(props: { id: number; name: string }) {
  return (
    <td className='cell left-col'>
      <div
        className='text-truncate text-info'
        style={{ cursor: 'pointer' }}
        onClick={() =>
          (window.location.href = `/organization_settings/survey_triggers/${props.id}/edit`)
        }
      >
        {props.name}
      </div>
    </td>
  );
}

function TableRows(props: PropTypes) {
  return (
    <tbody>
      {props.survey_triggers.map((st, index) => (
        <tr key={index}>
          <ColumnName id={st.id} name={st.name} />
          <td className='cell'>
            <div className='text-truncate'>{st.survey_template_name}</div>
          </td>
          <td className='cell'>
            <div className='text-truncate'>Pulse</div>
          </td>
          <td className='cell'>
            <span
              className={classNames(
                `badge rounded-pill me-1 bg-${
                  st.active ? 'success' : 'danger'
                }`,
              )}
            >
              {st.active ? 'Enabled' : 'Disabled'}
            </span>
          </td>
          <td className='cell'>
            <div className='text-truncate'>
              {longFormatDate(st.updated_at.toString())}
            </div>
          </td>
          <TableDropDown surveyTriggerId={st.id} />
        </tr>
      ))}
    </tbody>
  );
}

function TableFooter(props: PropTypes) {
  return (
    <div className='table-responsive-footer fs-5 row'>
      <ul className='col page-entries'>
        {props.survey_triggers.length > 0 ? (
          <>
            Showing{' '}
            <span className='fw-bold'>{props.survey_triggers.length}</span>{' '}
            {props.survey_triggers.length === 1
              ? 'Survey Trigger'
              : 'Survey Triggers'}
          </>
        ) : (
          <>No Survey Triggers found</>
        )}
      </ul>
    </div>
  );
}

export function SurveyTriggerTable(props: PropTypes) {
  return (
    <div className='card shadow-sm d-flex flex-column align-items-start'>
      <div className='w-100'>
        <div className='tab-content' id='survey-triggers-table-tab-content'>
          <div
            className='tab-pane fade show active'
            id='survey-triggers-all'
            role='tabpanel'
            aria-labelledby='survey-triggers-all-tab'
          >
            <div className='app-card app-card-table shadow-sm'>
              <div className='app-card-body'>
                <div className='table-responsive'>
                  <table className='table table-fixed mb-0 text-left'>
                    <TableHeader />
                    <TableRows survey_triggers={props.survey_triggers} />
                  </table>
                  <TableFooter survey_triggers={props.survey_triggers} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
