import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DeleteStageConfirmationModal } from './DeleteStageConfirmationModal';
import { JobStageTemplateInput } from '../../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { getLabelForCount } from '../../../../utils/grammar';
import { Chip, ChipColor } from '../../../Chip';
import { Icon } from '../../../Icon';
import RowActionsDelete from './RowActionDelete';
import { Tooltip } from 'reactstrap';
import { PlanTemplate } from '../../../../entities/applicant_tracking/PlanTemplate';
import { StageMoveDTO } from '../../../../containers/PlanTemplates/SetupPlanTemplatePage/DTOs/StageMoveDTO';

interface PropTypes {
  jobStageTemplate: JobStageTemplateInput;
  isFocused: boolean;
  jobStages: JobStageTemplateInput[];
  planTemplate: PlanTemplate;
  focusedJobStage?: JobStageTemplateInput;
  viewMode?: boolean;
  draggedOver?: boolean;
  disableViewingNonInterviewStages?: boolean;
  headerClassName?: string;
  stageMoves?: StageMoveDTO[];
  onEdit: () => void;
  onDelete: (moveDestinationStageName?: string) => void;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
}

function Header(props: PropTypes) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <div className={classNames('p-2', backgroundColor(props))}>
        <div className={classNames('d-flex justify-content-center')}>
          <Icon name='bi-grip-horizontal' />
        </div>
        <div className='d-flex justify-content-between align-items-center mb-1'>
          <div className='ms-1'>{props.jobStageTemplate.name}</div>
          <RowActionsDelete
            jobStageTemplate={props.jobStageTemplate}
            isFocused={props.isFocused}
            stageMoves={props.stageMoves}
            onDelete={() => setShowDeleteModal(true)}
          />
        </div>
      </div>
      <DeleteStageConfirmationModal
        stage={props.jobStageTemplate}
        isOpen={showDeleteModal}
        jobStages={props.jobStages}
        planTemplate={props.planTemplate}
        onClose={(result: boolean, moveDestinationStageName?: string) => {
          setShowDeleteModal(false);
          result && props.onDelete(moveDestinationStageName);
        }}
      />
    </>
  );
}

function ColoredChip(props: { chipColor: ChipColor; text: string }) {
  const ref = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.text]);

  return (
    <div ref={ref}>
      <Chip
        className={classNames(
          'mt-2 fw-semibold text-nowrap text-truncate',
          styles['chip'],
        )}
        color={props.chipColor}
      >
        {props.text}
      </Chip>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='bottom'
        >
          <div className='text-white'>{props.text}</div>
        </Tooltip>
      )}
    </div>
  );
}

function InterviewCountChip(props: PropTypes) {
  const interviewsCount = props.jobStageTemplate.interviewKitTemplate ? 1 : 0;

  return (
    <ColoredChip
      chipColor={
        props.jobStageTemplate.interviewKitTemplate
          ? ChipColor.SuccessLight
          : ChipColor.OrangeLight
      }
      text={getLabelForCount('interview', interviewsCount)}
    />
  );
}

function InterviewKitChip(props: PropTypes) {
  return (
    <>
      {props.jobStageTemplate.interviewKitTemplate && (
        <ColoredChip
          chipColor={ChipColor.BlueLight}
          text={props.jobStageTemplate.interviewKitTemplate.name}
        />
      )}
    </>
  );
}

function ScorecardChip(props: PropTypes) {
  const hasInterview = props.jobStageTemplate.interviewKitTemplate != null;

  return (
    <>
      {hasInterview && props.jobStageTemplate.scorecardTemplate && (
        <ColoredChip
          chipColor={ChipColor.YellowLight}
          text={props.jobStageTemplate.scorecardTemplate.name}
        />
      )}
    </>
  );
}

function Body(props: PropTypes) {
  return (
    <div className='m-3 mt-1'>
      <InterviewCountChip {...props} />
      <InterviewKitChip {...props} />
      <ScorecardChip {...props} />
    </div>
  );
}

function DragableHeader(
  props: PropTypes & {
    restrictView: boolean;
    attributes: DraggableAttributes;
    listeners: SyntheticListenerMap;
  },
) {
  const viewTextColor = props.restrictView ? 'text-dark' : 'text-info';

  if (props.viewMode)
    return (
      <div className={classNames('p-2', backgroundColor(props))}>
        <div
          className={classNames(
            'ms-1 fw-bold',
            props.headerClassName ?? 'mb-1 mt-3',
            props.isFocused ? 'text-white' : viewTextColor,
          )}
        >
          {props.jobStageTemplate.name}
        </div>
      </div>
    );

  return (
    <div
      {...props.attributes}
      {...props.listeners}
      className={classNames(
        props.draggedOver ? styles['grabbing-handle'] : styles['grab-handle'],
      )}
    >
      <Header {...props} />
    </div>
  );
}

function interviewsCountColor(props: PropTypes) {
  return props.jobStageTemplate.interviewKitTemplate
    ? 'bg-light-success'
    : 'bg-light-warning';
}

function backgroundColor(props: PropTypes) {
  if (props.isFocused) {
    return 'bg-blue text-white';
  }

  return interviewsCountColor(props);
}

export function JobStageContainer(props: PropTypes) {
  const restrictView =
    props.disableViewingNonInterviewStages &&
    !props.jobStageTemplate.interviewKitTemplate;
  const borderColor = restrictView ? 'border-blue-gray-700' : 'border-blue-500';
  const {
    isDragging,
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
  } = useSortable({ id: props.jobStageTemplate.uniqueKey, data: props });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    opacity: isDragging && !props.draggedOver ? 0.25 : 1,
  };

  return (
    <div
      className={classNames(
        `border border-1 ${borderColor} rounded-3 overflow-hidden h-100 bg-white`,
        styles['container'],
        !restrictView && styles['pointer-handle'],
      )}
      ref={setNodeRef}
      style={style}
      onClick={() =>
        !restrictView &&
        props.setFocusedJobStage(
          props.focusedJobStage &&
            props.focusedJobStage.id == props.jobStageTemplate.id
            ? null
            : props.jobStageTemplate,
        )
      }
    >
      <DragableHeader
        {...props}
        restrictView={restrictView}
        attributes={attributes}
        listeners={listeners}
      />
      <Body {...props} />
    </div>
  );
}
