import React from 'react';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../../../../../utils/mapResultToSelectOption';
import { BOOLEAN_OPTIONS, ApplicationListingFilters } from '../../utils';
import { Col, Row } from 'reactstrap';
import { LocationService } from '../../../../../../../../services/v1/applicant_tracking/LocationService';
import {
  Select,
  SelectOption,
} from '../../../../../../../../components/Select';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function RightToWorkLocationFilter(props: PropTypes) {
  const loadLocations = (name: string, page: number) =>
    mapResultToSelectOption(
      LocationService.list({ name, page }),
      (filter) => filter.locations,
      false,
    );
  const onChange = (values: SelectOption[]) => {
    props.setFilters({
      ...props.filters,
      rightToWorkLocations: values,
      ...(values.length === 0 && { rightToWorkLocationValue: null }),
    });
  };

  return (
    <Col xs='3' className='mt-3h'>
      <label>
        <h5 className=''>Right to work in location</h5>
      </label>
      <Row className='gx-3'>
        <Col xs='6'>
          <MultiSelectAsyncFilter
            isMulti
            placeholder='Select location'
            loadOptions={loadLocations}
            onChange={onChange}
            className='ps-0 fs-5 fw-semibold'
            disabled={props.isLoading}
            selected={props.filters?.rightToWorkLocations || []}
          />
        </Col>
        <Col xs='6'>
          <Select
            options={BOOLEAN_OPTIONS}
            selected={BOOLEAN_OPTIONS.find(
              (o) => o.value === props.filters?.rightToWorkLocationValue,
            )}
            disabled={props.isLoading}
            onChange={(rightToWorkLocationValue) =>
              props.setFilters({
                ...props.filters,
                rightToWorkLocationValue: rightToWorkLocationValue?.value,
              })
            }
          />
        </Col>
      </Row>
    </Col>
  );
}
