import React from 'react';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../../../../../utils/mapResultToSelectOption';
import { ApplicationListingFilters } from '../../utils';
import { Col } from 'reactstrap';
import { ChannelList } from '../../../../../../../../entities/v1/applicant_tracking/ChannelList';
import { SOURCING_CHANNELS } from '../../../../../../../../entities/applicant_tracking/Source';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

async function findMatchingChannels(name): Promise<ChannelList> {
  return new Promise((resolve) => {
    const matches = SOURCING_CHANNELS.filter((channel) =>
      channel.name.toLowerCase().includes(name.toLowerCase()),
    );
    resolve({
      page: 1,
      pageCount: 1,
      channels: matches,
      entriesCount: matches.length,
    });
  });
}

export function ChannelsFilter(props: PropTypes) {
  const loadChannels = (name: string, _page: number) =>
    mapResultToSelectOption(
      findMatchingChannels(name),
      (filter) => filter.channels,
      false,
    );

  return (
    <Col xs='3' className='mt-3h'>
      <MultiSelectAsyncFilter
        isMulti
        label='Channel'
        placeholder='Select channels'
        loadOptions={loadChannels}
        onChange={(channels) =>
          props.setFilters({ ...props.filters, channels })
        }
        className='ps-0 fs-5 fw-semibold'
        disabled={props.isLoading}
        selected={props.filters?.channels || []}
      />
    </Col>
  );
}
