import React, { useEffect, useState } from 'react';
import { FiltersHeader } from './FiltersHeader';
import { FiltersBody } from './FiltersBody';
import {
  ApplicationListingFilters,
  AVAILABLE_FILTERS_TYPES,
  FilterType,
} from './utils';
import { mapSearchSegmentToFilters } from './utils/mapSearchSegmentToFilters';
import { AlertObject } from '../../../../../../components/Alert';

interface PropTypes {
  filters: ApplicationListingFilters;
  filterTypes: FilterType[];
  onSearch: () => void;
  setAlert: (alert: AlertObject) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
  setFilters: (filters: ApplicationListingFilters) => void;
}

function isNullOrUndefinedOrEmptyArray(value) {
  return value == null || (Array.isArray(value) && value.length === 0);
}

function activateSelectedFilters(
  filters: ApplicationListingFilters,
  filterTypes: FilterType[],
  setFilterTypes: (filterTypes: FilterType[]) => void,
) {
  const OPTIONAL_FILTERS_TO_CHECK = [
    'name',
    'email',
    'location',
    'channels',
    'sources',
    'rightToWorkLocations',
    'resume',
  ];

  const validOptionalFilters = OPTIONAL_FILTERS_TO_CHECK.filter(
    (filter) => !isNullOrUndefinedOrEmptyArray(filters[filter]),
  );

  const newFilterTypes = [
    ...filterTypes,
    ...validOptionalFilters.map((filter) =>
      AVAILABLE_FILTERS_TYPES.find((type) => type.value === filter),
    ),
  ].filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.value === item.value),
  );

  setFilterTypes(newFilterTypes);
}

export function ListApplicationsFilters(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);

  useEffect(() => {
    if (!selectedSegment) return;

    setIsLoading(true);

    mapSearchSegmentToFilters(selectedSegment)
      .then((filters) => {
        activateSelectedFilters(
          filters,
          props.filterTypes,
          props.setFilterTypes,
        );
        props.setFilters(filters);
      })
      .finally(() => setIsLoading(false));
  }, [selectedSegment]);

  return (
    <div className='p-4'>
      <FiltersHeader
        isFiltersOpen={isFiltersOpen}
        filters={props.filters}
        filterTypes={props.filterTypes}
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        setFilterTypes={props.setFilterTypes}
        setIsFiltersOpen={setIsFiltersOpen}
      />
      {isFiltersOpen && (
        <FiltersBody
          filters={props.filters}
          filterTypes={props.filterTypes}
          isLoading={isLoading}
          onSearch={props.onSearch}
          setFilters={props.setFilters}
          setAlert={props.setAlert}
          onCreateSegment={setSelectedSegment}
        />
      )}
    </div>
  );
}
