import { validateEmail } from '../../../../../utils/validators/emailValidator';
import { validatePhoneNumber } from '../../../../../utils/validators/phoneNumberValidator';
import { JobPostingDetailsForm } from '../../JobBoardsTabUI/JobPostingDetails';
import {
  validateSalaryCurrency,
  validateSalaryFromInput,
  validateSalaryPeriod,
  validateSalaryToInput,
  validateWeeklyHoursFromInput,
  validateWeeklyHoursToInput,
} from './sharedFormValidations';

const FORM_OPTIONAL_FIELDS = [
  // Organic Post Fields that need to be ignored for paid posts
  'companyId',
  'organicEnabled',
  // Optional Paid Post Fields
  'address2',
  'contactEmail',
  'contactName',
  'contactPhone',
  'remote',
  'salaryCurrency',
  'salaryPeriod',
  'salaryRangeFrom',
  'salaryRangeTo',
  'weeklyHoursFrom',
];

export function isPaidPostFormValid(form: JobPostingDetailsForm): boolean {
  if (form == null) {
    return false;
  }

  const ignoredFields = FORM_OPTIONAL_FIELDS;

  for (const [key, value] of Object.entries(form)) {
    if (ignoredFields.includes(key)) {
      continue;
    }

    if (!value) {
      return false;
    }
  }

  if (isContactGroupInvalid(form)) {
    return false;
  }

  if (isSalaryGroupInvalid(form)) {
    return false;
  }

  if (isWeeklyHoursInvalid(form)) {
    return false;
  }

  return true;
}

function isContactGroupInvalid(form: JobPostingDetailsForm) {
  if (validateEmail(form.contactEmail)) {
    return true;
  }

  if (validatePhoneNumber(form.contactPhone)) {
    return true;
  }

  return false;
}

function isSalaryGroupInvalid(form: JobPostingDetailsForm) {
  if (!validateSalaryCurrency(form)) {
    return true;
  }

  if (!validateSalaryPeriod(form)) {
    return true;
  }

  if (validateSalaryFromInput(form)) {
    return true;
  }

  if (validateSalaryToInput(form)) {
    return true;
  }

  if (validateSalaryFromInput(form)) {
    return true;
  }

  return false;
}

function isWeeklyHoursInvalid(form: JobPostingDetailsForm) {
  if (validateWeeklyHoursFromInput(form)) {
    return true;
  }

  if (validateWeeklyHoursToInput(form)) {
    return true;
  }

  return false;
}
