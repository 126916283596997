import React from 'react';
import { Application } from '../../../../entities/Application';
import { ApplicationRatingFeed } from '../../../../entities/ApplicationFeed';
import { User } from '../../../../entities/User';
import { GenericEntry } from '..';
import { AuthorName } from '../AuthorName';
import { Icon } from '../../../../components/Icon';
import { ActivityButton } from '../ActivityButton';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: ApplicationRatingFeed;
  isLastItemInPage: boolean;
  editAction?: boolean;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function ApplicationRatingEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-clipboard-heart'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <AuthorName name={props.entry.authorName} />
          {props.editAction ? (
            <>
              {' '}
              edited <strong>
                {' '}
                {props.application.candidate.name}&apos;s
              </strong>{' '}
              rating.
            </>
          ) : (
            <>
              {' '}
              rated <strong> {props.application.candidate.name}.</strong>
            </>
          )}
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div className='d-flex'>
        {props.entry.applicationRating.rating && (
          <div className='d-flex align-items-center'>
            <Icon name='bi-star-fill' className='p-0 fs-4 text-yellow' />
            <div className='fs-5 fw-bold mx-2'>
              {props.entry.applicationRating.rating}
            </div>
            <div className='fs-6 text-dark-200 me-4'>Candidate Rating</div>
          </div>
        )}
        <ActivityButton
          url={`/applications/${props.application.id}?tab=feedback&show_application_rating_id=${props.entry.applicationRating.id}`}
          text='View Feedback'
        />
      </div>
    </GenericEntry>
  );
}
