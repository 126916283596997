import React from 'react';
import { AVAILABLE_FILTERS_TYPES, FilterType } from '../../utils';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { DropdownGroupedList } from '../../../../../../../../components/DropdownGroupedList';

interface PropTypes {
  filterTypes: FilterType[];
  setFilterTypes: (filterTypes: FilterType[]) => void;
  setIsFiltersOpen: (value: boolean) => void;
}

function buildOptions(filterTypes: FilterType[]) {
  return AVAILABLE_FILTERS_TYPES.map((filter) => ({
    label: filter.name,
    value: filter.value,
    selected: filter.default || filterTypes.includes(filter),
    category: filter.category,
    disabled: filter.default,
  }));
}

export function AddFilterButton(props: PropTypes) {
  return (
    <DropdownGroupedList
      buttonIcon={{ name: 'bi-plus-circle' }}
      buttonText='Add Filter'
      buttonClassName={classNames(
        'bg-blue text-white px-2h',
        styles['add-filter-button'],
      )}
      menuPlacementClass={classNames(
        'dropdown-menu-start mt-1',
        styles['dropdown-menu'],
      )}
      options={buildOptions(props.filterTypes)}
      onChange={(filterName, value) => {
        if (value) {
          props.setFilterTypes([
            ...props.filterTypes,
            AVAILABLE_FILTERS_TYPES.find(
              (filter) => filter.value === filterName,
            ),
          ]);
          props.setIsFiltersOpen(true);
        } else {
          props.setFilterTypes(
            props.filterTypes.filter((filter) => filter.value !== filterName),
          );
        }
      }}
    />
  );
}
