import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../../../../components/CloseableModal';
import { JobStage } from '../../../../../../../entities/v1/applicant_tracking/JobStage';
import { PlanTemplateService } from '../../../../../../../services/v1/applicant_tracking/PlanTemplateService';
import { Body } from './Body';
import { Footer } from './Footer';
import { PlanTemplate } from '../../../../../../../entities/v1/applicant_tracking/PlanTemplate';
import { JobStageService } from '../../../../../../../services/v1/applicant_tracking/JobStageService';

interface PropTypes {
  isOpen: boolean;
  jobId: number;
  newWorkflowId: string;
  onClose: (success: boolean, mappedWorkflow?: Map<number, number>) => void;
}

export type StageTuple = { id: number; label: string };

export function ChangeWorkflowModal(props: PropTypes) {
  const [oldStages, setOldStages] = useState<JobStage[]>(null);
  const [oldWorkflow, setOldWorkflow] = useState<PlanTemplate>(null);
  const [mapping, setMapping] = useState(new Map<number, StageTuple>());

  useEffect(() => {
    (async () => {
      if (props.isOpen) {
        setMapping(new Map<number, StageTuple>());

        if (oldStages == null) {
          const stages = await JobStageService.list({
            jobId: props.jobId,
            pageSize: 100,
            includeApplicationCount: true,
          });

          setOldStages(
            stages.jobStages.filter((v: JobStage) => v.applicationsCount > 0),
          );
        }

        if (oldWorkflow == null) {
          const workflow = await PlanTemplateService.list({
            jobId: props.jobId,
            expand: ['stage_plan_templates'],
          });
          setOldWorkflow(workflow.planTemplates[0]);
        }
      }
    })();
  }, [props.isOpen]);

  const handleChange = (oldJobStageId: number, newStage: StageTuple) => {
    setMapping(new Map(mapping.set(oldJobStageId, newStage)));
  };

  return (
    <CloseableModal
      isOpen={props.isOpen}
      size='lg'
      headerTitle='Change Job Workflow'
      bodyChildren={
        <Body
          mapping={mapping}
          newStages={() =>
            PlanTemplateService.get(parseInt(props.newWorkflowId), {
              expand: ['stage_plan_templates'],
            })
          }
          oldStages={oldStages}
          onSelectedStages={handleChange}
        />
      }
      footerClassName='justify-content-between'
      footerChildren={
        <Footer
          onClose={props.onClose}
          disabled={oldStages == null || oldStages?.length != mapping.size}
          mapping={mapping}
          oldWorflow={oldWorkflow}
        />
      }
      onClose={() => props.onClose(false)}
    />
  );
}
