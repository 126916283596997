import React from 'react';
import { OfferFeed } from '../../../../../entities/ApplicationFeed';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { AlertObject } from '../../../../../components/Alert';
import { GenericEntry } from '../..';
import { ActivityButton } from '../../ActivityButton';

interface PropTypes {
  application: Application;
  currentUser: User;
  isLastItemInPage: boolean;
  entry: OfferFeed;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function OfferAccepted(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-check2-circle'
      iconClassName='bg-light-success'
      title={
        <span>
          <strong>{props.application.candidate.name}</strong> {' accepted the '}
          <strong>{props.application.job.name}</strong> Offer.
          {props.entry.offerId && (
            <ActivityButton
              url={`/applicant_tracking/offers/${props.entry.offerId}/preview`}
              size='sm'
              openInNewTab
              text='View Offer'
              icon='bi-box-arrow-up-right'
              className='ms-3'
            />
          )}
        </span>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
