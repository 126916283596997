import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../../../components/Select';
import {
  JobPostTemplateInput,
  loadJobPostOptions,
} from './JobPostTemplateInput';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Col, Row } from 'reactstrap';
import { ShortDescriptionInput } from './ShortDescriptionInput';
import { UseFormGetValues } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { StopGeneratingButton } from './StopGeneratingButton';
import { GenerateButton } from './GenerateButton';
import { GenerateState } from '../../../../../../../components/FillWithAIButton';

interface PropTypes {
  setIsGeneratorOpened: (value) => void;
  formGetValues: UseFormGetValues<CreateJobDto>;
  setEditorState: (value: string) => void;
  aiGenerateState: GenerateState;
  setAiGenerateState: (value) => void;
  selectedjobPostTemplate: SelectOption;
  setSelectedjobPostTemplate: (value) => void;
  description: string;
  setDescription: (value) => void;
}

export function GenerateJobDescription(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [jobPostTemplateOptions, setJobPostTemplateOptions] = useState<
    SelectOption[]
  >([]);

  const [isErrorMessage, setIsErrorMessage] = useState(false);

  useEffect(() => {
    (async () => {
      setJobPostTemplateOptions(await loadJobPostOptions());

      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <Row className='mt-4'>
        <FormFieldHeader
          fieldName={'Fill Description with AI'}
          isRequired={false}
          classNames={'fw-semibold'}
          fontSize={'fs-3'}
        />
      </Row>
      <Row className='mt-4'>
        <Col className='col-3'>
          <JobPostTemplateInput
            jobPostOptions={jobPostTemplateOptions}
            selectedJobPostOption={props.selectedjobPostTemplate}
            onSelectedJobPostUpdate={props.setSelectedjobPostTemplate}
            isLoading={isLoading}
            disabled={props.aiGenerateState == 'Generating'}
          />
        </Col>
        <Col className='me-auto'>
          <ShortDescriptionInput
            description={props.description}
            onChangeDescription={props.setDescription}
            disabled={props.aiGenerateState == 'Generating'}
          />
        </Col>
        <Col className='d-flex col-1 justify-content-center align-items-end'>
          {
            <StopGeneratingButton
              aiGenerateStatus={props.aiGenerateState}
              setIsGeneratorOpened={props.setIsGeneratorOpened}
              setAiGenerateState={props.setAiGenerateState}
              isErrorMessage={isErrorMessage}
              setIsErrorMessage={setIsErrorMessage}
            />
          }
        </Col>
        <Col className='d-flex col-2 justify-content-center align-items-end'>
          {
            <GenerateButton
              aiGenerateState={props.aiGenerateState}
              setIsErrorMessage={setIsErrorMessage}
              setEditorState={props.setEditorState}
              setAiGenerateState={props.setAiGenerateState}
              formGetValues={props.formGetValues}
              selectedjobPostTemplate={props.selectedjobPostTemplate}
              description={props.description}
            />
          }
        </Col>
      </Row>
    </>
  );
}
