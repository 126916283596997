import React from 'react';
import { JobPostingDetailsForm } from '..';
import {
  validateWeeklyHoursFromInput,
  validateWeeklyHoursToInput,
} from '../../../utils/validators/sharedFormValidations';
import { NumberField } from '../NumberField';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: any;
  onInputChange: (name: string, value: unknown) => void;
}

export function WeeklyRange(props: PropTypes) {
  return (
    <div className='d-flex gap-2'>
      <NumberField
        className='w-50'
        label='Weekly Hours Range'
        value={props.form.weeklyHoursFrom}
        name='weeklyHoursFrom'
        onInputChange={props.onInputChange}
        placeholder='From'
        error={
          validateWeeklyHoursFromInput(props.form) ??
          props.errors['weeklyWorkingHours']?.['from']
        }
      />
      <NumberField
        className='w-50'
        label='To'
        value={props.form.weeklyHoursTo}
        name='weeklyHoursTo'
        onInputChange={props.onInputChange}
        mandatory={true}
        placeholder='To'
        error={
          validateWeeklyHoursToInput(props.form) ??
          props.errors['weeklyWorkingHours']?.['to']
        }
      />
    </div>
  );
}
