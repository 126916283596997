import React from 'react';
import { CardTitle } from '../../../../../components/Card';
import { FilterState } from '../../Filters';
import { TitledPanel } from '../TitledPanel';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { Time } from '../../../../../entities/applicant_tracking/analytics/Time';
import { TimeService } from '../../../../../services/applicant_tracking/analytics/TimeService';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import stylesheetColors from '../../../../../stylesheets/export.module.scss';
import { truncateNumber } from '../../../../../utils/number';
import { WidgetEmptyStateContent } from '../../../../survey_engine/WidgetEmptyStateContent';
import { useAnalyticsData } from '../useAnalyticsData';

interface PropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
  height: string;
}

interface LazyContentPropTypes {
  filterState: FilterState;
  exportToPDF: boolean;
}

interface ContentPropTypes {
  data: Time;
  exportToPDF: boolean;
}

export function TimeWidget(props: PropTypes) {
  return (
    <TitledPanel
      title={<Title exportToPDF={props.exportToPDF} />}
      height={props.height}
    >
      <LazyContent
        filterState={props.filterState}
        exportToPDF={props.exportToPDF ?? false}
      />
    </TitledPanel>
  );
}

function Title(props: { exportToPDF?: boolean }) {
  return (
    <div className='hstack gap-2'>
      <CardTitle>Time</CardTitle>
      {props.exportToPDF !== true && (
        <InfoTooltip>
          Average time to fill vs. average time to hire time comparison
        </InfoTooltip>
      )}
    </div>
  );
}

function LazyContent(props: LazyContentPropTypes) {
  const data = useAnalyticsData(TimeService.show, props.filterState);

  if (!data) return <LoadingSpinner />;

  if (data.averageDaysToHire == null) {
    return <WidgetEmptyStateContent />;
  } else {
    return <Content data={data} exportToPDF={props.exportToPDF} />;
  }
}

function Content(props: ContentPropTypes) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            if (
              index === 0 ||
              index === ticks.length - 1 ||
              index === (ticks.length - 1) / 2
            ) {
              return value;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'end' as const,
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },
    },
  };

  const data = {
    labels: props.data.daysToFill.map((d) =>
      moment(d.startDate, 'YYYY-MM-DD').format('MMM DD, YYYY'),
    ),
    datasets: [
      {
        label: 'AVG Time to Fill',
        data: props.data.daysToFill.map((d) => d.value),
        backgroundColor: stylesheetColors.pink,
        borderColor: stylesheetColors.pink,
      },
      {
        label: 'AVG Time to Hire',
        data: props.data.daysToHire.map((d) => d.value),
        backgroundColor: stylesheetColors.blue500,
        borderColor: stylesheetColors.blue500,
      },
    ],
  };

  /* eslint-disable no-irregular-whitespace */
  return (
    <div className='hstack h-100 gap-5'>
      <div className='vstack align-self-center gap-3 flex-shrink-0'>
        <WideMetric
          value={props.data.averageDaysToFill}
          text='AVG TIME TO FILL'
        />
        <WideMetric
          value={props.data.averageDaysToHire}
          text='AVG TIME TO HIRE'
        />
      </div>
      {/* FIXME: very hakish way of centering this line chart in the card. Fun fact 'charmander' would have worked too, but it was copy pastable :( */}
      {props.exportToPDF && <span className='text-white'>          </span>}
      <div className='w-100 h-100'>
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
/* eslint-enable no-irregular-whitespace */

function WideMetric(props: { value?: number; text: string }) {
  return (
    <div>
      <h3 className='mb-0'>
        <WideValue>{props.value}</WideValue>
      </h3>
      <small className='text-muted'>{props.text}</small>
    </div>
  );
}

function WideValue(props: { children?: number }) {
  if (props.children == null) return <>-</>;

  return (
    <>
      {truncateNumber(props.children)} {props.children === 1 ? 'day' : 'days'}
    </>
  );
}
