import React, { useEffect, useState } from 'react';
import { IconButton } from '../../../components/IconButton';
import { useForm } from 'react-hook-form';
import { FormValues } from '../UserInvitationPage';
import { SelectOption } from '../../../components/Select';
import { UserInviteService } from '../../../services/UserInviteService';
import { NewUserAlerts } from './NewUserAlerts';
import { NewUserModal } from './NewUserModal';

export default function AddNewUserButton() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<SelectOption>({
    value: 'basic',
    label: 'Basic',
  });
  const [failedAlertActive, setFailedAlertActive] = useState<boolean>(false);
  const [successAlertActive, setSuccessAlertActive] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [invalidInput, setInvalidInput] = useState<boolean>(false);
  const [alertEmail, setAlertEmail] = useState<string>(null);

  const { register, watch, handleSubmit, resetField } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  const email = watch('email');

  useEffect(() => {
    if (email) {
      setInvalidInput(false);
    }
  }, [email]);

  const onSubmit = async (data: FormValues) => {
    const { firstName, lastName, email } = data;

    try {
      await UserInviteService.post(
        firstName,
        lastName,
        email,
        selectedRole.value,
      );
      setAlertEmail(email);
      setOpenModal(false);
      setSuccessAlertActive(true);
      resetField('email');
      resetField('firstName');
      resetField('lastName');
      setSelectedRole({
        value: 'basic',
        label: 'Basic',
      });
    } catch (e) {
      setFailedAlertActive(true);
      setErrorMessage(
        e.body.error ||
          e.body?.email?.[0] ||
          'An unexpected error has occurred, try again later',
      );
      setInvalidInput(true);
      resetField('email');
    }
  };

  return (
    <>
      <NewUserAlerts
        failedAlertActive={failedAlertActive}
        setFailedAlertActive={setFailedAlertActive}
        errorMessage={errorMessage}
        successAlertActive={successAlertActive}
        setSuccessAlertActive={setSuccessAlertActive}
        email={alertEmail}
      />
      <IconButton
        buttonText='Add User'
        iconName='bi-person-plus'
        onClick={() => setOpenModal(true)}
        buttonClass={'mt-3'}
        color='primary'
      />
      <NewUserModal
        register={register}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        invalidInput={invalidInput}
        openModal={openModal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setOpenModal={setOpenModal}
        email={email}
      />
    </>
  );
}
