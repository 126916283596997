import React from 'react';
import { InputFormField } from '../../../../../../../../components/InputFormField';

interface PropTypes {
  description: string;
  onChangeDescription: (newDescription) => void;
  disabled: boolean;
}

export function ShortDescriptionInput(props: PropTypes) {
  return (
    <InputFormField
      fieldName={'Short Description'}
      placeholder={'Add additional context to improve the AI response'}
      classNames={'fs-6'}
      headerClassNames={'fw-semibold'}
      type={'text'}
      value={props.description ?? ''}
      setValueOnChange={(newDescription) =>
        props.onChangeDescription(newDescription)
      }
      disabled={props.disabled}
    />
  );
}
