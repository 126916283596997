import { JobPostingDetailsForm } from '../../JobBoardsTabUI/JobPostingDetails';
import {
  validateNumberInput,
  validateSalaryCurrency,
  validateSalaryFromInput,
  validateSalaryPeriod,
  validateSalaryToInput,
  validateWeeklyHoursFromInput,
  validateWeeklyHoursToInput,
} from './sharedFormValidations';

const FORM_OPTIONAL_FIELDS = [
  'applicationFormUrl',
  'companyId',
  'contactName',
  'contactEmail',
  'editorStateHtml',
  'educationLevel',
  'jobPostUrl',
  'salaryCurrency',
  'salaryPeriod',
  'salaryRangeFrom',
  'salaryRangeTo',
  'seniority',
  'skills',
  'title',
  'weeklyHoursFrom',
  'yearsOfExperience',
];

export function validateOrganicForm(form: JobPostingDetailsForm) {
  return {
    salaryIndication: {
      range: {
        from: validateSalaryFromInput(form),
        to: validateSalaryToInput(form),
      },
    },
    weeklyWorkingHours: {
      from: validateWeeklyHoursFromInput(form),
      to: validateWeeklyHoursToInput(form),
    },
    linkedinCompanyId: validateNumberInput(form.companyId),
  };
}

export function isOrganicPostFormValid(form: JobPostingDetailsForm): boolean {
  if (form == null) {
    return false;
  }

  const ignoredFields = FORM_OPTIONAL_FIELDS;

  for (const [key, value] of Object.entries(form)) {
    if (ignoredFields.includes(key)) {
      continue;
    }

    if (!value) {
      return false;
    }
  }

  if (!validateSalaryCurrency(form)) {
    return false;
  }

  if (!validateSalaryPeriod(form)) {
    return false;
  }

  if (validateSalaryFromInput(form)) {
    return false;
  }

  if (validateSalaryToInput(form)) {
    return false;
  }

  if (validateWeeklyHoursFromInput(form)) {
    return false;
  }

  if (validateWeeklyHoursToInput(form)) {
    return false;
  }

  // Validate Linkedin Company Id
  if (validateNumberInput(form.companyId)) {
    return false;
  }

  return true;
}
