import React, { useState } from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { InterviewStage } from '../../../../../entities/applicant_tracking/InterviewStage';
import ScheduleInterviewPage, { SchedulingSettings } from '../index';
import { User } from '../../../../../entities/User';
import candidateSchedulingImage from '../../../../../images/candidate-experience-mailer-new-survey.png';
import {
  InterviewDatePickerFooter,
  UpsertSelfScheduleInterviewPropTypes,
} from '../InterviewDatePicker/InterviewDatePickerFooter';
import { InterviewCandidateUserService } from '../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import {
  appendCurrentRedirectUri,
  appendQueryParam,
} from '../../../../../utils/url';
import { ShareTeamAvailabilityFooter } from '../ShareTeamAvailabilityFooter';
import {
  getSessionSelfSchedulingDto,
  getSessionUUID,
  SESSION_UUID_PARAM,
  setSessionSelfSchedulingDto,
} from '../../../../../utils/applicant_tracking/buildSelfScheduling';
import { appendDateQueryParamsToUrl } from '../utils/buildDateQueryParams';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  currentUser: User;
  availableParticipants: User[];
  jobId: number;
  interviewStage: InterviewStage;
  meetingProviders: VcsProvider[];
  defaultMeetingProvider: VcsProvider;
}

export default function BulkScheduleInterview(props: PropTypes) {
  const [schedulingSettings, setSchedulingSettings] =
    useState<SchedulingSettings>(null);

  const applicationIds = getSessionSelfSchedulingDto()?.applicationIds;

  if (!applicationIds || applicationIds.length === 0) {
    window.location.href = appendCurrentRedirectUri(
      `/applicant_tracking/interview_candidate_users/schedule/error`,
    );
  }

  const onConfirm = async (data: UpsertSelfScheduleInterviewPropTypes) => {
    try {
      const dto = await InterviewCandidateUserService.bulkCreateSelfSchedule(
        applicationIds,
        props.jobId,
        props.interviewStage.id,
        data.interviewerUserIds,
        data.meetingProvider,
        data.durationMinutes,
        data.dateRange,
        data.scheduleFromHours,
        data.timezone,
        data.meetingOrganizer.id,
      );

      setSessionSelfSchedulingDto(dto);

      let redirectUri =
        '/applicant_tracking/interview_candidate_users/bulk_show';

      redirectUri = appendDateQueryParamsToUrl(
        redirectUri,
        data.scheduleFromHours,
        data.dateRange,
        data.timezone,
      );

      redirectUri = appendQueryParam(
        redirectUri,
        SESSION_UUID_PARAM,
        getSessionUUID(),
      );

      window.location.href = appendCurrentRedirectUri(redirectUri);
    } catch (e) {
      window.location.href = appendCurrentRedirectUri(
        `/applicant_tracking/interview_candidate_users/schedule/error`,
      );
    }
  };

  return (
    <ScheduleInterviewPage
      meetingOrganizer={props.currentUser}
      applicationIds={applicationIds}
      title={'Let the candidate schedule the interview'}
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      participants={[props.currentUser]}
      availableParticipants={props.availableParticipants}
      interviewStage={props.interviewStage}
      meetingProviders={props.meetingProviders}
      meetingProvider={props.defaultMeetingProvider}
      customImage={candidateSchedulingImage}
      onConfirm={(_data) => null}
      candidateView={false}
      selfScheduleView={true}
      allowChangingMeetingOrganizer={true}
      allowIgnoreParticipantCalendar={false}
      setSchedulingSettings={setSchedulingSettings}
      interviewDatePickerFooter={
        <InterviewDatePickerFooter
          buttonText='Confirm Availability'
          description={
            <ShareTeamAvailabilityFooter
              schedulingSettings={schedulingSettings}
            />
          }
          onConfirm={onConfirm}
        />
      }
    />
  );
}
