import React from 'react';
import { User } from '../../../../../../../../entities/User';
import { Scorecard } from '../../../../../../../../entities/applicant_tracking/Scorecard';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { TruncateText } from '../../../../../../../../components/TruncateText';

const MAX_CHAR_LIMIT = 24;

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

function memberName(currentUser: User, scorecardUser: User) {
  if (scorecardUser?.id === currentUser.id) {
    return 'My Own';
  }

  return scorecardUser?.name;
}

export function ScorecardBlockTitle(props: PropTypes) {
  const interviewScorecard = props.scorecard.interviewId ? true : false;

  return (
    <>
      {!props.viewAllScorecards ? (
        <div className='fw-semibold fs-4 me-3'>
          <TruncateText
            tooltip
            ellipsize
            maxChars={MAX_CHAR_LIMIT}
            text={props.scorecard.jobStage?.name}
          />
        </div>
      ) : (
        <>
          <div className='fw-semibold fs-4 me-3'>
            <TruncateText
              tooltip
              ellipsize
              maxChars={MAX_CHAR_LIMIT}
              text={memberName(props.currentUser, props.scorecard.user)}
            />
          </div>
          <Chip
            color={
              interviewScorecard ? ChipColor.OrangeLight : ChipColor.BlueLight
            }
          >
            <TruncateText
              tooltip
              ellipsize
              maxChars={MAX_CHAR_LIMIT}
              text={props.scorecard.jobStage?.name}
            />
          </Chip>
        </>
      )}
    </>
  );
}
