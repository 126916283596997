import React, { useEffect, useState } from 'react';
import { EmailTemplate } from '../../../../../../../../entities/EmailTemplate';
import { PlanTemplate } from '../../../../../../../../entities/applicant_tracking/PlanTemplate';
import {
  SendEmailTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { DelayDropdown } from '../Dropdown/DelayDropdown';
import { StagesDropdown } from '../Dropdown/StagesDropdown';
import { EmailTemplateDropdown } from '../Dropdown/EmailTemplateDropdown';
import { StatusDropdown } from '../Dropdown/StatusDropdown';

interface PropTypes {
  emailTemplates: EmailTemplate[];
  counter: number;
  trigger: Trigger;
  formData: Trigger[];
  planTemplate?: PlanTemplate;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

export function SendEmailTriggerRow(props: PropTypes) {
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [delayOptions, setDelayOptions] = useState([]);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState(
    props.trigger.applicationStatuses || [],
  );
  const [selectedStageOptions, setSelectedStageOptions] = useState<number[]>(
    props.trigger.stageIds || [],
  );

  const updateTrigger = () =>
    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'send_email',
        emailTemplateId: parseInt(
          emailTemplateOptions.find((v) => v.selected)?.value,
        ),
        applicationStatuses: selectedStatusOptions,
        stageIds: selectedStageOptions,
        delayDays: parseInt(delayOptions.find((v) => v.selected)?.value ?? ''),
      },
      props.counter,
    );

  useEffect(updateTrigger, [
    emailTemplateOptions,
    selectedStatusOptions,
    selectedStageOptions,
    delayOptions,
  ]);

  return (
    <>
      <EmailTemplateDropdown
        trigger={props.trigger as SendEmailTrigger}
        emailTemplates={props.emailTemplates}
        emailTemplateOptions={emailTemplateOptions}
        setEmailTemplateOptions={setEmailTemplateOptions}
        isMandatory={true}
      />
      <StatusDropdown
        trigger={props.trigger as SendEmailTrigger}
        selectedStatusOptions={selectedStatusOptions}
        setSelectedStatusOptions={setSelectedStatusOptions}
      />
      <StagesDropdown
        label={'If the Stage'}
        trigger={props.trigger}
        planTemplate={props.planTemplate}
        selectedOptions={selectedStageOptions}
        setSelectedStageOptions={setSelectedStageOptions}
        onlyInterviewStages={false}
      />
      <DelayDropdown
        trigger={props.trigger}
        delayOptions={delayOptions}
        setDelayOptions={setDelayOptions}
      />
    </>
  );
}
