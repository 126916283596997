import React, { useState } from 'react';
import { SingleComment } from '../SingleComment';
import { Button } from 'reactstrap';
import { ApplicationFeedComment } from '../../../../../../entities/ApplicationFeedComment';
import { CommentButton } from '../CommentButton';
import { User } from '../../../../../../entities/User';
import { AvatarList } from './AvatarList';
import { ApplicationFeed } from '../../../../../../entities/ApplicationFeed';
import { AlertObject } from '../../../../../../components/Alert';
import { getLabelForCount } from '../../../../../../utils/grammar';

interface PropTypes {
  applicationFeed: ApplicationFeed;
  comments: ApplicationFeedComment[];
  currentUser: User;
  showNewCommentBox: boolean;
  reloadFeed: () => void;
  setAlert: (data: AlertObject) => void;
  setShowNewCommentBox: (state: boolean) => void;
  setShowReplyToEmail: (state: boolean) => void;
  setViewAllComments?: (state: boolean) => void;
}

function ExpandButton(props: { text: string; onClick: () => void }) {
  return (
    <Button size='md' color='borderless' onClick={props.onClick}>
      {props.text}
    </Button>
  );
}

function ConfinedView(props: PropTypes) {
  return (
    <div className='mt-2 p-0 ms-5'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='d-flex ps-1 me-2 align-items-center'>
            <AvatarList comments={props.comments} />
            <span className='text-dark-200 ms-1'>
              {getLabelForCount('comment', props.comments.length)}
            </span>
          </div>
          <ExpandButton
            text='View All'
            onClick={() => props.setViewAllComments(true)}
          />
        </div>
        <CommentButton
          showNewCommentBox={props.showNewCommentBox}
          entryType={props.applicationFeed.entryType}
          setShowNewCommentBox={props.setShowNewCommentBox}
          setShowReplyToEmail={props.setShowReplyToEmail}
        />
      </div>
    </div>
  );
}

function ExpandedView(props: PropTypes) {
  return (
    <>
      {props.comments.map((comment: ApplicationFeedComment, index) => (
        <SingleComment
          applicationFeed={props.applicationFeed}
          comment={comment}
          currentUser={props.currentUser}
          key={index}
          reloadFeed={props.reloadFeed}
          setAlert={props.setAlert}
        />
      ))}
      <div className='d-flex justify-content-between mt-2 ms-5'>
        <ExpandButton
          text='View Less'
          onClick={() => props.setViewAllComments(false)}
        />
        <CommentButton
          entryType={props.applicationFeed.entryType}
          showNewCommentBox={props.showNewCommentBox}
          setShowNewCommentBox={props.setShowNewCommentBox}
          setShowReplyToEmail={props.setShowReplyToEmail}
        />
      </div>
    </>
  );
}

export function MultipleComments(props: PropTypes) {
  const [viewAllComments, setViewAllComments] = useState<boolean>(false);

  return (
    <>
      {viewAllComments ? (
        <ExpandedView {...props} setViewAllComments={setViewAllComments} />
      ) : (
        <ConfinedView {...props} setViewAllComments={setViewAllComments} />
      )}
    </>
  );
}
