import React from 'react';
import { JobStageTemplateInput } from '../../../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { StageMoveDTO } from '../../../../../containers/PlanTemplates/SetupPlanTemplatePage/DTOs/StageMoveDTO';
import styles from './styles.module.scss';
import { IconButton } from '../../../../IconButton';

interface PropTypes {
  jobStageTemplate: JobStageTemplateInput;
  isFocused: boolean;
  stageMoves?: StageMoveDTO[];
  onDelete: () => void;
}

function DeleteStage(props: { onDelete: () => void; color: string }) {
  return (
    <IconButton
      onClick={props.onDelete}
      color='transparent'
      buttonClass={styles['delete-button']}
      iconName={'bi-trash'}
      iconClass={`fs-4 ${props.color}`}
    />
  );
}

export default function RowActionDelete(props: PropTypes) {
  const color = props.isFocused ? 'text-white' : 'text-blue';
  const receivingMoves =
    props.stageMoves.filter((move) => move.to === props.jobStageTemplate.name)
      .length > 0;

  if (receivingMoves) {
    return null;
  }

  return <DeleteStage onDelete={props.onDelete} color={color} />;
}
