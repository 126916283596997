import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import {
  Control,
  FormState,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import React, { useState } from 'react';
import { GenerateJobDescription } from './GenerateJobDescription';
import { JobDescriptionEditor } from './JobDescriptionEditor';
import { JobDescriptionHeader } from './JobDescriptionHeader';
import { SelectOption } from '../../../../../../components/Select';
import { GenerateState } from '../../../../../../components/FillWithAIButton';
import { FootnoteMessage } from './FootnoteMessage';

interface PropTypes {
  formControl: Control<CreateJobDto>;
  formState: FormState<CreateJobDto>;
  formResetField: (controllerName: string) => void;
  formGetValues: UseFormGetValues<CreateJobDto>;
  formSetValue: UseFormSetValue<CreateJobDto>;
  previewUrl: string;
  updateGlobalStateCallback: (data: any) => void;
  generateJobDescriptionEnabled: boolean;
}

export function JobDescriptionInput(props: PropTypes) {
  const [isGeneratorOpened, setIsGeneratorOpened] = useState(false);
  const [aiGenerateState, setAiGenerateState] =
    useState<GenerateState>('Generate');
  const [generatedJobDescription, setGeneratedJobDescription] =
    useState<string>(null);
  const [selectedjobPostTemplate, setSelectedjobPostTemplate] =
    useState<SelectOption>(null);

  const [description, setDescription] = useState<string>(null);

  function handleFillWithAIButtonClick() {
    setIsGeneratorOpened(true);
  }

  return (
    <>
      {
        <JobDescriptionHeader
          formState={props.formState}
          previewUrl={props.previewUrl}
          isGeneratorOpened={isGeneratorOpened}
          aiGenerateState={aiGenerateState}
          handleFillWithAIButtonClick={handleFillWithAIButtonClick}
          generateJobDescriptionEnabled={props.generateJobDescriptionEnabled}
        />
      }
      {isGeneratorOpened && props.generateJobDescriptionEnabled && (
        <GenerateJobDescription
          setIsGeneratorOpened={setIsGeneratorOpened}
          aiGenerateState={aiGenerateState}
          setAiGenerateState={setAiGenerateState}
          formGetValues={props.formGetValues}
          setEditorState={setGeneratedJobDescription}
          selectedjobPostTemplate={selectedjobPostTemplate}
          setSelectedjobPostTemplate={setSelectedjobPostTemplate}
          description={description}
          setDescription={setDescription}
        />
      )}
      {
        <JobDescriptionEditor
          formControl={props.formControl}
          generatedJobDescription={generatedJobDescription}
          updateGlobalStateCallback={props.updateGlobalStateCallback}
          disabled={aiGenerateState == 'Generating'}
        />
      }
      <FootnoteMessage aiGenerateState={aiGenerateState} />
    </>
  );
}
