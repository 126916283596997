import { IconButton } from '../../../../../../../../components/IconButton';
import React from 'react';
import { generateAiResponse } from '../generateAiResponse';
import { UseFormGetValues } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { SelectOption } from '../../../../../../../../components/Select';
import { GenerateState } from '../../../../../../../../components/FillWithAIButton';
import { GeneratingIcon } from './GeneratingIcon';

interface PropTypes {
  aiGenerateState: GenerateState;
  setIsErrorMessage: (value) => void;
  setEditorState: (value) => void;
  setAiGenerateState: (value) => void;
  formGetValues: UseFormGetValues<CreateJobDto>;
  selectedjobPostTemplate?: SelectOption;
  description: string;
}

export function GenerateButton(props: PropTypes) {
  function handleOnGenerateButtonClick() {
    props.setAiGenerateState('Generating');
    props.setIsErrorMessage(false);
    return generateDescription();
  }

  const generateDescription = async () =>
    await generateAiResponse(
      props.setEditorState,
      props.setIsErrorMessage,
      props.setAiGenerateState,
      props.formGetValues('jobId'),
      props.formGetValues('name'),
      props.formGetValues('locationId'),
      props.formGetValues('locationType'),
      props.formGetValues('employmentType'),
      props.formGetValues('experienceLevel'),
      props.selectedjobPostTemplate
        ? props.selectedjobPostTemplate.value
        : null,
      props.description,
    );

  return (
    <>
      {['Generate', 'Regenerate'].includes(props.aiGenerateState) ? (
        <IconButton
          color='info'
          buttonText='Generate Description'
          iconName='bi-stars'
          size='md'
          onClick={handleOnGenerateButtonClick}
        />
      ) : (
        <GeneratingIcon aiGenerateState={props.aiGenerateState} />
      )}
    </>
  );
}
