import React from 'react';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../../../../../utils/mapResultToSelectOption';
import { ApplicationListingFilters } from '../../utils';
import { Col } from 'reactstrap';
import { SourceService } from '../../../../../../../../services/v1/applicant_tracking/SourceService';

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function SourcesFilter(props: PropTypes) {
  const loadSources = (name: string, page: number) =>
    mapResultToSelectOption(
      SourceService.list({ name, page }),
      (filter) => filter.sources,
      false,
    );

  return (
    <Col xs='3' className='mt-3h'>
      <MultiSelectAsyncFilter
        isMulti
        label='Source'
        placeholder='Select sources'
        loadOptions={loadSources}
        onChange={(sources) => props.setFilters({ ...props.filters, sources })}
        className='ps-0 fs-5 fw-semibold'
        disabled={props.isLoading}
        selected={props.filters?.sources || []}
      />
    </Col>
  );
}
