import React, { useEffect, useState } from 'react';
import { CenteredLayout } from '../../../../components/CenteredLayout';
import { PreviewJobPost } from '../../../../entities/applicant_tracking/PreviewJobPost';
import { CompanyInfo } from '../../../../entities/CompanyInfo';
import JobPostPageHeader from './JobPostPageHeader';
import { ApplicationFormTab } from './ApplicationFormTab';
import { StateMachineProvider } from 'little-state-machine';
import { OverviewTab } from './OverviewTab';
import { UnpublishedJobEmptyState } from './UnpublishedJobEmptyState';
import { LogoWithBackgroundHeader } from '../../../Headers/LogoWithBackgroundHeader';
import { getQueryParam } from '../../../../utils/url';
import { Alert, AlertObject } from '../../../../components/Alert';

export type JobPostPageTab = 'overview' | 'application_form';

interface PropTypes {
  activeTab?: JobPostPageTab;
  companyInfo?: CompanyInfo;
  jobPostHtmlString?: string;
  previewMode: boolean;
  previewJobPost: PreviewJobPost;
}

function queryStringTab(): JobPostPageTab | null {
  return getQueryParam('tab') as JobPostPageTab;
}

function defaultTab(viewSelectedTab?: JobPostPageTab) {
  return queryStringTab() ?? viewSelectedTab ?? 'overview';
}

export default function JobPostPage(props: PropTypes) {
  const [activeTab, setActiveTab] = useState<JobPostPageTab>(
    defaultTab(props.activeTab),
  );
  const [alertObject, setAlertObject] = useState<AlertObject>(null);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      const previousUrl = window.location.href;
      if (window.location.search.includes('tab=')) {
        const tab = queryStringTab();
        setActiveTab(tab as JobPostPageTab);
      } else {
        e.preventDefault();
        window.location.href = previousUrl;
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const urlTab = queryStringTab();
    if (urlTab != activeTab) {
      const url = new URL(window.location.href);
      url.searchParams.set('tab', activeTab);

      history.pushState(null, '', url.toString());
    }
  }, [activeTab]);

  return (
    <StateMachineProvider>
      {alertObject && (
        <Alert
          autoClearTimeout={3000}
          clearable
          type={alertObject.type}
          onClose={() => setAlertObject(null)}
        >
          {alertObject.message}
        </Alert>
      )}
      <CenteredLayout heightClass='h-100'>
        <LogoWithBackgroundHeader
          companyInfo={props.companyInfo}
          whitelabelEnabled={props.companyInfo?.logoUrl != null}
        />
        {!props.previewMode && props.previewJobPost.status !== 'published' ? (
          <UnpublishedJobEmptyState />
        ) : (
          <>
            <JobPostPageHeader
              previewMode={props.previewMode}
              previewJobPost={props.previewJobPost}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              companyInfo={props.companyInfo}
            />
            {activeTab === 'overview' && (
              <OverviewTab
                previewMode={props.previewMode}
                jobPostHtmlString={props.jobPostHtmlString}
                setActiveTab={setActiveTab}
              />
            )}
            {activeTab === 'application_form' && (
              <ApplicationFormTab
                companyInfo={props.companyInfo}
                previewJobPost={props.previewJobPost}
                previewMode={props.previewMode}
                setAlertObject={setAlertObject}
              />
            )}
          </>
        )}
      </CenteredLayout>
    </StateMachineProvider>
  );
}
