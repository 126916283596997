import React from 'react';
import { Col, Row } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';
import { TitledPanel } from '../TitledPanel';
import { CardTitle } from '../../../../../components/Card';
import { HiringProcessRates } from '../../../../../entities/applicant_tracking/analytics/HiringProcessRates';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { FilterState } from '../../Filters';
import { HiringProcessRatesService } from '../../../../../services/applicant_tracking/analytics/HiringProcessRatesService';
import { WidgetEmptyStateContent } from '../../../../survey_engine/WidgetEmptyStateContent';
import { useAnalyticsData } from '../useAnalyticsData';

interface PropTypes {
  filterState?: FilterState;
  height: string;
}

interface LazyContentPropTypes {
  filterState?: FilterState;
}

interface Entry {
  label: string;
  chartColor: string;
  chipColor: ChipColor;
  value: number;
}

export function HiringProcessRatesWidget(props: PropTypes) {
  return (
    <TitledPanel title={<Title />} height={props.height}>
      <LazyContent filterState={props.filterState} />
    </TitledPanel>
  );
}

function Title() {
  return (
    <div className='hstack gap-2'>
      <CardTitle>Hiring Process rates</CardTitle>
    </div>
  );
}

function hasHiringProcessRates(hpr: HiringProcessRates): boolean {
  return (
    hpr.hiredCount +
      hpr.activeCount +
      hpr.rejectedByCandidateCount +
      hpr.rejectedByOrganizationCount ===
    0
  );
}

function mapToEntries(hpr: HiringProcessRates): Entry[] {
  return [
    {
      label: 'Hired',
      chartColor: stylesheetExports.green700,
      chipColor: ChipColor.SuccessLight,
      value: hpr.hiredCount,
    },
    {
      label: 'Rejected',
      chartColor: stylesheetExports.chartOrange,
      chipColor: ChipColor.OrangeLight,
      value: hpr.rejectedByOrganizationCount,
    },
    {
      label: 'Withdraw',
      chartColor: stylesheetExports.chartRed,
      chipColor: ChipColor.DangerLight,
      value: hpr.rejectedByCandidateCount,
    },
    {
      label: 'Active',
      chartColor: stylesheetExports.gray300,
      chipColor: ChipColor.Gray,
      value: hpr.activeCount,
    },
  ];
}

function LazyContent(props: LazyContentPropTypes) {
  const data = useAnalyticsData(
    HiringProcessRatesService.show,
    props.filterState,
  );

  if (!data) return <LoadingSpinner />;

  if (hasHiringProcessRates(data)) return <WidgetEmptyStateContent />;

  return <Content data={mapToEntries(data)} />;
}

function Content(props: { data: Entry[] }) {
  return (
    <div className='d-flex h-100 gap-3'>
      <Chart data={props.data} />
      <EntryTable data={props.data} />
    </div>
  );
}

function Chart(props: { data: Entry[] }) {
  const labels = props.data.map((e: Entry) => e.label);
  const values = props.data.map((e: Entry) => e.value);

  const options = {
    cutout: '70%',
    plugins: {
      legend: { display: false },
      tooltip: {
        bodyFont: {
          size: 10,
        },
        displayColors: false,
        callbacks: {
          label: function (ctx) {
            return ctx.label;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: props.data.map((e: Entry) => e.chartColor),
        hoverOffset: 0,
      },
    ],
  };

  return (
    <div className='align-self-center' style={{ maxWidth: '150px' }}>
      <Doughnut options={options} data={data} />
    </div>
  );
}

function EntryTable(props: { data: Entry[] }) {
  return (
    <div className='w-100 align-self-center'>
      {props.data.map((e: Entry, index: number) => (
        <Row key={index} className={'align-items-center mb-2'}>
          <Col xs={1} />
          <Col xs={6} className={'fw-semibold fs-6 text-dark-500'}>
            {e.label}
          </Col>
          <Col xs={4}>
            <Chip color={e.chipColor}>{e.value}</Chip>
          </Col>
        </Row>
      ))}
    </div>
  );
}
