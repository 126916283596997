import React, { CSSProperties, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { Placement } from '@popperjs/core';

interface ControlledSpanTooltipPropTypes {
  isOpen: boolean;
}

interface PropTypes {
  text: React.ReactNode | string;
  tooltipText?: React.ReactNode | string;
  placement?: Placement;
  className?: string;
  tooltipStyle?: CSSProperties;
  tooltipFade?: boolean;
  trigger?: 'hover' | 'focus' | undefined;
  controlledTooltip?: ControlledSpanTooltipPropTypes;
  tooltipInnerClassName?: string;
  showTooltip?: boolean;
  popperClassName?: string;
}

export function SpanTooltip(props: PropTypes) {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const ref = useRef(null);

  return (
    <>
      <span className={classNames(props.className)} ref={ref}>
        {props.text}
      </span>
      {(props.showTooltip == null || props.showTooltip) && (
        <Tooltip
          target={ref}
          isOpen={props.controlledTooltip?.isOpen || isTooltipOpen}
          toggle={
            props.controlledTooltip != null
              ? undefined
              : () => setIsTooltipOpen(!isTooltipOpen)
          }
          placement={props.placement}
          style={props.tooltipStyle}
          fade={props.tooltipFade}
          trigger={props.controlledTooltip != null ? 'manual' : props.trigger}
          innerClassName={props.tooltipInnerClassName}
          popperClassName={props.popperClassName}
        >
          {props.tooltipText || props.text}
        </Tooltip>
      )}
    </>
  );
}
