import { CampaignTargetGroupData } from '@vonq/hapi-elements-types';
import React, { useEffect, useState } from 'react';
import { Card, CardTitle } from '../../../../../components/Card';
import { JobDetails } from '../../../../../entities/JobDetails';
import { TargetGroup } from './TargetGroup';
import { NameFields } from './NameFields';
import { LocationFields } from './LocationFields';
import { LoadingData } from '../../../../../components/LoadMoreButton';
import { JobPost as JobPostV1 } from '../../../../../entities/v1/applicant_tracking/JobPost';
import { PostDetails } from '../../../../../entities/applicant_tracking/jobboards/PostDetails';
import { Button } from 'reactstrap';
import {
  multiLangTargetGroupFromValue,
  targetGroupFromValue,
} from './TargetGroup/utils';
import { JobBoardAlertObject, JobBoardAlertType } from '../JobBoardAlert';
import { JobRequirementsFields } from './JobRequirementsFields';
import { ContactInformationFields } from './ContactInformationFields';
import { TargetGroupValues } from './TargetGroup/TargetGroupValues';
import { AlertMessage } from '../../../../../components/AlertMessage';
import { PostDetailsService } from '../../../../../services/applicant_tracking/analytics/jobboards/PostDetailsService';
import { OrganicPostSkill } from '../../../../../entities/v1/applicant_tracking/OrganicPostSkill';
import { toIntOrNull } from '../../../../../utils/toIntOrNull';
import { isPaidPostFormValid } from '../../utils/validators/paidPostFormValidator';
import { extractNumbers } from '../../../../../utils/extractors/extractNumbers';
import { addSeparatorToSalary } from '../../../../../utils/addSeparatorToSalary';

interface PropTypes {
  form: JobPostingDetailsForm;
  jobDetails: JobDetails;
  postDetails: PostDetails;
  targetGroupValues: TargetGroupValues;
  errors?: Record<string, string>;
  setForm: React.Dispatch<React.SetStateAction<JobPostingDetailsForm>>;
  setAlert: (alert: JobBoardAlertObject) => void;
}

export type SalaryCurrency = 'USD' | 'EUR' | 'GBP' | 'AUD' | 'JPY';

export interface JobPostingDetailsForm {
  // Automatic filled
  editorStateHtml: string;
  jobPostUrl: string;
  applicationFormUrl: string;
  // Job Post
  campaignName?: string;
  title?: string;
  // Location
  address1?: string;
  address2?: string;
  city: string;
  postcode: string;
  country: string;
  countryState?: string;
  locationType?: string;
  // Company info
  companyId?: string;
  // Contact Information
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  // Job Requirements
  yearsOfExperience?: number;
  organicPostSkills?: OrganicPostSkill[];
  salaryPeriod?: string;
  salaryCurrency?: SalaryCurrency;
  salaryRangeFrom?: string;
  salaryRangeTo?: string;
  weeklyHoursFrom?: string;
  weeklyHoursTo: string;
  // Target Group
  educationLevel?: CampaignTargetGroupData;
  seniority?: CampaignTargetGroupData;
  industry: { id: number | string; description: string };
  jobCategory: { id: number | string; description: string };
  organicEnabled?: boolean;
}

export function prefillCampaignForm(
  job: JobDetails,
  jobPost?: JobPostV1,
  postDetails?: PostDetails,
): JobPostingDetailsForm {
  return {
    campaignName:
      postDetails?.campaignName ??
      `${job.name} Campaign ${new Date().toLocaleDateString()}`,
    title: postDetails?.title ?? job.jobPosts[0].name,
    // Automatic filled
    editorStateHtml: jobPost?.editorStateHtml,
    jobPostUrl: jobPost?.jobPostUrl,
    applicationFormUrl: jobPost?.applicationFormUrl,
    //Location
    address1: postDetails?.address ?? jobPost?.location?.address,
    address2: postDetails?.addressDetail,
    city: postDetails?.city ?? jobPost?.location?.city,
    postcode: postDetails?.postcode,
    country: postDetails?.country ?? jobPost?.location?.countryCode,
    locationType: job.locationType,
    // Contact Information
    contactName: postDetails?.contactName,
    contactEmail: postDetails?.contactEmail,
    contactPhone: postDetails?.contactPhone,
    // Job Requirements
    yearsOfExperience: postDetails?.yearsExperience,
    salaryPeriod: postDetails?.salaryPeriod,
    salaryCurrency: postDetails?.salaryCurrency as SalaryCurrency,
    salaryRangeFrom: addSeparatorToSalary(
      postDetails?.salaryRangeFrom?.toString(),
    ),
    salaryRangeTo: addSeparatorToSalary(postDetails?.salaryRangeTo?.toString()),
    weeklyHoursFrom: postDetails?.weeklyHoursFrom?.toString(),
    weeklyHoursTo: postDetails?.weeklyHoursTo?.toString(),
    companyId: postDetails?.companyId,
    // Target Group
    educationLevel: null,
    seniority: null,
    industry: null,
    jobCategory: null,
    organicEnabled: null,
  };
}

export async function saveForm(form: JobPostingDetailsForm, job: JobDetails) {
  await PostDetailsService.create({
    campaignName: form.campaignName,
    title: form.title,
    address: form.address1,
    addressDetail: form.address2,
    city: form.city,
    postcode: form.postcode,
    country: form.country,
    remote: job.locationType != 'onsite',
    contactName: form.contactName,
    contactEmail: form.contactEmail,
    contactPhone: form.contactPhone,
    yearsExperience: form.yearsOfExperience,
    organicPostSkills: form.organicPostSkills || null,
    salaryPeriod: form.salaryPeriod,
    salaryCurrency: form.salaryCurrency,
    salaryRangeFrom: toIntOrNull(extractNumbers(form.salaryRangeFrom)),
    salaryRangeTo: toIntOrNull(extractNumbers(form.salaryRangeTo)),
    weeklyHoursFrom: toIntOrNull(form.weeklyHoursFrom),
    weeklyHoursTo: toIntOrNull(form.weeklyHoursTo),
    educationLevelId: form.educationLevel?.vonqId?.toString(),
    seniorityId: form.seniority?.vonqId?.toString(),
    industryId: form.industry?.id?.toString(),
    jobCategoryId: form.jobCategory?.id?.toString(),
    jobCategoryName: form.jobCategory?.description?.toString(),
    companyId: form.companyId,
    jobId: job.id,
  });
}

export function JobPostingDetails(props: PropTypes) {
  const [formUpdated, setFormUpdated] = useState(false);

  async function handleSave() {
    try {
      await saveForm(props.form, props.jobDetails);
      setFormUpdated(false);
      props.setAlert({
        type: JobBoardAlertType.FormSaved,
        message: (
          <span>
            The <b>{props.form.campaignName}</b> was successfuly updated!
          </span>
        ),
      });
    } catch (e: any) {
      props.setAlert({
        type: JobBoardAlertType.FormSavedError,
        message: 'An error occurred and the form was not successfuly saved.',
      });
      console.error(e);
    }
  }

  function handleInputChange(name: string, value: unknown) {
    props.setForm({ ...props.form, [name]: value });
    setFormUpdated(true);
  }

  useEffect(() => {
    if (props.postDetails) {
      props.setForm({
        ...props.form,
        educationLevel: multiLangTargetGroupFromValue(
          props.targetGroupValues?.educationLevels.find(
            (el) => el.id.toString() === props.postDetails.educationLevelId,
          ),
        ),
        seniority: multiLangTargetGroupFromValue(
          props.targetGroupValues?.seniorities.find(
            (s) => s.id.toString() === props.postDetails.seniorityId,
          ),
        ),
        industry: targetGroupFromValue(
          props.targetGroupValues?.industries.find(
            (s) => s.id.toString() === props.postDetails.industryId,
          ),
        ),
        jobCategory: targetGroupFromValue(
          props.targetGroupValues?.jobFunctions.find(
            (s) => s.id.toString() === props.postDetails.jobCategoryId,
          ),
        ),
      });
    }
  }, [props.targetGroupValues]);

  return (
    <UI
      isLoading={props.form == null}
      formUpdated={formUpdated}
      targetGroupValues={props.targetGroupValues}
      form={props.form}
      errors={props.errors}
      onInputChange={handleInputChange}
      onSave={handleSave}
    />
  );
}

interface UIPropTypes {
  isLoading: boolean;
  formUpdated: boolean;
  targetGroupValues: TargetGroupValues;
  form: JobPostingDetailsForm;
  errors: any;
  onSave: () => void;
  onInputChange: (name: string, value: unknown) => void;
}

function showAlertMessage(
  form: JobPostingDetailsForm,
  targetGroupValues: TargetGroupValues,
): boolean {
  const isLoadingTargetGroupValues = targetGroupValues == null;

  // If the form was not fully pre-filled, we will not show the alert message.
  if (form == null || isLoadingTargetGroupValues) {
    return false;
  }

  return !isPaidPostFormValid(form);
}

function UI(props: UIPropTypes) {
  const errors = props.errors || {};

  return (
    <Card>
      {props.isLoading ? (
        <LoadingData />
      ) : (
        <>
          <div className='d-flex flex-nowrap justify-content-between mb-3'>
            <CardTitle>Job Posting details</CardTitle>
            <Button
              disabled={
                props.form == null ||
                !props.formUpdated ||
                !isPaidPostFormValid(props.form)
              }
              size='sm'
              color='primary'
              onClick={props.onSave}
            >
              Save
            </Button>
          </div>
          {showAlertMessage(props.form, props.targetGroupValues) && (
            <AlertMessage
              textClassName='fs-5'
              icon={{ name: 'bi-info-circle' }}
              className='bg-light-warning mb-3'
              text={'Several mandatory fields have not been filled in.'}
              hasOverlay={true}
            />
          )}
          <div className='d-flex flex-column gap-4'>
            <NameFields
              form={props.form}
              errors={errors}
              onInputChange={props.onInputChange}
            />
            <LocationFields
              form={props.form}
              errors={errors}
              onInputChange={props.onInputChange}
              isOrganic={false}
            />
            <ContactInformationFields
              form={props.form}
              errors={errors}
              onInputChange={props.onInputChange}
            />
            <JobRequirementsFields
              form={props.form}
              errors={errors}
              onInputChange={props.onInputChange}
            />
            <TargetGroup
              targetGroupValues={props.targetGroupValues}
              form={props.form}
              onInputChange={props.onInputChange}
            />
          </div>
        </>
      )}
    </Card>
  );
}
