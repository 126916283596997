import { UseFormSetError } from 'react-hook-form';
import { ApiError } from '../errors/ApiError';
import { ErrorCodes } from '../errors/ErrorCodes';

function handleClientErrors(error: ApiError, setError: UseFormSetError<any>) {
  switch (error.body?.code) {
    case ErrorCodes.ATS_CANDIDATE_EMAIL_DUPLICATED:
      setError('email', { type: 'unique', message: error.body.message });
      break;
    case ErrorCodes.ATS_CANDIDATE_PHONE_NUMBER_DUPLICATED:
      setError('phoneNumber', { type: 'unique', message: error.body.message });
      break;
    case ErrorCodes.ATS_JOB_DUPLICATED:
      setError('name', { message: error.body.message, type: 'unique' });
      break;
    case ErrorCodes.ATS_INVALID_JOB_NAME:
      setError('name', { message: error.body.message, type: 'pattern' });
      break;
    case ErrorCodes.ATS_PLAN_TEMPLATE_NAME_DUPLICATED:
      setError('name', { type: 'unique', message: error.body.message });
      break;
    case ErrorCodes.ATS_CANDIDATE_AND_REFERRER_EMAIL_MATCH:
      setError('referrerEmail', {
        type: 'unique',
        message: error.body.message,
      });
      break;
    case ErrorCodes.ATS_LOCATION_DUPLICATED:
      setError('name', { type: 'unique', message: error.body.message });
      break;
    case ErrorCodes.ATS_OFFER_LETTER_TEMPLATE_NAME_DUPLICATED:
      setError('name', { type: 'unique', message: error.body.message });
      break;
    // This happens if the backend throws an unknown code for the frontend, or a condition is missing.
    default:
      setError('root', { type: 'clientError' });
  }
}

export function handleFormErrors(e: unknown, setError: UseFormSetError<any>) {
  const error = e as ApiError;

  if (error.status < 400) {
    // Shouldn't happen, since it's not an error, just log
    console.log('Api thrown error, ', JSON.stringify(error));
  } else if (error.status >= 400 && error.status < 500) {
    handleClientErrors(error, setError);
  } else {
    setError('root', { type: 'serverError' });
  }
}
