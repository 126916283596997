import { SelectOption } from '../../../../../../../components/Select';
import { SearchSegment } from '../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import { JobService } from '../../../../../../../services/v1/applicant_tracking/JobService';
import { JobStageService } from '../../../../../../../services/v1/applicant_tracking/JobStageService';
import { LocationService } from '../../../../../../../services/v1/applicant_tracking/LocationService';
import {
  ListParams,
  SourceService,
} from '../../../../../../../services/v1/applicant_tracking/SourceService';
import { stringToDateRange } from '../../../../../../../utils/stringToDateRange';
import { dateStrToMoment } from '../../../../../../../utils/dateStrToMoment';
import { SourceList } from '../../../../../../../entities/v1/applicant_tracking/SourceList';
import { JobList } from '../../../../../../../entities/v1/JobList';
import { JobStageList } from '../../../../../../../entities/v1/JobStageList';
import { LocationList } from '../../../../../../../entities/v1/LocationList';
import { SOURCING_CHANNELS } from '../../../../../../../entities/applicant_tracking/Source';
import { ApplicationListingFilters, DEFAULT_FILTER_STATE } from '.';

interface ResourceEntity {
  id: number | string;
  name: string;
}

async function fetchAllSelectedValues<T>(
  ids: number[],
  fetchFunction: (params: ListParams) => Promise<T>,
  extractData: (response: T) => ResourceEntity[],
) {
  if (ids == null || ids.length === 0) return [];

  const pageSize = 100;
  const page = 1;

  const response = await fetchFunction({ ids, pageSize, page });

  return extractData(response);
}

function mapIdsToOptions(
  ids: number[] | string[],
  entities: ResourceEntity[],
): SelectOption[] {
  if (!ids) return [];

  return ids
    .map((entityId) => entities.find((E) => E.id === entityId))
    .filter((entity): entity is ResourceEntity => !!entity)
    .map((entity) => ({
      value: entity.id.toString(),
      label: entity.name,
    }));
}

export async function mapSearchSegmentToFilters(
  segment: SearchSegment,
): Promise<ApplicationListingFilters> {
  const [jobs, jobStages, sources, locations] = await Promise.all([
    fetchAllSelectedValues<JobList>(
      segment.filter.jobIds,
      JobService.list,
      (res) => res.jobs,
    ),
    fetchAllSelectedValues<JobStageList>(
      segment.filter.jobStageIds,
      JobStageService.list,
      (res) => res.jobStages,
    ),
    fetchAllSelectedValues<SourceList>(
      segment.filter.sourceIds,
      SourceService.list,
      (res) => res.sources,
    ),
    fetchAllSelectedValues<LocationList>(
      segment.filter.rightToWorkLocationIds,
      LocationService.list,
      (res) => res.locations,
    ),
  ]);

  return {
    channels: mapIdsToOptions(segment.filter.channels, SOURCING_CHANNELS),
    jobs: mapIdsToOptions(segment.filter.jobIds, jobs),
    sources: mapIdsToOptions(segment.filter.sourceIds, sources),
    jobStages: mapIdsToOptions(segment.filter.jobStageIds, jobStages),
    rightToWorkLocations: mapIdsToOptions(
      segment.filter.rightToWorkLocationIds,
      locations,
    ),
    status: segment.filter.applicationStatus,
    rightToWorkLocationValue: segment.filter.rightToWorkLocationOperator,
    dateRange: {
      start: dateStrToMoment(segment.filter.startDate?.toString()),
      end: dateStrToMoment(segment.filter.endDate?.toString()),
      range:
        segment.filter.dateRange && stringToDateRange(segment.filter.dateRange),
    },
    sortingColumn: {
      columnName: DEFAULT_FILTER_STATE.sortingColumn.columnName,
      direction: DEFAULT_FILTER_STATE.sortingColumn.direction,
    },
    name: segment.filter.name,
    nameInclusionValue: segment.filter.nameOperator,
    email: segment.filter.email,
    emailInclusionValue: segment.filter.emailOperator,
    resume: segment.filter.resume,
    resumeInclusionValue: segment.filter.resumeOperator,
    location: segment.filter.candidateLocation,
    page: 1,
  } as ApplicationListingFilters;
}
