import React, { useState } from 'react';
import { LabelledMultiSelect } from '../../../../../components/LabelledMultiSelect';
import {
  DateFilter,
  DateRange,
  DateRangePicker,
} from '../../../../../components/DateRangePicker';
import { defaultDateRange } from '../../../../../components/candidate_experience/Filters/filterState';
import { Moment } from 'moment';
import styles from './styles.module.scss';
import { SelectOption } from '../../../../../components/Select';
import classNames from 'classnames';

interface PropTypes {
  onFilter: (filters: ActivityTabFilter) => void;
}

export interface ActivityTabFilter {
  dateFilter: DateFilter;
  activityFilter: SelectOption[];
  page: number;
}

const activityOptions = [
  {
    value: 'background_checks',
    label: 'Background Checks',
  },
  {
    value: 'emails',
    label: 'Emails',
  },
  {
    value: 'interview_scheduling',
    label: 'Interview Scheduling',
  },
  {
    value: 'pulse',
    label: 'Pulse',
  },
  {
    value: 'success',
    label: 'Success',
  },
  {
    value: 'others',
    label: 'Other Activities',
  },
];

export const defaultFilters = {
  dateFilter: defaultDateRange(),
  activityFilter: [],
  page: 1,
} as ActivityTabFilter;

function dateChanged(previousState: DateFilter, newState: DateFilter) {
  return (
    previousState.start !== newState.start ||
    previousState.end !== newState.end ||
    previousState.range !== newState.range
  );
}

export function ActivityTabFilters(props: PropTypes) {
  const [dateFilter, setDateFilter] = useState<DateFilter>(
    defaultFilters.dateFilter,
  );
  const [activityFilter, setActivityFilter] = useState<SelectOption[]>(
    defaultFilters.activityFilter,
  );

  const handleDateUpdate = (start: Moment, end: Moment, range: DateRange) => {
    const updatedDates = { start, end, range } as DateFilter;
    setDateFilter(updatedDates);

    if (dateChanged(dateFilter, { start, end, range } as DateFilter)) {
      props.onFilter({
        dateFilter: updatedDates,
        activityFilter,
        page: 1,
      } as ActivityTabFilter);
    }
  };

  return (
    <>
      <div className={classNames('w-30 me-3', styles.filters)}>
        <DateRangePicker
          dateFilter={dateFilter}
          onChange={handleDateUpdate}
          className={classNames('fs-6 py-0', styles['date-range-picker'])}
        />
      </div>
      <div className={classNames('w-30', styles.filters)}>
        <LabelledMultiSelect
          options={activityOptions}
          selected={activityFilter}
          label={null}
          placeholder='All activity'
          singleSelectedText={activityFilter[0]?.label}
          manySelectedText='%d selected'
          onChange={setActivityFilter}
          disableSearch={true}
          className='fs-6'
          labelClassName='mb-0'
          size='sm'
          onClose={() => {
            props.onFilter({
              dateFilter,
              activityFilter,
              page: 1,
            } as ActivityTabFilter);
          }}
        />
      </div>
    </>
  );
}
