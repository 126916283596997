import { UnprocessableEntityError } from './errors/UnprocessableEntityError';
import { ApiError } from './errors/ApiError';
import * as queryString from 'query-string';

type RequestBody = object | FormData;

interface IRequestOptions {
  headers?: IRequestHeaders;
  signal?: AbortSignal;
}

interface IRequestHeaders extends Record<string, string> {
  Accept?: string;
  'Content-Type'?: string;
  'X-CSRF-Token'?: string;
}

const defaultOptions: IRequestOptions = {
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export class ApiService {
  public static async get<T>(
    path: string,
    params: object = undefined,
    options: IRequestOptions = {},
  ): Promise<T> {
    let processedPath = path;
    if (params !== undefined) {
      processedPath = `${processedPath}?${queryString.stringify(params, {
        arrayFormat: 'bracket',
      })}`;
    }
    return this.request('GET', processedPath, undefined, options);
  }

  public static async post<T>(
    path: string,
    body?: RequestBody,
    options: IRequestOptions = {},
  ): Promise<T> {
    return this.request('POST', path, body, options);
  }

  public static async patch<T>(
    path: string,
    body: RequestBody,
    options: IRequestOptions = {},
  ): Promise<T> {
    return this.request('PATCH', path, body, options);
  }

  public static async put<T>(
    path: string,
    body?: RequestBody,
    options: IRequestOptions = {},
  ): Promise<T> {
    return this.request('PUT', path, body, options);
  }

  public static async delete<T>(
    path: string,
    options: IRequestOptions = {},
  ): Promise<T> {
    return this.request('DELETE', path, {}, options);
  }

  public static getCSRFConfig(): { param: string; token: string } {
    const csrfParam: HTMLMetaElement = document.querySelector(
      'meta[name=csrf-param]',
    );
    const csrfToken: HTMLMetaElement = document.querySelector(
      'meta[name=csrf-token]',
    );
    if (csrfParam == null || csrfToken == null) {
      return { param: 'csrf_token', token: 'unavailable' };
    }

    return { param: csrfParam.content, token: csrfToken.content };
  }

  private static async request(
    method: string,
    path: string,
    body: RequestBody = undefined,
    options: IRequestOptions = {},
  ): Promise<any> {
    const res = await fetch(path, {
      method,
      credentials: 'same-origin',
      cache: 'no-cache',
      headers: {
        ...defaultOptions.headers,
        ...this.includeCsrfToken(method, options.headers || {}),
      },
      body: this.processRequestBody(body),
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      signal: options.signal,
    });

    return await this.processResponseBody(res);
  }

  private static includeCsrfToken(
    method: string,
    headers: IRequestHeaders,
  ): IRequestHeaders {
    if (method === 'GET') return headers;

    const { token } = this.getCSRFConfig();

    return { ...headers, 'X-CSRF-Token': token };
  }

  private static processRequestBody(body: RequestBody): string | FormData {
    if (body === undefined) return;
    if (body instanceof FormData) return body as FormData;
    return JSON.stringify(body);
  }

  private static async processResponseBody(res: Response): Promise<any> {
    let data: any;
    if (res.status === 204) return {};
    if (res.headers.get('content-type').match(/application\/json/)) {
      data = await res.json();
    } else {
      if (res.status === 204) return;
      data = await res.blob();
    }

    if (res.status === 401) {
      window.location.replace('/');
    } else if (res.status === 422) {
      throw new UnprocessableEntityError(data);
    } else if (res.status !== 200 && res.status !== 201) {
      throw new ApiError(res.status, data);
    }

    return data;
  }
}
