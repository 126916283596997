import { ApiService } from '../../ApiService';
import { ListCandidates } from '../../../entities/applicant_tracking/ListCandidate';
import {
  FilterStates,
  mapToQueryParams,
} from '../../../containers/applicant_tracking/Candidates/ListCandidatesPage/FilterStates';
import snakecaseKeys from 'snakecase-keys';
import { ApplicationListingFilters } from '../../../containers/applicant_tracking/Applications/ListApplicationsPage/ListApplicationsPageBody/ListApplicationsFilters/utils';

function mapToParams(filters: ApplicationListingFilters) {
  return {
    status: filters.status,
    jobStageIds: filters.jobStages?.map((stage) => stage.value),
    jobIds: filters.jobs?.map((job) => job.value),
    sourceIds: filters.sources?.map((source) => source.value),
    channels: filters.channels?.map((channel) => channel.value),
    rightToWorkLocationIds: filters.rightToWorkLocations?.map(
      (location) => location.value,
    ),
    rightToWorkLocationValue: filters.rightToWorkLocationValue,
    startDate: filters.dateRange.start?.utc()?.format(),
    endDate: filters.dateRange.end?.utc()?.format(),
    dateRange: filters.dateRange.range,
    page: filters.page,
    sortColumn: filters.sortingColumn?.columnName,
    sortDirection: filters.sortingColumn?.direction,
    nameValue: filters.name,
    nameOperator: filters.nameInclusionValue,
    emailValue: filters.email,
    emailOperator: filters.emailInclusionValue,
    resumeValue: filters.resume,
    resumeOperator: filters.resumeInclusionValue,
    location: filters.location,
  };
}
export class ListCandidateService {
  public static list(filterStates: FilterStates): Promise<ListCandidates> {
    return ApiService.get<ListCandidates>(
      '/api/v1/applicant_tracking/list_candidates',
      Object.fromEntries(
        mapToQueryParams(filterStates).map(({ key, value }) => [key, value]),
      ),
    );
  }

  public static newList(
    filterStates: ApplicationListingFilters,
  ): Promise<ListCandidates> {
    return ApiService.get<ListCandidates>(
      '/api/v1/applicant_tracking/applications',
      snakecaseKeys(mapToParams(filterStates)),
    );
  }
}
