import { extractNumbers } from './extractors/extractNumbers';

export function addSeparatorToSalary(value?: string) {
  if (value == null) {
    return value;
  }

  const onlyNumbers = extractNumbers(value);
  return onlyNumbers?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
