import { FilterState } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { HiringProcessRates } from '../../../../entities/applicant_tracking/analytics/HiringProcessRates';
import { ApiService } from '../../../ApiService';
import { mapToParams } from '../mapToParams';

export class HiringProcessRatesService {
  public static show(
    filterState?: FilterState,
    signal?: AbortSignal,
  ): Promise<HiringProcessRates> {
    return ApiService.get(
      '/api/v1/applicant_tracking/analytics/hiring_process_rates',
      mapToParams(filterState),
      { signal },
    );
  }
}
